<template>
    <div v-if="current">
        <!--Banner-->
        <section>
            <v-row class="banner">
                <img v-if="current.detailed.banner" :src="current.detailed.banner" />
                <h1 class="sectionTitle PageTitle" v-html="current.name"></h1>
            </v-row>
        </section>
        <!--join-->
        <section v-if="!current.detailed.hours.servicesPM && !current.detailed.hours.partsPM">
            <div class="orangeStripe"></div>
            <v-row>
                <v-col cols="12" md="6" class="JoinSection">
                    <v-row><v-col><h2 class="joinTitle">NOUS JOINDRE</h2> </v-col></v-row>
                    <v-row class="textSection">
                        <v-col cols="12">
                            <v-row class="">
                                <v-col cols="1"><img src="../../public/ressources/images/icon/mapIcon.png" /></v-col>
                                <v-col class="d-flex align-center"><p v-html="current.address"></p></v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row class="">
                                <v-col cols="1"><img src="../../public/ressources/images/icon/phone-2.png" /></v-col>
                                <v-col class="d-flex align-center">
                                    <a :href="'tel:' + current.phone" v-html="current.phone"></a>
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.phoneNoFee">
                                <v-col cols="1"></v-col>
                                <v-col class="d-flex align-center">
                                    <p  style="margin-top: -18px; font-size: 18px;">Sans frais : <a  style="font-size: 18px; padding-left: 0px;" :href="'tel:' + current.phoneNoFee" v-html="current.phoneNoFee"></a></p>
                                    
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.phoneServices">
                                <v-col cols="1"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><v-col cols="12"><p style="margin-top: -24px; font-size: 18px;">Département du service : <a  style="font-size: 18px; padding-left: 0px;" :href="'tel:' + current.phoneServices" v-html="current.phoneServices"></a></p></v-col> </v-row>                                    
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.phoneParts">
                                <v-col cols="1"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><p style="font-size: 18px;">Département des pièces : <a  style="font-size: 18px; padding-left: 0px;" :href="'tel:' + current.phoneParts" v-html="current.phoneParts"></a></p></v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="current.detailed.fax">
                            <v-row class="">
                                <v-col cols="1"><img src="../../public/ressources/images/icon/FAX.png" /></v-col>
                                <v-col class="d-flex align-center"><p v-html="current.detailed.fax"></p></v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="current.email">
                            <v-row class="" >
                                <v-col cols="1"><img src="../../public/ressources/images/icon/CourrielDealer.png" /></v-col>
                                <v-col class="d-flex align-center">
                                    <a :href="'mailto:' + current.email" v-html="current.email"></a>
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.emailParts">
                                <v-col cols="1"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><v-col cols="12"><p v-if="current.emailParts" style="margin-top: -24px; font-size: 18px;">Département des pièces : <a  style="font-size: 18px; padding-left: 0px;" :href="'mailto:' + current.emailParts" v-html="current.emailParts"></a></p></v-col> </v-row>                                    
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.emailServices">
                                <v-col cols="1"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><p v-if="current.emailServices" style="font-size: 18px;">Département du service : <a  style="font-size: 18px; padding-left: 0px;" :href="'mailto:' + current.emailServices" v-html="current.emailServices"></a></p></v-row>
                                    
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="current.website">
                            <v-row class="">
                                <v-col cols="1"><img src="../../public/ressources/images/icon/website.png" /></v-col>
                                <v-col class="d-flex align-center"><a :href="current.website" v-html="current.website"></a></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" class="hoursSector hours">
                    <img style="width: 100%; height: 100%; object-fit: cover; object-position: center;" :src="current.detailed.hoursBanner" />
                    <div class="hoursAlignement" style="">
                        <v-row><v-col><h2 class="hoursTitle" style="transform: translate(-5%,-0%);">HEURES&nbsp;D’OUVERTURE</h2></v-col></v-row>
                        <v-row class="hoursSubtitle">
                            <v-col cols="4"></v-col>
                            <v-col cols="4" ><div v-if="current.detailed.hours.services">Service</div></v-col>
                            <v-col cols="4" ><div v-if="current.detailed.hours.parts">Pièces</div></v-col>
                        </v-row>
                        <v-row class="hoursText">
                            <v-col cols="4">
                                <v-row><v-col cols="12"><p>Lundi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Mardi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Mercredi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Jeudi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Vendredi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Samedi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Dimanche</p></v-col></v-row>
                            </v-col>
                            <v-col cols="4">
                                <div v-if="current.detailed.hours.services">
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.services.monday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.services.tuesday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.services.wednesday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.services.thursday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.services.friday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.services.saturday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.services.sunday"></p></v-col></v-row>
                                </div>
                            </v-col>
                            <v-col cols="4">
                                <div v-if="current.detailed.hours.parts">
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.parts.monday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.parts.tuesday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.parts.wednesday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.parts.thursday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.parts.friday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.parts.saturday"></p></v-col></v-row>
                                    <v-row><v-col cols="12"><p v-html="current.detailed.hours.parts.sunday"></p></v-col></v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </section>
        <section v-else>
            <div class="orangeStripe"></div>
            <v-row>
                <v-col cols="12" md="4" class="JoinSection">
                    <v-row><v-col><h2 class="joinTitle">NOUS JOINDRE</h2> </v-col></v-row>
                    <v-row class="textSection">
                        <v-col cols="12">
                            <v-row class="">
                                <v-col cols="2"><img src="../../public/ressources/images/icon/mapIcon.png" /></v-col>
                                <v-col class="d-flex align-center"><p v-html="current.address"></p></v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row class="">
                                <v-col cols="2"><img src="../../public/ressources/images/icon/phone-2.png" /></v-col>
                                <v-col class="d-flex align-center">
                                    <a :href="'tel:' + current.phone" v-html="current.phone"></a>
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.phoneNoFee">
                                <v-col cols="2"></v-col>
                                <v-col class="d-flex align-center">
                                    <p  style="margin-top: -18px; font-size: 18px;">Sans frais : <a  style="font-size: 18px; padding-left: 0px;" :href="'tel:' + current.phoneNoFee" v-html="current.phoneNoFee"></a></p>
                                    
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.phoneServices">
                                <v-col cols="2"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><v-col cols="12"><p style="margin-top: -24px; font-size: 18px;">Département du service : <a  style="font-size: 18px; padding-left: 0px;" :href="'tel:' + current.phoneServices" v-html="current.phoneServices"></a></p></v-col> </v-row>                                    
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.phoneParts">
                                <v-col cols="2"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><p style="font-size: 18px;">Département des pièces : <a  style="font-size: 18px; padding-left: 0px;" :href="'tel:' + current.phoneParts" v-html="current.phoneParts"></a></p></v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="current.detailed.fax">
                            <v-row class="">
                                <v-col cols="2"><img src="../../public/ressources/images/icon/FAX.png" /></v-col>
                                <v-col class="d-flex align-center"><p v-html="current.detailed.fax"></p></v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="current.email">
                            <v-row class="" >
                                <v-col cols="2"><img src="../../public/ressources/images/icon/CourrielDealer.png" /></v-col>
                                <v-col class="d-flex align-center">
                                    <a :href="'mailto:' + current.email" v-html="current.email"></a>
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.emailParts">
                                <v-col cols="2"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><v-col cols="12"><p v-if="current.emailParts" style="margin-top: -24px; font-size: 18px;">Département des pièces : <a  style="font-size: 18px; padding-left: 0px;" :href="'mailto:' + current.emailParts" v-html="current.emailParts"></a></p></v-col> </v-row>                                    
                                </v-col>
                            </v-row>
                            <v-row class="" v-if="current.emailServices">
                                <v-col cols="2"></v-col>
                                <v-col class="d-flex align-center">
                                    <v-row style="min-width: 100%;"><p v-if="current.emailServices" style="font-size: 18px;">Département du service : <a  style="font-size: 18px; padding-left: 0px;" :href="'mailto:' + current.emailServices" v-html="current.emailServices"></a></p></v-row>
                                    
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="current.website">
                            <v-row class="">
                                <v-col cols="2"><img src="../../public/ressources/images/icon/website.png" /></v-col>
                                <v-col class="d-flex align-center"><a :href="current.website" v-html="current.website"></a></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="8" class="hoursSectorV2 hours">
                    <img style="width: 100%; height: 100%; object-fit: cover; object-position: center;" :src="current.detailed.hoursBanner"  />
                    <div class="hoursAlignementV2" style="">
                        <v-row><v-col><h2 class="hoursTitle" style="transform: translate(-5%,-0%);">HEURES&nbsp;D’OUVERTURE</h2></v-col></v-row>
                        <v-row class="hoursSubtitleV2">
                            <v-col cols="2"></v-col>
                            <v-col cols="5" ><div v-if="current.detailed.hours.services">Service</div></v-col>
                            <v-col cols="5" ><div v-if="current.detailed.hours.parts">Pièces</div></v-col>
                        </v-row>
                        <v-row class="hoursTextV2">
                            <v-col cols="2">
                                <v-row><v-col cols="12"><p>Lundi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Mardi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Mercredi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Jeudi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Vendredi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Samedi</p></v-col></v-row>
                                <v-row><v-col cols="12"><p>Dimanche</p></v-col></v-row>
                            </v-col>
                            <v-col cols="5">
                                <div v-if="current.detailed.hours.services">
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.services.monday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.servicesPM.monday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.services.tuesday"></p></v-col>
                                        <v-col cols="6"><p v-html="current.detailed.hours.servicesPM.tuesday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.services.wednesday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.servicesPM.wednesday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.services.thursday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.servicesPM.thursday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.services.friday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.servicesPM.friday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.services.saturday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.servicesPM.saturday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.services.sunday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.servicesPM.sunday"></p></v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col cols="5">
                                <div v-if="current.detailed.hours.parts">
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.parts.monday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.partsPM.monday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.parts.tuesday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.partsPM.tuesday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.parts.wednesday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.partsPM.wednesday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.parts.thursday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.partsPM.thursday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.parts.friday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.partsPM.friday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.parts.saturday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.partsPM.saturday"></p></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5"><p v-html="current.detailed.hours.parts.sunday"></p></v-col>
                                        <v-col cols="7"><p v-html="current.detailed.hours.partsPM.sunday"></p></v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </section>
        <!--service-->
        <section>
            <v-row class="servicesSection">
                <v-row><v-col><h2 class="servicesTitle">NOS SERVICES</h2></v-col></v-row>
                <v-row>
                    <v-col v-for="(item, i) in current.detailed.services" :key="i" style="min-width: 350px;" class="service">
                        <img style="height: 200px; width: auto; display: block; margin-left: auto; margin-right: auto;" :src="item.img" />
                        <h2 style="font-size: 18px; text-align: center; font-weight: 700; font-family: MontserratBI; color: white; text-transform: uppercase;" v-html="item.title"></h2>
                    </v-col>
                </v-row>
            </v-row>
        </section>
        <!--Emergency-->
        <section v-if="current.emergency" class="">
            <v-row>
                <v-col cols="12" md="6" class="emergencySection heightEmergency" >
                    <v-row><v-col><h2 class="sectionTitle" style="color: rgb(var(--secondary));">Urgence Routière</h2></v-col></v-row>
                    <v-row class="d-flex align-center justify-center verticalAlign">
                        <v-col  style="max-width: 200px;">
                            <img style="width: 100%; height: auto;"  src="/ressources/images/dealer/serviceRoutier.png" />
                        </v-col>
                        <v-col v-if="current.emergency.multipleTitle">
                            <v-col cols="12"><h3 class="emergencySubtitle">Service routier et de remorquage</h3></v-col>
                            <v-col cols="12" style="margin-top:20px"><p class="emergencyText" v-if="current.emergency.openHours">Selon les heures d'ouverture</p> </v-col>
                            <v-col cols="12" style="margin-top:10px"><a class="phoneText" :href="'tel:' + current.emergency.phone" style="" v-html="current.emergency.phone"></a></v-col>
                        </v-col>
                        <v-col v-else>
                            <v-row>
                                <v-col style="min-width: 250px;">
                                    <v-row>
                                        <v-col cols="12"><h3 class="emergencySubtitle">Service routier</h3></v-col>
                                        <v-col cols="12" style="margin-top:10px"><p class="emergencyText" v-if="current.emergency.openHours">Selon les heures d'ouverture</p> </v-col>
                                        <v-col cols="12" style="margin-top:0px">
                                            <a class="phoneText" :href="'tel:' + current.emergency.phone" style="" v-html="current.emergency.phone"></a>
                                            <p class="phoneText" style="padding-left: 10px;" v-if="current.emergency.allDay && !current.emergency.openHours"> 24h/7</p>
                                        </v-col>
                                        <v-col cols="12" style="margin-top:10px"><p class="emergencyText" v-if="current.emergency.midnightphone">À partir de minuit</p> </v-col>
                                        <v-col cols="12" style="margin-top:0px">
                                            <a class="phoneText" :href="'tel:' + current.emergency.midnightphone" style="" v-html="current.emergency.midnightphone"></a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-col cols="12" v-if="current.emergency.towingPhone"><h3 class="emergencySubtitle">Remorquage</h3></v-col>
                                        <v-col cols="12" style="margin-top:10px" v-if="current.emergency.allDay"><p class="phoneText">24h/7</p></v-col>
                                        <v-col cols="12" v-if="current.emergency.towingPhone">
                                            <a class="phoneText" :href="'tel:' + current.emergency.towingPhone" v-html="current.emergency.towingPhone"></a>
                                            <p class="phoneText" v-if="current.emergency.SecTowingPhone" style="padding: 0px 5px;">ou</p>
                                            <a v-if="current.emergency.SecTowingPhone" class="phoneText" :href="'tel:' + current.emergency.SecTowingPhone" v-html="current.emergency.SecTowingPhone"></a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="heightEmergency">
                    <img  style="width: 100%; height: 100%; object-fit: cover; object-position: center;" src="/ressources/images/dealer/emergencyImg.jpg" />
                </v-col>
            </v-row>
        </section>
        <!--teams-->
        <section>
            <div class="orangeStripe"></div>
            <v-row class="teamsSection">
                <v-col>
                    <v-row><v-col><h2 class="teamsTitle">CONTACTEZ NOTRE ÉQUIPE</h2></v-col></v-row>
                    <v-row style="padding: 0px 50px ;">
                        <v-col v-for="(item, i) in current.detailed.teams" :key="i" class="itemTeam">
                            <v-row><v-col><h3 class="nameTeam" v-html="item.name"></h3></v-col></v-row>
                            <v-row><v-col><p v-html="item.title"></p></v-col></v-row>
                            <v-row><v-col><a class="mailTeam" :href="'mailto:' + item.email" v-html="item.email"></a></v-col></v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </section>
        <!--map-->
        <section>
            <iframe style="border:0px; margin-bottom: -7px;" :src="current.detailed.map" width="100%" height="250" frameborder="0" ></iframe>
        </section>
    </div>
  </template>
  <style scoped>
    .banner { position: relative; height: 400px; background-color: black; background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-transform: uppercase; }
    .banner img { width: 100%; height: auto; max-height: 100%; object-position: center; object-fit: cover; }
    .JoinSection { background-color: rgb(var(--ligthGray)); padding: 50px; }
    .joinTitle { color: rgb(var(--secondary)); text-transform: uppercase; font-size: 32px; font-family: MontserratBI;  }
    .textSection { margin-top: 50px !important; }
    .textSection p { font-family: CooperHewittR;  font-size: 28px; padding-left: 20px;}
    .textSection a { font-family: CooperHewittR;  font-size: 28px; padding-left: 20px;}
    .textSection img { width: 75px; }
    .textSectionV2 { margin-top: 50px !important; }
    .textSectionV2 p { font-family: CooperHewittR;  font-size: 20px; padding-left: 20px;}
    .textSectionV2 a { font-family: CooperHewittR;  font-size: 20px; padding-left: 20px;}
    .textSectionV2 img { width: 75px; }
    .hoursSector { overflow: hidden; height: 650px; padding: 0px; position: relative; background-color: black; background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .hoursSectorV2 { overflow: hidden; height: 550px; padding: 0px; position: relative; background-color: black; background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .hours img { height: 100%; padding: 0px !important;}
    .hoursTitle { color: white; text-transform: uppercase; font-size: 32px; font-family: MontserratBI; text-align: center;  }
    .hoursSubtitle { margin-top: 70px !important; font-family: CooperHewittR; font-size: 28px; }
    .hoursSubtitleV2 { margin-top: 50px !important; font-family: CooperHewittR; font-size: 28px; }
    .hoursAlignement {padding: 50px; position: absolute; z-index: 10; top:44%; left: 50%; transform: translate(-45%,-50%); color: white; min-width: 90%;}
    .hoursAlignementV2 {padding: 0px; position: absolute; z-index: 10; top:43%; left: 50%; transform: translate(-45%,-50%); color: white; min-width: 100%;}
    .hoursText { margin-top: 20px !important;  }
    .hoursText p { font-family: CooperHewittR; font-size: 28px; }
    .hoursTextV2 { margin-top: 20px !important;  }
    .hoursTextV2 p { font-family: CooperHewittR; font-size: 20px; }
    .servicesSection { background-color: rgb(var(--gray)); padding: 50px; }
    .servicesTitle { color: white; text-transform: uppercase; font-size: 32px; font-family: MontserratBI; margin-bottom: 50px; }
    .teamsSection { background-color: black; padding: 50px; }
    .teamsTitle { color: white; text-transform: uppercase; font-size: 32px; font-family: MontserratBI; margin-bottom: 50px; min-width: 800px;}
    .itemTeam { color:white; min-width: 50%; padding: 25px 0px !important; font-family: CooperHewittR; }
    .nameTeam { font-size: 24px; }
    .mailTeam { color:rgb(var(--secondary)); }
    .emergencySection { background-color: rgb(var(--ligthGray)); padding: 50px !important; }
    .emergencySubtitle { font-family: MontserratBI; text-transform: uppercase; font-size: 24px; color: black; margin-top: 20px !important;}
    .emergencyText { font-family: CooperHewittR; font-size: 18px;}
    .phoneText {font-family: CooperHewittR; font-size: 18px; display: inline-block;}
    .heightEmergency { max-height: 450px !important; }
    .verticalAlign { height: 100%; } 

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
        .itemTeam { color:white; min-width: 100%; padding: 25px 0px !important; font-family: CooperHewittR; }
        .hoursAlignement {position: absolute; z-index: 10; top:50%; left: 55%; transform: translate(-50%,-50%); color: white; min-width: 90%;}
        .hoursText p { font-size: 12px !important; }
        .textSection p { font-size: 12px !important; }
        .textSection a { font-size: 12px !important; }
        .PageTitle { text-align: center; }
        .teamsTitle { min-width: unset; }
        .emergencySection { min-width: 100%; }
        .emergencySubtitle { margin-top: 5px !important; }
    }
    @media handheld, only screen and (max-width: 1279px) {
        .verticalAlign { height: unset; } 
        .textSection img { width: 50px; }
        
    }
    @media handheld, only screen and (max-width: 1378px) {
        .hoursText p { font-size: 16px; }
        .textSection p { font-size: 16px; }
        .textSection a { font-size: 16px; }
        .hoursAlignement { top:36%; }
    }
 </style>
  <script>
  
  export default {
    name: 'dealer',
    data:()=>({
        dealer: {
            name:'CAMIONS INTER-ANJOU', 
            banner:'/ressources/images/dealer/interAnjou.jpg', 
            map:'https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d11163.844011680309!2d-73.564711!3d45.611426!3m2!1i1024!2i768!4f13.1!2m1!1s8300,+rue+Edison,+Anjou,,+H1J+1S8!6i15!3m1!1sfr',
            address: '8300,rue Edison, Anjou&nbsp;(Québec)&nbsp;H1J 1S8',
            phone: '(514)&nbsp;353-9720',
            fax: '(514)&nbsp;353-3222',
            email: 'requete@camionsinteranjou.com',
            services: [
                { title:'Service&nbsp;routier', img:'/ressources/images/dealer/serviceRoutier.png' },
                { title:'Heures de service prolongées', img:'/ressources/images/dealer/serviceProlongees.png' },
                { title:'MOTEURS CUMMINS', img:'/ressources/images/dealer/cummins.png' },
                { title:'Atelier&nbsp;de carrosserie', img:'/ressources/images/dealer/atelierCarrosserie.png' },
                { title:'Service&nbsp;de remorquage', img:'/ressources/images/dealer/ServiceRemorquage.png' },
                { title:'Service&nbsp;accéléré', img:'/ressources/images/dealer/serviceAccelere.png' },
                { title:'Alignement', img:'/ressources/images/dealer/Alignement.png' },
                { title:'NETTOYAGE&nbsp;DE FILTRE&nbsp;À&nbsp;PARTICULES', img:'/ressources/images/dealer/nettoyageDPF.png' },
                { title:'Fleet&nbsp;Charge', img:'/ressources/images/dealer/fleetCharge.png' },
                { title:'Location&nbsp;de camions', img:'/ressources/images/dealer/locationCamions.png' },
                { title:'RÉPARATION D’AIR&nbsp;CLIMATISÉ', img:'/ressources/images/dealer/airClimatise.png' },
                { title:'Fleetrite', img:'/ressources/images/dealer/fleetrite.png' },
                { title:'OnCommand', img:'/ressources/images/dealer/onCommand.png' }
            ],
            teams:[
                { name:'Nathalie Hallé',title:'Présidente',email:'nathalie.halle@camionsinteranjou.com' },
                { name:'Éric Morin',title:'Directeur du service',email:'eric.morin@camionsinteranjou.com' },
                { name:'Malek Toursal',title:'Directeur des ventes',email:'malek.toursal@camionsinteranjou.com' },
                { name:'Sylvain Lalumière',title:'Directeur technique',email:'sylvain.lalumiere@reseaudynamique.com' },
                { name:'Sylvain Bélanger',title:'Directeur des pièces',email:'Sylvain.Belanger@camionsinteranjou.com' }
            ],
            hours:[
                {services:[
                    {monday:"6:00&nbsp;-&nbsp;00:15"},
                    {tuesday:"6:00&nbsp;-&nbsp;00:15"},
                    {wednesday:"6:00&nbsp;-&nbsp;00:15"},
                    {thursday:"6:00&nbsp;-&nbsp;00:15"},
                    {friday:"6:00&nbsp;-&nbsp;00:15"},
                    {saturday:"Fermé"},
                    {sunday:"Fermé"}
                ]},
                {parts: [
                    {monday:"6:00&nbsp;-&nbsp;23:00"},
                    {tuesday:"6:00&nbsp;-&nbsp;23:00"},
                    {wednesday:"6:00&nbsp;-&nbsp;23:00"},
                    {thursday:"6:00&nbsp;-&nbsp;23:00"},
                    {friday:"6:00&nbsp;-&nbsp;23:00"},
                    {saturday:"Fermé"},
                    {sunday:"Fermé"}
                ]}
            ]
        },
        current:null,
        dealers: null,
        
        
    }),
    mounted() {
        let id = this.$route.params.id;
        console.log(id);
        if(id){ this.dataRead(id); } else { this.$router.push('/'); }
        
    },
    methods:{
        dataRead: async function (id) {
            try {
                const response = await fetch('/ressources/json/dealers.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                this.dealers = await response.json();
                if(this.dealers){this.current = this.dealers[id]}
            } catch (error) {
                console.error('Error loading JSON data:', error);
            }
        },
    },
    components: { }
  }
  </script>