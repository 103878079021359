<template>
    <div>
        <!--Banner-->
        <section class="">
            <div class="banner">
                <h1 v-if="!isNew" class="PageTitle" style="">INVENTAIRE CAMIONS USAGÉS</h1>
                <h1 v-if="isNew == 1" class="PageTitle" style="">INVENTAIRE CAMIONS NEUFS</h1>
            </div>
        </section>
        <!--Filter-->
        <section>
            <v-row class="bgFilter grid-pad" style="font-family: MontserratBI;"> 
                <v-col class="">
                    <div class="blocFilter">
                        <select class="SelectFilter">
                            <option> - Marque -</option>
                        </select>
                        <select class="SelectFilter">
                            <option> - Modèles -</option>
                        </select>
                    </div>
                </v-col>
                <v-col class="">
                    <div class="blocFilter">
                        <select class="SelectFilter">
                            <option> - Année -</option>
                        </select>
                        <select class="SelectFilter">
                            <option> - Prix -</option>
                        </select>
                    </div>
                </v-col>
                <v-col class="">
                    <div class="blocFilter">
                        <select class="SelectFilter">
                            <option> - Application -</option>
                        </select>
                        <button>Rechercher</button>
                    </div>
                </v-col>
            </v-row>
        </section>
        <div v-if="item">
            <section>
                <div style="background-color: rgb(var(--ligthGray)); padding: 20px;">
                    <v-row class="truck" v-for="(item, i) in item.records" :key="i" style="background-color: white; margin: 20px 0px !important; padding: 20px;">
                        <v-row style="height: 100%; width: 100%; padding: 10px;">
                            <v-col class="image-box" style="display: flex; justify-content: center; align-items: center; max-width: 345px; min-width: 345px; margin: auto; height: auto; max-height: 200px; object-fit: contain;">
                                <img @click="this.readPictures(item)" style="object-fit: contain; object-position: center center;" height="100%" width="auto" :src="getSrc(item.picture_id)" />
                            </v-col>
                            <v-col class="specWidth">
                                <v-row>
                                    <v-col>
                                        <h2 class="truckTitle">{{ item.strAnnee }} {{ item.Model }} {{ item.marque }}</h2>
                                    </v-col>
                                    <v-col>
                                        <p class="priceTitle"  v-if="item.price">{{ item.price }}$</p>
                                        <p class="specText" v-else>N/D</p>
                                    </v-col>
                                </v-row>
                                <v-row class="d-flex justify-center" style="margin-top: 25px !important;">
                                    <v-col class="spec">
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">État&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.etat">{{ item.etat }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Numéro de stock&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.stock">{{ item.stock }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Kilométrage&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.km" >{{ item.km }} KM</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Moteur&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.engine">{{ item.engine }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Transmission&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.transmission">{{ item.transmission }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="spec">
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Concessionnaire&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.dealer">{{ item.dealer }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Couleur&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.color">{{ item.color }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Essieu avant&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.frontaxle">{{ item.frontaxle }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Essieu arrière&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.rearaxle">{{ item.rearaxle }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="specSubtitle"><h3 class="specTitle">Équipement&nbsp;:</h3></v-col>
                                            <v-col><p class="specText" v-if="item.equipments">{{ item.equipments }}</p><p class="specText" v-else>N/D</p></v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col style="margin: 10px;">
                                <button @click="this.readPictures(item)"  class="grayButton d-flex align-center">
                                    <img style="height: 50px; display: inline-block;" src="../../public/ressources/images/icon/iconPhoto.png" />
                                    <h2 style="display: inline-block; padding-left: 20px;">Photos</h2>
                                </button>
                            </v-col>
                            <v-col style="margin: 10px;">
                                <button class="grayButton d-flex align-center">
                                    <img style="height: 50px; display: inline-block;" src="../../public/ressources/images/icon/iconDialog.png" />
                                    <h2 style="display: inline-block; padding-left: 20px;">Obtenir&nbsp;un&nbsp;prix</h2>
                                </button>
                            </v-col>
                            <v-col style="margin: 10px;">
                                <button class="grayButton d-flex align-center">
                                    <img style="height: 50px; display: inline-block;" src="../../public/ressources/images/icon/iconDollars.png" />
                                    <h2 style="display: inline-block; padding-left: 20px;">Financement</h2>
                                </button>
                            </v-col>
                            <v-col style="margin: 10px;">
                                <router-link :to="getHrefPrint(item.id)" target="_blank">
                                    <button class="grayButton d-flex align-center">
                                        <img style="height: 50px; display: inline-block;" src="../../public/ressources/images/icon/iconPrint.png" />
                                        <h2 style="display: inline-block; padding-left: 20px;">Imprimer</h2>
                                    </button>
                                </router-link>
                            </v-col>
                            <v-col style="margin: 10px;">
                                <router-link :to="getHref(item.id)">
                                    <button class="blueButton d-flex align-center">
                                        <img style="height: 50px; display: inline-block;" src="../../public/ressources/images/icon/iconDetailed.png" />
                                        <h2 style="display: inline-block; padding-left: 20px;">FICHE&nbsp;DÉTAILLÉE</h2>
                                    </button>
                                </router-link>
                                
                            </v-col>
                        </v-row>
                    </v-row>
                    <!--resultat section -->
                    <div v-if="paging" class="grid horizonRuler ">
                        <div class="d-flex align-center justify-center pagination" style="color: rgb(var(--primary)); font-family: MontserratBI; font-size: 64px;" v-if="paging.available">
                            <button style="display: inline-block; margin: 10px;" type="button" class="arrowPaging arrowPrev buttonwebsite" @click="paging.prev()">&lt;</button>
                            <div style="display: inline-block; margin: 5px; cursor: pointer; font-size: 36px;"  v-for="page in paging.pages">
                                <a class="paging" style="color: rgb(var(--primary));"  v-bind:class="{ active: page.current }" @click="paging.move(page.index)">{{page.title}}</a>
                            </div>
                            <button style="display: inline-block; margin: 10px;" type="button" class="arrowPaging arrowNext buttonwebsite" @click="paging.next()">></button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-if="!item">
            <v-row class="" >
                <v-row class="bgCharging">
                    <v-col><h1 class="titleCharging txt-c">L'inventaire est en chargement <v-progress-circular style="color: rgb(var(--primary));" :size="75" :width="8" indeterminate></v-progress-circular></h1> 
                    </v-col>
                </v-row>

            </v-row>
        </div>

        <v-dialog class="" style="z-index: 10000; object-position: center center;" v-model="showPicturesSelected">
            <template v-slot:default="{ isActive }">
            <v-card class="custom-scrollbar">
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text="X"
                    @click="isActive.value = false"
                ><span class="bold" style="font-size: 22px;">X</span></v-btn>
                </v-card-actions>
                <v-card-text>
                <v-row class="">
                    
                    <v-col class="d-flex align-center justify-center">
                    <div class="borderPrimary" style="max-height:100%; max-width:100%;">
                        <v-carousel v-if="picturesSelected" style="height: auto; margin-top:25px;" cycle hide-delimiters infinite>
                            <template v-slot:prev="{ props }"> <h2 class="storyArrow arrowPaging arrowPrev" @click="props.onClick"> &lt; </h2></template>
                            <template v-slot:next="{ props }"> <h2 class="storyArrow arrowPaging arrowNext" @click="props.onClick"> > </h2></template>
                            <template v-for="(pic, index) in picturesSelected"> 
                                <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
                                    <v-row class="flex-nowrap" style="height:100%">
                                    <template v-for="(n,i) in columns"> 
                                        <template v-if="(+index + i) < picturesSelected.length"> 
                                            <v-col :key="i">
                                                <v-sheet v-if="(+index + i) < picturesSelected.length" style="height: 100%; margin: 0px 10px; background-color: transparent;" >
                                                    <v-row class="" justify="center" >
                                                        <div class="display-3">
                                                            <!--ici ton image ou information necessaire @click="selectedProduct(Dist[index + i].img)" -->
                                                            <img style="position: relative; object-fit: contain; z-index: 1; width: 100%; height: auto; max-height: 600px;" :src="picturesSelected[index + i]"/>
                                                        </div>
                                                    </v-row>
                                                </v-sheet>
                                            </v-col>
                                        </template>
                                    </template>
                                    </v-row>
                                </v-carousel-item>
                            </template>
                        </v-carousel>
                        <h2 v-else class="titlePictures">Les photos sont en chargement !</h2>
                    </div>
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
            </template>
        </v-dialog>
    </div>
  </template>
  <style scoped>
        .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-transform: uppercase; }
        .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/inventory/banner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
        /*#region Filter*/
        .bgFilter {background-color: rgb(var(--gray)); min-height: 50px; padding: 50px;}
        .SelectFilter {width: 100%; background-color: black; color: white; padding: 5px 10px;  margin-bottom: 20px; text-transform: uppercase;}
        .blocFilter { width: 80%; margin-left: auto; margin-right: auto; display: block; text-transform: uppercase !important;}
        .blocFilter button { padding: 5px 30px; background-color: rgb(var(--secondary)); color: white; text-transform: uppercase !important; }
        .blocFilter button:hover { box-shadow: 0px 0px 5px rgb(var(--primary)); cursor: pointer; }
        /*#endregion*/
        /*#region truck*/
        .truckTitle{ font-family: MontserratBI; font-size: 18px; color: rgb(var(--secondary)); padding-left: 50px !important; }
        .priceTitle { font-family: MontserratRe; font-size: 18px; text-align: right; color: rgb(var(--secondary));   }
        .specTitle { font-family: MontserratBI; font-size: 16px; color: black; }
        .specText { font-family: MontserratRe; font-size: 16px; color: black;}
        .specWidth { padding: 0px 0px 0px 10px !important; }
        .specSubtitle { min-width: 200px; max-width: 200px; }
        .spec { padding-left: 50px !important; min-width: 350px; }
        .grayButton { background-color: rgb(var(--ligthGray)); text-align: left; color: rgb(var(--secondary)); min-width: 250px; width: 100%; padding: 10px; }
        .blueButton { background-color: rgb(var(--secondary)); color: white; text-align: left; min-width: 250px; width: 100%; padding: 10px; }
        .grayButton h2 { text-transform: uppercase; font-family: MontserratBI; }
        .blueButton h2 { text-transform: uppercase; font-family: MontserratBI; }
        .truck button:hover {box-shadow: 0px 0px 10px rgb(var(--primary)); cursor: pointer;}
        .image-box { position: relative; margin: auto; overflow: hidden; }
        .image-box img { max-width: 100%; transition: all 0.3s; display: block; width: 100%; height: auto; transform: scale(1); }
        .image-box:hover img { transform: scale(1.1); }
        .arrowPaging { display: inline-block;  font-size: 76px; cursor: pointer; color: rgb(var(--primary));}
        .arrowPaging:hover { color: white; text-shadow: 0px 0px 8px rgb(var(--primary)); }
        .paging:hover { color: white !important; }
        .titlePictures{ padding: 50px; font-family: MontserratBI; text-transform: uppercase; font-size: 24px; text-align: center; color: rgb(var(--secondary)); }
        .titleCharging { position: absolute; top:50%; left: 50%; transform: translate(-50%,-50%); font-family: MontserratBI; color: black; font-size: 36px; text-transform: uppercase;}
        .bgCharging { position: relative; min-height: 600px; background-image: url('../../public/ressources/images/background-truck.jpg'); background-position: center center; background-size: cover; }
        /*#endregion*/
        @media handheld, only screen and (max-width: 600px) {
            .spec { padding-left: 0px !important; }
            .truckTitle { padding-left: 0px !important; }
        }
        @media handheld, only screen and (max-width: 960px) {
            
        }
        @media handheld, only screen and (max-width: 1279px) {
            .specTitle { font-size: 12px; }
            .specText { font-size: 12px; }
        }
        @media handheld, only screen and (max-width: 1378px) {}
  </style>
  <script>
  
  export default {
    name: 'Home',
    data:()=>({
        items:[
            { picture:'/ressources/images/inventory/imgtest1.png',Model:'International', marque:'LT625 6x4', annee:'2021', price:'155,000.00',etat:'Usagé',noSerie:'US1012',km:'210 497',moteur:'26 475/1650',transmission:'Ultrashift+ 13 vitesses',concessionnaire:'Camions International Élite',couleur:'Blanc',essieuAvant:'12,000',essieuArriere:'40,000',equipment:'Option aérodynamique' },
            { picture:'/ressources/images/inventory/imgtest2.png',Model:'International', marque:'HX520 6x4', annee:'2022', price:'225,000.00',etat:'Neuf',noSerie:'A8207',km:'1 466',moteur:'Cummins ISX15 485/1850',transmission:'Eaton Manuelle 18 vitesses',concessionnaire:'Centre Routier 1994',couleur:'Noir',essieuAvant:'12,000',essieuArriere:'40,000',equipment:'Cab Guard Herd + plusieurs ajouts de chrome, intérieur Elite Diamond' },
            { picture:'/ressources/images/inventory/imgtest3.png',Model:'International', marque:'eMV 4x2', annee:'2022', price:'340,000.00',etat:'Neuf',noSerie:'A6181',km:'86',moteur:'Électrique',transmission:'Automatique / électrique',concessionnaire:'Camions Inter-Anjou',couleur:'Bleu métallique',essieuAvant:'12,000',essieuArriere:'21,000',equipment:'Boîte réfrigérée (à venir)' },
            { picture:'/ressources/images/inventory/imgtest1.png',Model:'International', marque:'LT625 6x4', annee:'2021', price:'155,000.00',etat:'Usagé',noSerie:'US1012',km:'210 497',moteur:'A26 475/1650',transmission:'Ultrashift+ 13 vitesses',concessionnaire:'Camions International Élite',couleur:'Blanc',essieuAvant:'12,000',essieuArriere:'40,000',equipment:'Option aérodynamique' },
            { picture:'/ressources/images/inventory/imgtest5.png',Model:'International', marque:'HX520 6x4', annee:'2022', price:'155,000.00',etat:'Neuf',noSerie:'A8207',km:'1 466',moteur:'Cummins ISX15 485/1850',transmission:'Eaton Manuelle 18 vitesses',concessionnaire:'Centre Routier 1994',couleur:'Noir',essieuAvant:'12,000',essieuArriere:'40,000',equipment:'Cab Guard Herd + plusieurs ajouts de chrome, intérieur Elite Diamond' }
        ],
        isNew: 0,
        params: "",
        errorMessage: "",
        item: null,
        showPicturesSelected: false,
        picturesSelected: null,
        columns: 1,
        paging: null
    }),
    async beforeRouteUpdate(to, from, next) {
        this.paramInit(to.fullPath);
        this.pagingInit();
        this.dataRead();

        next();
        
    },
    mounted() {
        try {
            this.paramInit(window.location.search);
            this.pagingInit();
            this.dataRead();

        } catch (error) {
            this.errorMessage = error.toString();
        }
    },
    methods:{
        async paramInit(path) {
            let isNew = (path.match(new RegExp('[?&]' + 'n' + '=([^&]+)')) || [,
                null
            ])[1];
            if (isNew && isNew == 1) { this.isNew = 1; }
                this.params = (path.match(new RegExp('[?&]' + 'params' + '=([^&]+)')) ||
                [, null
            ])[1];
            if(!isNew){ this.isNew = 0; }
        },
        pagingInit() {

            this.paging = {
                available: true,
                current: 0,
                size: 0,
                message: "",
                pages: [],
                indexFirst: 0,
                indexCount: 0,
                indexAll: 0,
                changed: function(paging) {},
                reset: function() {
                    this.current = this.size = this.indexFirst = this.indexCount = this
                        .indexAll = 0;
                    this.message = "";
                    this.pages = [];
                },
                set: function(data) {
                    this.size = data.max;
                    this.indexFirst = data.first;
                    this.indexCount = data.count;
                    this.indexAll = data.total;
                    this.message = data.first + " - " + Math.max(data.first + data.count - 1,
                        0) + " sur " + data.total;
                    this.available = (data.total > data.count);
                    if (this.available) {
                        this.pages = [];
                        let q = Math.floor(this.indexAll / this.size);
                        for (let i = 0; i <= q; i++) {
                            this.pages.push({
                                index: i,
                                title: (i + 1).toString(),
                                current: (i == this.current)
                            });
                        }
                    }
                },
                next: function() {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    let result = true;
                    this.current++;
                    if ((this.current * this.size) >= this.indexAll) {
                        this.current--;
                        result = false;
                    } else {
                        this.changed(this);
                    }
                    return result;
                },
                prev: function() {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.current = Math.max(this.current - 1, 0);
                    this.changed(this);
                    return true;
                },
                move: function(index) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.current = index;
                    this.changed(this);
                    return true;
                }
            };
            this.paging.changed = this.pagingChanged;
        },
        paramChanged(params) {
            this.params = params;
            if (this.paging) { this.paging.reset(); }
            this.dataRead();
        },
        pagingChanged(paging) { this.dataRead(); },
        async dataRead() {
            //let api = '/ressources/api/trucks.php';
            //let api = 'https://reseaudynamique.com/api/trucks.php';
            let api = 'https://dev.reseaudynamique.com/ressources/api/trucks.php';

            // Show loading spinner
            //$('.loading-overlay').show();

            try {
                let page = 0;
                if (this.paging) {
                    page = this.paging.current;
                }
                let response = await fetch(api + '?n=' + this.isNew + '&p=' + page + '&params=' +
                    encodeURI(this.params));
                let data = await response.json()
                this.item = data;

                if (this.paging) {
                    this.paging.set(data);
                }

            } catch (error) {
                console.error(error);
                this.errorMessage = error.toString();
            }

            // Hide loading spinner
            //$('.loading-overlay').hide();
        },
        getHref(id) {
            let url = "/camion?"
            if (this.isNew == 1) { url = url + "n=1&" }
            return url + "id=" + encodeURI(btoa(id));
        },
        getHrefPrint(id){
            let url = "/impressionCamion?"
            if (this.isNew == 1) { url = url + "n=1&" }
            return url + "id=" + encodeURI(btoa(id));
        },
        getSrc(id) {
            if (id) {
                //let url = "/ressources/api/pictures.php?";
                let url = "https://reseaudynamique.com/api/pictures.php?";

                url = url + "n=" + this.isNew + "&id=" + id; 
                return url;
            } else {
                return "/ressources/images/noImage.jpg";
            }
        },
        async readPictures(item) {
            this.picturesSelected = null;
            this.showPicturesSelected = true;
            //let api = '/api/singleUsedTruck_.php';
            let api = 'https://reseaudynamique.com/api/singleUsedTruck_.php';
            let isNew = this.isNew;
            if(isNew && isNew == 1){
                //api = '/api/singleNewTruck_.php';
                api = 'https://reseaudynamique.com/api/singleNewTruck_.php';
            }

            let id = encodeURI(btoa(item.id));
            if (id) {

                try {

                    const response = await fetch(api + '?id=' + id)
                    const data = await response.json()
                    this.picturesSelected = data.pictures;
                    

                } catch (error) {
                    this.showPicturesSelected = false;
                    console.error(error);
                    this.errorMessage = error.toString();
                }
            }
        }
    },
    components: { }
  }
  </script>