import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import home from '../views/home.vue'
import news from '../views/news.vue'
import contactUs from '../views/contactUs.vue'
import career from '../views/career.vue'
import opportunities from '../views/opportunities.vue'
import dealer from '../views/dealer.vue'
import truckElectrik from '../views/truckElectrik.vue'
import inventory from '../views/inventory.vue'
import truck from '../views/truck.vue'
import truckPrint from '../views/truckPrint.vue'
import emergency from '../views/emergency.vue'
import powertrainS13 from '../views/news/powertrainS13.vue'
import preWinter from '../views/news/preWinter.vue'
import promo from '../views/promo.vue'
import Services from '../views/services.vue'
import sales from '../views/sales.vue'
import student from '../views/student.vue'
import job from '../views/job.vue'
import parts from '../views/parts.vue'
import policy from '../views/policy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/actualites',
    name: 'news',
    component: news
  },
  {
    path: '/actualites/powertrain',
    name: 'powertrainS13',
    component: powertrainS13
  },
  {
    path: '/actualites/preHivernal',
    name: 'preWinter',
    component: preWinter
  },
  {
    path: '/nous-joindre',
    name: 'contactUs',
    component: contactUs
  },
  {
    path: '/carriere',
    name: 'career',
    component: career
  },
  {
    path: '/opportunites',
    name: 'opportunities',
    component: opportunities
  },
  {
    path: '/concessionaire/:id',
    name: 'dealer',
    component: dealer
  },
  {
    path: '/camionElectrique',
    name: 'truckElectrik',
    component: truckElectrik
  },
  
  {
    path: '/inventaire',
    name: 'inventory',
    component: inventory
  },
  {
    path: '/camion',
    name: 'truck',
    component: truck
  },
  {
    path: '/impressionCamion',
    name: 'truckPrint',
    component: truckPrint
  },
  {
    path: '/urgenceRoutiere',
    name: 'emergency',
    component: emergency
  },
  {
    path: '/promotion',
    name: 'promo',
    component: promo
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/ventes',
    name: 'sales',
    component: sales
  },
  {
    path: '/piece',
    name: 'parts',
    component: parts
  },
  {
    path: '/etudiant',
    name: 'student',
    component: student
  },
  {
    path: '/travail/:jobkey/:dealerkey',
    name: 'job',
    component: job
  },
  {
    path: '/mentionsLegales',
    name: 'policy',
    component: policy
  }

  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      setTimeout(() => {
        if (el.length) {
          const offset = 150; // Décalage en pixels, ajustez selon vos besoins
          const elPosition = document.getElementById(el).getBoundingClientRect().top + window.pageYOffset;
          const scrollToPosition = elPosition - offset;
          window.scrollTo({
            top: scrollToPosition,
            behavior: "smooth"
          });
        }
      }, 5);
      
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router
