<template>
  <div >
    <div class="newsBg">
      <section>
        <v-row>
          <v-col><h1 class="titleNews">LE NOUVEAU MOTOPROPULSEUR S13 PRÊT POUR LE SECTEUR VOCATIONNEL</h1></v-col>
        </v-row>
      </section>
      <section class="iconBg">
        <v-row>
          <v-col>
            <v-row class="d-flex align-center justify-center">
              <v-col class="iconWidth"><img src="/ressources/images/icon/dateIcon.png" truesrc="/ressources/images/icon/dateIcon.png"  alt="date icon"/></v-col>
              <v-col class="textWidth" ><p>13 novembre 2024</p></v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="d-flex align-center justify-center">
              <v-col class="iconWidth"><img src="/ressources/images/icon/typeIcon.png" truesrc="/ressources/images/icon/typeIcon.png" alt="type icon"/></v-col>
              <v-col class="textWidth"><p>Blogue</p></v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row @click="back" class="d-flex align-center justify-center iconZone">
              <v-col class="iconWidth"><img src="/ressources/images/icon/backIcon.png" truesrc="/ressources/images/icon/backIcon.png" alt="back icon"/></v-col>
              <v-col class="textWidthBack"><p>Retour aux actualités</p></v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <section class="articleSection">
        <v-row>
          <v-col>
            <p>Saviez-vous que c’est à présent possible de commander un nouveau HX520 ou HX620 muni du nouveau moteur S13, sans système EGR, couplé à la nouvelle transmission automatisée T14 pour application sévère ?</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Saviez-vous aussi que vous pourriez réduire considérablement vos coûts en carburant, vos coûts d’entretiens et de réparation tout en augmentant la productivité de vos chauffeurs ?</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Découvrez comment et pourquoi dans cet article.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <img style="" src="../../../public/ressources/images/news/moteurS13.png" />
          </v-col>
        </v-row>
        <v-row>
          <v-col><h2>Laissez le moteur faire ce qu’il fait de mieux… sans valve EGR ?!</h2></v-col>
        </v-row>
        <v-row>
          <v-col><p>Le nouveau moteur 13 litres d’International offre une performance allant jusqu’à 515 ch et 1850 lb-pi de couple question de s’adapter aux diverses applications sévères requérant une puissance accrue. </p></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>ch et 1850 lb-pi de couple question de s’adapter aux diverses applications sévères requérant une puissance accrue. Les chauffeurs de camion vocationnels tendent à prioriser certaines fonctionnalités telles que :</p>
          </v-col>
          <v-col cols="12">
            <ul>
              <li>La durabilité et la fiabilité</li>
              <li>L’efficacité énergétique</li>
              <li>Le confort et l’ergonomie</li>
            </ul>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>En plus de porter une attention toute particulière à tous ces points, le nouveau groupe motopropulseur a été développé autour d’une idée très simple — laissons le moteur être un moteur, la transmission être une transmission et le système antipollution remplir ses fonctions.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Soyons transparents vous et nous quelques instants… Combien de fois êtes-vous allé au garage pour un problème relié au système d’EGR ? Une valve EGR qui faisait défaut ? Un refroidisseur d’EGR qui avait une fuite ? La réponse est « trop souvent », et ce, tout fabricant de moteurs confondus. Faire passer les gaz d’échappement en double à travers le moteur pour éliminer les particules polluantes réduit considérablement la durabilité des composantes. Avec 23 garages partout au Québec, nous en changeons… beaucoup !</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Le nouveau moteur S13 n’a qu’une seule tâche — vous propulser de l’avant. Pour ce faire, plus besoin de valve EGR. Le refroidisseur, ou communément appelé EGR Cooler, on s’en débarrasse. La tuyauterie d’antigel nécessaire, on l’élimine. Laisser le moteur être un moteur — c’est synonyme de durabilité, de fiabilité.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>L’élimination du système EGR relègue la responsabilité de l’élimination des particules polluantes au système d’antipollution. Sa fonction primaire est fort simple, mais la tâche n’en est pas moins difficile pour autant. Pour accomplir sa mission, ce dernier utilise un post-traitement (Aftertreatment) à deux étapes :</p>
          </v-col>
          <v-col>
            <ol>
              <li>Une dose de DEF injectée à la sortie du turbo.</li>
              <li>Une deuxième dose de DEF injectée à l’intérieur du système d’antipollution.</li>
            </ol>
          </v-col>
        </v-row>
        <v-row>
          <v-col><iframe  class="video" src="https://www.youtube.com/embed/Swg_b3VmMUQ?si=xD78MPcLnjU4icLC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Cela permet de réduire remarquablement la concentration de suie et de particules SANS la nécessité d’un catalyseur d’oxydation diesel (DOC). Le système d’antipollution est ainsi, plus facile d’entretien, plus durable, plus fiable, tout en procurant à l’appareil une efficacité énergétique bonifiée.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Fabriqué en Alabama, résultat d’une collaboration globale de toutes les filiales du Groupe TRATON (incluant Scania, Man et Volkswagen), le groupe motopropulseur S13 offre jusqu’à 15 % de gain en termes d’efficacité énergétique* par sa légèreté et son économie.</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Le tout ne serait pas possible sans l’apport de la nouvelle transmission entièrement automatisé T14 à 14 rapports. Avec un poids nominal brut combiné (ou GCWR) de plus de 140 000 lbs de capacité, cette dernière dote la série HX d’un changement de vitesse plus accéléré et souple par ces trois modes de conduite (Économie, Performance et Performance+). À noter que deux sorties de prises de forces (ou PTO) sont offertes pour accommoder l’opération de vos équipements. Les manœuvres à basse vitesse, le maintien en côte, la navigation en terrain accidenté et le décollage suite à un arrêt s’effectuent en douceur et facilité. Votre chauffeur y passera de longues heures tout en confort.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col><iframe  class="video" src="https://www.youtube.com/embed/kcZCK8a7i-Y?si=aL384TOwVhZRRCpk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></v-col>
        </v-row>
        <v-row>
          <v-col><h2>Conclusion</h2></v-col>
        </v-row>
        <v-row>
          <v-col><p>Gain en termes d’efficacité énergétique, d’efficacité opérationnelle, de temps d’entretien et d’économie de carburant : voilà les bénéfices que vous acquerrez en choisissant la série HX d’International munis du nouveau groupe motopropulseur S13 !</p></v-col>
        </v-row>
      </section>
    </div>
    <section>
      <diapoNews />
    </section>
  </div>
</template>
<style scoped>
  p { font-size: 24px; }
  .newsBg { background-color: black; padding: 100px 250px; color: #FFF; }
  .titleNews { text-align: center; font-family: MontserratBI; text-transform: uppercase; }
  .iconWidth { max-width: 80px; }
  .textWidth { max-width: 200px;}
  .textWidthBack { max-width: 300px; }
  .iconBg { margin-top: 100px; }
  .iconBg p { font-family: CooperHewittR; }
  .iconZone:hover { cursor: pointer; text-decoration: underline; }
  .articleSection { font-family: CooperHewittR; margin-top: 100px; }
  .articleSection p { padding: 20px 0px;  }
  .articleSection h2 { font-family: CooperHewittB; }
  .articleSection li { margin-left: 30px; font-size: 24px;}
  .video { display: block; width: 80%; height: 600px; margin: 50px auto; }

  @media handheld, only screen and (max-width: 600px) {}
  @media handheld, only screen and (max-width: 960px) {}
  @media handheld, only screen and (max-width: 1279px) {}
  @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
  import diapoNews from '../../components/diapo-news.vue';
export default {
  name: 'powertrainS13',
  data:()=>({

  }),
  mounted() {

  },
  methods:{
    back: function (){
      this.$router.push('/actualites')
    }
  },
  components: { diapoNews }
}
</script>
