<template>
    <div>
        <section>
            <v-row class="banner">
                <v-col class="d-flex align-center "> <div class="padOpportunities"><h1 class="sectionTitle" style="">OPPORTUNITÉS D’EMPLOI</h1></div> </v-col> 
            </v-row>
        </section>
        <!--JobText-->
        <section>
            <div class="orangeStripe"></div>
            <v-row class="jobBg">
                <v-col cols="12"><h2 class="sectionTitle">NOS POSTES DISPONIBLES</h2></v-col>
                <v-col cols="12" class="jobText">
                    <p>Tu souhaites faire partie d’une organisation dynamique dans le domaine&nbsp;du&nbsp;transport&nbsp;?</p>
                    <p>Tu auras le choix de te joindre à l’une de nos équipes dans l’un de nos 23 concessionnaires répartis à travers la province. Pour ce faire, consulte nos opportunités d’emploi ci-bas selon nos&nbsp;différentes&nbsp;places&nbsp;d’affaires.</p>
                </v-col>
            </v-row>
        </section>
        <!--JobsList-->
        <section>
            <v-row class="JobsListBg ">
                <v-col v-for="(item,i) in this.jobs" :key="i" class="career-box">
                    <v-col class="card dealerFront" style="">
                        <h2 class="career-box-Title sectionTitle">{{ item.title }}</h2>
                        <img :src="item.opportunitiesImg" :truesrc="item.opportunitiesImg" :title="'image qui réprésentation du travail ' + item.title" :alt="'image qui réprésentation du travail ' + item.title" />  
                    </v-col>  
                    <v-col class="card dealerBack" style="background-color: black;">
                        <div v-if="item.dealers.length > 0" style="position: absolute; left: 50%; top:50%; transform: translate(-50%,-50%); width: 100%;">
                            <router-link class="career-box-Town sectionTitle" v-for="(dealer,j) in item.dealers" :key="j"  :to="'/travail/'+ item.UId + '/' + dealer.town" > {{ dealer.town }}</router-link>
                        </div>
                    </v-col>                  
                </v-col>
            </v-row>
        </section>
        <!--newsletters-->
        <section>
            <div class="orangeStripe"></div>
                <v-row class="bgBlack">
                    <v-col cols="12" md="6" class="textAlignement">
                        <h2 class="sectionTitle">RESTEZ INFORMÉ(E)S&nbsp;!</h2>
                        <p class="sectionSubtitle spaceNewsletter">Abonnez-vous à notre infolettre du Réseau Dynamique pour recevoir les premiers nos promotions, événements, du contenu exclusif&nbsp;et&nbsp;plus&nbsp;encore&nbsp;!</p>
                        <a href="https://app.cyberimpact.com/clients/47814/subscribe-forms/8F9576B8-21A3-49D4-9B9C-DA19428BAE27" target="_Blank"><button style="" class="priBtn spaceNewsletter">S’inscrire</button></a>
                    </v-col>
                    <v-col class="">
                    </v-col>
            </v-row>
        </section>
    </div>
  </template>
  <style scoped>
        .padOpportunities { padding: 0px 100px; }
        .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/career/bannerOpportunities.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
        .jobBg { padding: 50px 100px; background-color: black; }
        .jobTitle {color: rgb(var(--secondary)); text-transform: uppercase; font-family: MontserratBI; font-size: 28px; }
        .jobText { color: white; margin-top: 20px; }

        .JobsListBg { min-height: 1000px; background-color: rgb(var(--ligthGray)); padding: 50px; } 
        .career-box {  position: relative; max-width: 700px; min-width: 700px; width: 700px; height: 600px; margin: 20px auto; perspective: 1000px;  }
        .career-box-Title { text-transform: uppercase; width: 100%; text-align: center; color: white; position: absolute; top:50%; left: 50%; transform: translate(-50%,-50%); font-family: MontserratBI; z-index: 100; }
        .career-box-Town { text-transform: uppercase; display: inline-block; width: 100%; text-align: center; color: white; font-family: MontserratBI;}
        .career-box-Town:hover { cursor: pointer; color: rgb(var(--primary));}
        .career-box:hover { cursor: pointer; }
        .career-box:hover .career-box-Title { color: rgb(var(--primary));  }
        .career-box img {position: relative; z-index: 10; width: 100%; height: 100%; object-fit: cover; object-position: center center; box-shadow: 0px 5px 10px 0px black; }
        .career-box-Shaddow { position: absolute; top:0px; z-index: 1; height: 700px; width: 800px; background-color: rgba(var(--black),0.5); margin: 50px auto; border-radius: 0% 0% 30% 30% / 0% 0% 30% 30%; }

        .card { width: 100%; height: 100%; position: absolute; backface-visibility: hidden; transition: transform 0.6s; }
        .dealerBack { transform: rotateY(180deg); }
        .career-box:hover .dealerBack { transform: rotateY(0deg); }
        .career-box:hover .dealerFront { transform: rotateY(180deg); }

        /*#region newsletter*/
        .bgNewsletter { display: block; background-image: url('../../public/ressources/images/home/infolettre.png'); background-position: center center; background-size: cover; background-repeat: no-repeat; }
        .bgBlack {background-color: black; }
        .textAlignement {padding: 2% 5% !important;}
        .spaceNewsletter { margin: 20px 0px; }
        /*#endregion*/
        @media handheld, only screen and (max-width: 600px) {
            .career-box { height: 300px; width: 500px; margin: 20px auto; }
            .career-box-Title { font-size: 18px; }
        }
        @media handheld, only screen and (max-width: 960px) {
            .career-box { height: 500px; width: 500px; margin: 20px auto; }
        }
        @media handheld, only screen and (max-width: 1279px) {}
        @media handheld, only screen and (max-width: 1378px) {}
  </style>
  <script>
  
  export default {
    name: 'opportunities',
    data:()=>({
        jobs:null,
        jsonDealers:null,
    }),
    async mounted() {
        await this.jobsJsonRead();
        await this.dealersJsonRead();
        await this.jobRead();

    },
    methods:{
        jobsJsonRead: async function () {
            try {
                const response = await fetch('/ressources/json/jobs.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                this.jobs = await response.json();

            } catch (error) {
                console.error('Error loading JSON data:', error);
            }
        },
        dealersJsonRead: async function () {
            try {
                const response = await fetch('/ressources/json/dealers.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                this.jsonDealers = await response.json();
            } catch (error) {
                console.error('Error loading JSON data:', error);
            }
        },
        jobRead: async function() {
            try {
                //API read
                // const response = await fetch('/ressources/json/dealers.json');
                // if (!response.ok) {
                //     throw new Error('Failed to fetch');
                // }
                let data = [
                    {
                        "key":"technicalAdvisor",
                        "title":"Aviseur(e) technique",
                        "dealersKey": ["anjou","drummondville"],
                        "infoJob":"Permanent, Temps plein de soir (40 h / semaine)",
                        "schedule":"Du lundi au vendredi, de 14 h à 00 h 30",
                        "startJob":"Dès que possible ",
                        "salary":"À discuter",
                        "yearsExperience":"Avoir 2 d’expérience",
                        "spokenLanguages":null,
                        "writtenLanguages":"Capacité à lire en anglais (un atout)",
                        "levelStudy":"Détenir un DEP (un atout)",
                        "otherRequirement":"Détenir un DEP (un atout)",
                    },
                    {
                        "key":"towTruckDriver",
                        "title":"Chauffeur(se) de remorqueuse",
                        "dealersKey": ["anjou","drummondville"],
                        "infoJob":"Permanent, Temps plein de soir (40 h / semaine)",
                        "schedule":"Du lundi au vendredi, de 14 h à 00 h 30",
                        "startJob":"Dès que possible ",
                        "salary":"À discuter",
                        "yearsExperience":"Avoir 2 d’expérience",
                        "spokenLanguages":null,
                        "writtenLanguages":"Capacité à lire en anglais (un atout)",
                        "levelStudy":"Détenir un DEP (un atout)",
                        "otherRequirement":"Détenir un DEP (un atout)",
                    }
                ]
                data.forEach(async e => {
                    let job;

                    if(e.dealersKey.length > 0){ 
                        job = await this.findJob(e.key);                      
                        job = await this.feedDealer(job,e.dealersKey);
                        if(job){
                            console.log(job);
                            let index = this.jobs.findIndex(j => j.UId == e.key);
                            if (index !== -1) {
                                this.jobs[index] = job;
                            }
                            //console.log(this.jobs.find( j => j.UId == e.key));
                        }
                    }
                    
                });

                
            } catch (error) {
                console.error('Error loading JSON data:', error);
            }
        },
        findJob: async function(jobKey){
            let jobSelected;
            try {
                let f = this.jobs.find( e => e.UId == jobKey);
                if(f){jobSelected = f;}
                
            } 
            catch (error) { this.errorMessage = "Une erreur c'est produite dans la fiche technique"; }
            return jobSelected;
        },
        feedDealer: function(jobSelected,jobDealers) {
            try {
                if(jobSelected){
                    if(this.jsonDealers){
                        jobDealers.forEach(async e => {
                            let f = this.jsonDealers[e];
                            if(f){jobSelected.dealers.push(f);}
                        });
                    } else { }
                } else { }
                
            } 
            catch (error) { this.errorMessage = "Une erreur c'est produite dans la fiche technique"; }
            //console.log(jobSelected);
            return jobSelected;
        },
    },
    components: { }
  }
  </script>
  