<template>
    <div>
      <!--Video Section-->
      <section class="videoContainer">
        <v-row>
          <v-col>
            <video width="100%" loop autoplay muted>
                <source src="/ressources/images/video/headerVideo.mp4" type="video/mp4">
                Votre navigateur ne prend pas en charge la lecture de la vidéo.
            </video>
          </v-col>
        </v-row>

      </section>
      <!--partners-->
      <section style="margin-top: -10px;">
          <v-row class="grid-pad " style="background-color: rgb(var(--ligthGray));">
            <v-col v-for="(item, i) in partners" :key="i" :class="item.minWidth"><img class="businessIcon" :src="item.logo" /></v-col>
          </v-row>
      </section>
      <!--News-->
      <section>
          <div class="news">
            <v-row class="">
              <v-col cols="12"><h2 class="sectionTitle txt-c">ACTUALITÉS</h2></v-col>
              <v-col cols="12"><p class="sectionSubtitle txt-c">Nos articles les plus récents</p></v-col>
            </v-row>
            <v-row class="blocNews">
              <v-col class="bloc" v-for="(item, i) in news" :key="i">
                <v-row><v-col cols="12"> <div style="height: auto; width:100%; "><img style="display: block; margin-left: auto; margin-right: auto; height: 205px;  width: inherit;" :src="item.img" /></div></v-col></v-row>
                <v-row><v-col cols="12"><div style="height: 200px; width:100%; overflow: hidden; margin: 0px 0px 10px 0px;"><p v-html="item.subtitle"></p></div></v-col></v-row>
                <v-row><v-col cols="12"><div><router-link :to="item.path">Lire la suite</router-link></div></v-col></v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col ><router-link to="/actualites"><button class="btn-c secBtn">Découvrez-en plus</button></router-link></v-col>
            </v-row>
          </div>
      </section>
      <!--Services-->
      <section>
        <v-row class="bgServiceGray">
          <v-col cols="12"><h2 class="sectionTitle txt-c">NOS SERVICES</h2></v-col>
          <v-col cols="12"><p class="sectionSubtitle txt-c">d’experts qui contribuent à l’essor de l’industrie des transports d’ici</p></v-col>
        </v-row>
        <v-row class="serviceSection bgSales" >
          <v-col cols="12"><router-link to="/ventes"><h2 class="sectionTitle serviceTitle">ventes</h2></router-link></v-col>
        </v-row>
        <v-row>
          <v-col class="serviceSection bgparts">
            <router-link to="/parts"><h2 class="sectionTitle serviceSubtitle">PIÈCES</h2></router-link>
          </v-col>
          <v-col class="serviceSection bgservice" style="">
            <router-link to="/services"><h2 class="sectionTitle serviceSubtitle">SERVICE</h2></router-link>
          </v-col>
          <v-col class="serviceSection bglocation" >
            <router-link to="/"><h2 class="sectionTitle serviceSubtitle">LOCATION</h2></router-link>
          </v-col>
        </v-row>
      </section>
      <!--Story-->
      <section id="about">
        <div>
          <v-carousel class="sliderHeight" show-arrows cycle hide-delimiters style="">
            <template v-slot:prev="{ props }"> <h2 class="storyArrow arrowPaging arrowPrev" @click="props.onClick"> &lt; </h2></template>
            <template  v-slot:next="{ props }"> <h2 class="storyArrow arrowPaging arrowNext" @click="props.onClick"> > </h2></template>
            <v-carousel-item>
              <div class="bgCarousselStory">
                <div class="myCaroussel">
                  <h2 class="sectionTitle">NOTRE HISTOIRE</h2>
                  <p>Réseau Dynamique est une entreprise familiale québécoise transmise d’une génération à l’autre. Claude Hallé, avec ses capacités entrepreneuriales et son leadership visionnaire, fonde le Centre du Camion Beaudoin à Drummondville en 1978. Son sens du développement des affaires amène l’entreprise à un autre niveau avec l’ouverture de plusieurs concessionnaires à travers la province pour former un seul réseau d’experts.</p>
                  <p>Ses enfants, Steve et Nathalie Hallé, qui ont repris le flambeau depuis quelques années, profitent de son expertise pour élever la marque et apportent plusieurs changements structurels afin d’offrir des produits et services toujours à la fine pointe de la technologie.</p>
                </div>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="bgCarousselDistinction">
                <div class="myCaroussel">
                  <h2 class="sectionTitle">CE QUI NOUS DISTINGUE</h2>
                  <p>Voilà près de 100 ans que notre entreprise familiale est présente à chaque étape de votre évolution pour que vous puissiez vous concentrer à livrer ce qui compte et déplacer ce qui est possible. Pour accomplir ceci depuis aussi longtemps que nous le faisons, nous mettons les meilleures marques et produits. Ainsi que l’équipe de professionnels la plus expérimentée au Québec à votre disposition.</p>
                  <p>Le Réseau Dynamique, c’est 23 concessionnaires et points de services des marques International, Isuzu, Kalmar, Doepker, Fleetrite et Battle Motors partout en province, de Montréal jusqu’à Sept-Îles.</p>
                </div>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="bgCarousselForce">
                <div class="myCaroussel">
                  <h2 class="sectionTitle">LA FORCE DE NOTRE RÉSEAU</h2>
                  <p>Accessibles partout au Québec, nous offrons une grande disponibilité ainsi qu’un accès privilégié en termes d’expertise, de produits et de services grâce à notre regroupement constitué de 23 concessionnaires et associé(e)s répartis sur le territoire. Grâce à la force du nombre, nous assurons un service après-vente hors pair.</p>
                  <p>Par ailleurs, nous avons été les premiers à se réunir afin de faire profiter à nos clients de qualifications et connaissances plus poussées dans le domaine du transport, d’une plus grande accessibilité et d’un meilleur pouvoir d’achat. De plus, notre réseau d’experts reçoit de la formation continue tout au long de l’année dans le but d'acquérir un savoir-faire sans égal.</p>
                </div>
              </div>
            </v-carousel-item>
            <v-carousel-item>
              <div class="bgCarousselMisson">
                <div class="myCaroussel">
                  <h2 class="sectionTitle">NOTRE MISSION</h2>
                  <p>Notre équipe attentive, dynamique, disponible et toujours à l’affût des changements est déterminée à offrir à notre clientèle un service à la hauteur de leurs attentes ainsi qu’adapté à leurs besoins. Nous tâchons d’établir une étroite collaboration avec chacun de nos clients afin de prendre connaissance des objectifs de leur entreprise, de nous familiariser avec leur environnement de travail et d’être aux faits de leurs besoins en matière de service à la clientèle.</p>
                  <p>Offrir un service de qualité supérieure dans tous les domaines de la vente de camions et du service, c’est ce à quoi nous nous dévouons et c’est pour cette raison que l’on peut compter sur nous.</p>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </section>
      <!--value-->
      <section>
        <v-row class="bgValueGray" style="position: relative;">
          <v-col cols="12"><h2 class="sectionTitle txt-c">NOS VALEURS</h2></v-col>
          <v-col cols="12"><p class="sectionSubtitle txt-c">Ce qui compte pour nous</p></v-col>
          <v-row  class="" style="position: absolute; width: 100%; top: 70%; padding: 0px 50px;">
              <v-col class="absValue category" @mouseover="valueSelected = 0" @click="valueSelected = 0">
                <h3 :class="{'f-primary': valueSelected == 0 }" class="categoryTitle" >Disponibilité</h3>
                <img class="iconValue" src="/ressources/images/home/Disponibility.jpg" />
              </v-col>
              <v-col class="absValue category" @mouseover="valueSelected = 1" @click="valueSelected = 1">
                <h3 :class="{'f-primary': valueSelected == 1 }" class="categoryTitle" >COMPÉTENCE</h3>
                <img class="iconValue" src="/ressources/images/home/Competence.jpg" />
              </v-col> 
              <v-col class="absValue category" @mouseover="valueSelected = 2" @click="valueSelected = 2">
                <h3 :class="{'f-primary': valueSelected == 2 }" class="categoryTitle" >INNOVATION</h3>
                <img class="iconValue" src="/ressources/images/home/Innovation.jpg" />
              </v-col>
              <v-col class="absValue category" @mouseover="valueSelected = 3" @click="valueSelected = 3">
                <h3 :class="{'f-primary': valueSelected == 3 }" class="categoryTitle" >QUALITÉ <br> SUPÉRIEURE</h3>
                <img class="iconValue" src="/ressources/images/home/quality.jpg" />
              </v-col>
            </v-row>
        </v-row>
        <v-row class="bgWhite">
          <v-row id="categoryDisponibility">
            <v-carousel style="height: 100%;" v-model="valueSelected" :show-arrows="false" hide-delimiters>
              <h2 class="valueArrow arrowPaging arrowPrev" @click="prevPage()">&lt;</h2>
              <v-carousel-item v-for="(item, i) in valueSlides" :key="item" :value="i">
                <v-col cols="12">           
                  <img class="relValue iconValueSlide" :src="item.img" />
                </v-col>
                <v-col cols="12" class="positioningValue" style="">
                  <h2 class="valueBg" v-html="item.title"></h2>
                  <p class="valueSubtitle" v-html="item.text"></p>
                </v-col>
              </v-carousel-item>
              <h2 class="valueArrow arrowPaging arrowNext" @click="nextPage()">&gt;</h2>
            </v-carousel>

            
          </v-row>
        </v-row>
      </section>
      <!--map-->
      <section>
        <div class="mapBG">
          <v-row class="mapPa">
            <v-col>
              <v-row>
                <v-col style="max-width: max-content;">
                  <h3 style="font-size: 139px; font-family: MontserratBI;">23</h3>
                </v-col>
                <v-col >
                  <v-row>
                    <v-col style="max-width: max-content;" cols="12">
                      <h3 style="font-size: 36px; padding-left: 10px; margin-top: 50px; font-family: MontserratBI;">CONCESSIONNAIRES</h3><br>
                      <router-link to="/nous-joindre" style="padding-left: 10px !important; margin-top: -20px;"><button  style="width: 97%; margin-top: -10px;" class="secBtn">Nous joindre</button></router-link>
                    </v-col>
                    <v-col cols="12"></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="imgArrow" style="">
              <v-row>
                <v-col cols="12" ><h3 style="text-align: center; font-size: 36px; padding-left: 100px; font-family: MontserratBI;">À SEPT-ÎLES</h3></v-col>
                <v-col cols="12" ><img style="display: block; width: 300px; margin-left: auto; margin-right: auto;" src="../../public/ressources/images/home/fleche.png" /></v-col>
                <v-col cols="12"><h3 style="text-align: center; font-size: 36px; padding-right: 300px; font-family: MontserratBI;">DE MONTRÉAL</h3></v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </section>
      <!--newsletters-->
      <section>
        <v-row>
          <v-col class="bgBlack">
              <h2 class="sectionTitle">RESTEZ INFORMÉ(E)S&nbsp;!</h2>
              <p class="sectionSubtitle spaceNewsletter">Abonnez-vous à notre infolettre du Réseau Dynamique pour recevoir les premiers nos promotions, événements, du contenu exclusif&nbsp;et&nbsp;plus&nbsp;encore&nbsp;!</p>
              <a href="https://app.cyberimpact.com/clients/47814/subscribe-forms/9DA9DF1E-9467-4FB2-9E34-03537D03367C" target="_blank"><button style="" class="priBtn spaceNewsletter">S’inscrire</button></a>
          </v-col>
          <v-col cols="6" class="bgNewsletter">
          </v-col>
        </v-row>
      </section>
    </div>
  </template>
  <style scoped>
  /*#region bande business*/
    .largeIcon {min-width: 250px;}
    .smallIcon {min-width: 150px;}
    .businessIcon { display: block; height: 100px; width: auto; margin-left: auto; margin-right: auto; margin-top: 10px; margin-bottom: 10px;}
  /*#endregion*/
  /*#region news*/
    .news { padding:50px; background-image: url('../../public/ressources/images/home/bgActualites.jpg');  background-position: center center; background-size: cover; background-repeat: no-repeat; }
    .bloc { display: block; margin-left: auto; margin-right: auto; height: auto; margin-top: 50px; min-width: 350px; max-width: 400px; margin-bottom: 50px; background-color: black; padding-bottom: 40px !important; color: white ; }
    .bloc img { padding: 10px;}
    .bloc p { padding: 20px 10px; font-family: CooperHewittR; }
    .bloc a { color: white; padding: 20px 10px; font-family: CooperHewittR; font-weight: 700; }
  /*#endregion*/
  /*#region story*/
    .bgCarousselStory { height: 100%; background-image: url('../../public/ressources/images/home/caroussel/histoire.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
    .myCaroussel { color: white;  padding: 5%; }
    .myCaroussel p { width: 75%; font-size: 18px; margin: 25px 0px 0px 0px; font-family: CooperHewittR; }
    .bgCarousselDistinction { height: 100%; background-image: url('../../public/ressources/images/home/caroussel/distingue.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
    .bgCarousselForce { height: 100%; background-image: url('../../public/ressources/images/home/caroussel/force.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
    .bgCarousselMisson { height: 100%; background-image: url('../../public/ressources/images/home/caroussel/mission.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}
    .sliderHeight { height: 500px ;}
    /*#endregion*/
    /*#region value*/
      .valueTitle { font-family: MontserratBI; color: rgb(var(--primary));}
      .valueBg { font-size: 100px;  position: absolute; top:50%; left: 50%; transform: translate(-50%,-40%); z-index: 10; font-family: MontserratBI; color: rgba(var(--primary),0.1); }
      .valueSubtitle { font-family: CooperHewittB; position: absolute; top:50%; left: 50%; text-align: center; transform: translate(-50%,-50%); z-index: 100; margin-top: 20px; }
      .category {position: relative; text-transform: uppercase;}
      .category:hover {cursor: pointer;}
      .categoryTitle { font-family: MontserratBI; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); color: white; text-align: center; font-size: 26px;}
      .category:hover h3 { color: rgb(var(--primary)); }
      .bgWhite { height: 400px; padding: 225px 0px 0px 0px; }
      .iconValue { max-width: 250px; display: block; margin-left: auto; margin-right: auto;}
      .iconValueSlide {width: 250px; margin-top: 10px; margin-left: auto; margin-right: auto;}
      .absValue {display: block;}
      .relValue {display: none;}
      .bgValueGray { background-color: rgb(var(--gray)); padding: 50px 0px 120px 0px; }
      .positioningValue { margin-top: 40px; position: relative; }
      .storyArrow { display: block; }
      .valueArrow { display: none; }
      .arrowPaging { position: absolute; font-size: 76px; cursor: pointer; color: rgb(var(--primary)); z-index: 1000;}
      .arrowPaging:hover { color: white; text-shadow: 0px 0px 8px rgb(var(--primary)); }
      .arrowNext { padding-left: 40px; top:40%; right: 1%; }
      .arrowPrev { padding-right: 20px; top:40%; left: 1%; }
      
    /*#endregion*/
    /*#region services*/
      .bgServiceGray { background-color: rgb(var(--gray)); padding: 50px 0px 50px 0px; }
      .bgSales { background-image: url('../../public/ressources/images/home/serviceSales.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgparts { background-image: url('../../public/ressources/images/home/PIECES.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgservice { background-image: url('../../public/ressources/images/home/SERVICE.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bglocation { background-image: url('../../public/ressources/images/home/LOCATION.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .serviceTitle { font-family: MontserratBI; padding: 10% 0px; text-align: center; margin: 10px 0px !important; text-transform: uppercase; color: white; }
      .serviceSubtitle { font-family: MontserratBI; padding: 30% 0px; text-align: center; margin: 10px 0px; text-transform: uppercase; color: white; }
      .serviceSection:hover  .serviceTitle {color: rgb(var(--primary)); cursor: pointer;}
      .serviceSection:hover  .serviceSubtitle {color: rgb(var(--primary)); cursor: pointer;}
    /*#endregion*/
    /*#region map*/
    .mapBG { color: white; background-image: url('../../public/ressources/images/home/mapConcession.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat; }
    .imgArrow { position: relative; display: block; }
    .mapPa { padding: 5% 5% !important; }
    /*#endregion*/
    /*#region newsletter*/
      .bgNewsletter { display: block; background-image: url('../../public/ressources/images/home/infolettre.jpg'); background-position: center center; background-size: cover; background-repeat: no-repeat; }
      .bgBlack { background-color: black; padding: 9% 5% !important; }
      .spaceNewsletter { margin: 20px 0px; }
      /*#endregion*/
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
      .myCaroussel { color: white;  padding: 2%; }
      .myCaroussel h2 { font-family: MontserratBI; font-size: 24px; }
      .myCaroussel p { width: 100%; font-size: 14px;}
      .bgNewsletter {display: none;}
      .serviceSubtitle { padding: 10% 0px; margin: 0px !important;}
      .serviceSection { min-width: 95%; margin: 10px;}
      .spacingServices { margin: 10px; }
      .bgServiceGray { padding: 20px 0px; }
      .valueTitle { display: none; }
      .bgValueGray { background-color: rgb(var(--gray)); padding: 50px 0px 50px 0px; }
      .positioningValue { margin-top: 60px; }
      .valueBg { font-size: 36px; }
      .valueSubtitle {  position: relative; top:0%; left: 0%; text-align: center; transform: unset; margin-right: auto; margin-top: 0px; }
      .valueArrow { display: block; }
      .absValue {display: none;}
      .relValue {display: block;}
      .bgWhite { height: 500px !important; padding: 20px 0px 0px 0px !important; }
      .sliderHeight { height: 400px !important;}
    }
    @media handheld, only screen and (max-width: 1279px) {
      .imgArrow {display: none;}
      .mapPa { padding: 8% 5% !important; }
      .bgWhite { height: 300px; padding: 100px 0px 0px 0px; }
      .iconValue { max-width: 150px;}
      .categoryTitle {  font-size: 16px;}

    }
    @media handheld, only screen and (max-width: 1378px) {}
  
  </style>
  <script>

  export default {
    name: 'Home',
    data:()=>({
      news: [],
      partners: [
        {  minWidth:'largeIcon', logo:'/ressources/images/logo/DoepkerTrailers.png' },
        {  minWidth:'smallIcon', logo:'/ressources/images/logo/IsuzuCamion.png' },
        {  minWidth:'smallIcon', logo:'/ressources/images/logo/International.png' },
        {  minWidth:'smallIcon', logo:'/ressources/images/logo/BattleMotors.png' },
        {  minWidth:'largeIcon', logo:'/ressources/images/logo/KalmarOttawa.png' }

      ],
      valueSelected: 0,
      valueSlides: [
        { title:'DISPONIBILITÉ', text: "Tenir en tout temps un large inventaire de pièces et disposer de plusieurs points de service à travers le Québec", img: "/ressources/images/home/DisponibilityMobile.jpg" },
        { title:'COMPÉTENCE', text: "Miser sur la formation continue de nos employé(e)s afin d'actualiser leur savoir-faire", img: "/ressources/images/home/competenceMobile.jpg" },
        { title:'INNOVATION', text: "Demeurer à la fine pointe de la technologie grâce à nos installations modernes et nos équipements spécialisés", img: "/ressources/images/home/InnovationMobile.jpg" },
        { title:'QUALITÉ&nbsp;SUPÉRIEURE', text: "Livrer des standards de qualité supérieurs dans l'ensemble de nos actions", img: "/ressources/images/home/qualityMobile.jpg" }
        
      ],
    }),
    async mounted() {
      await this.NewsRead();
    },
    methods:{
      prevPage() {
        if (this.valueSelected > 0) this.valueSelected--;
      },
      nextPage() {
        if (this.valueSelected < (this.valueSlides.length - 1)) this.valueSelected++;
      },
      NewsRead: async function () {
        try {
            const response = await fetch('/ressources/json/news.json');
            if (!response.ok) { throw new Error('Failed to fetch'); }
            this.data = await response.json();
            if(this.data) {
              let newsOrdered = this.data;
              newsOrdered.sort(function(a,b){ return new Date(a.Date) - new Date(b.Date);});
              this.news = newsOrdered.slice(0,3); 
            }
        } catch (error) { console.error('Error loading JSON data:', error); }
      },

    },
    components: { }
  }
  </script>
  