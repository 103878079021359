<template>
    <v-row class="d-flex align-center header">
        <!--
        <v-col cols="12" class="">
          <v-row class="grid-pad"> 
            <v-col class=""></v-col>
            <v-col class="emergencyWidth" >
              <v-row class="emergencyBox">
                <v-col class="emergencyTextPosition"><p class="MontserratBI">URGENCE ROUTIÈRE 24H DISPONIBLE PARTOUT AU QUÉBEC</p></v-col> 
                <v-col class="phoneBG" cols="1" ><img style="height: 20px; width: auto; position: absolute; left: 50%; top:50%; transform: translate(-50%,-48%);" src="./../../public/ressources/images/icon/phone.svg" /></v-col>               
              </v-row>
            </v-col>
          </v-row>
        </v-col>-->
        <v-col cols="12" class="compagny">
          <v-row class="d-flex align-center">
            <v-col class="compagnyWidth">
              <img class="compagnyLogo" src="/ressources/images/logo/logoBlanc.png" />
            </v-col>
            <v-col class="pa-0">
              <div style="width: auto;">
                <p class="compagnyText">QUÉBEC LÉVIS RIVIÈRE-DU-LOUP GRANBY BOUCHERVILLE FORESTVILLE THETFORD SAINT-HYACINTHE SHAWINIGAN MATANE ANJOU CHICOUTIMI SEPT-ÎLES SAINTE-M VICTORIAVILLE SAINT-GEORGES CLERMONT JOLIETTE RIMOUSKI DRUMMONDVILLE QUÉBEC LÉVIS RIVIÈRE-DU-LOUP GRANBY BOUCHERVILLE FORESTVILLE THETFORD SAINT-HYACINTHE SHAWINIGAN MATANE ANJOU CHICOUTIMI SEPT-ÎLES SAINTE-M VICTORIAVILLE SAINT-GEORGES CLERMONT JOLIETTE RIMOUSKI DRUMMONDVILLE QUÉBEC LÉVIS RIVIÈRE-DU-LOUP GRANBY BOUCHERVILLE FORESTVILLE THETFORD SAINT-HYACINTHE SHAWINIGAN MATANE ANJOU CHICOUTIMI SEPT-ÎLES SAINTE-M VICTORIAVILLE SAINT-GEORGES CLERMONT JOLIETTE RIMOUSKI DRUMMONDVILLE QUÉBEC LÉVIS RIVIÈRE-DU-LOUP GRANBY BOUCHERVILLE FORESTVILLE THETFORD SAINT-HYACINTHE SHAWINIGAN MATANE ANJOU CHICOUTIMI SEPT-ÎLES SAINTE-M VICTORIAVILLE SAINT-GEORGES CLERMONT JOLIETTE RIMOUSKI DRUMMONDVILLE</p>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="menuBG ">
          <v-row class="d-flex align-center MenuDesktop" >
            <v-col class="grid-pad">
              <v-row class=" ma-0 d-flex align-center" style=" position: relative; z-index: 10000; margin-right: auto !important; margin-left: auto !important;">
                  <div class="categories">
                    <h3 class="align-center"><router-link to="/" class=" d-flex align-center">Accueil</router-link> </h3>
                    <div class="subMenu">
                      <router-link to="/#about"><h3 class="subText">À propos</h3></router-link>
                      <router-link to="/actualites"><h3 class="subText">Actualités</h3></router-link>
                    </div>
                  </div>
                  <div class="categories">
                    <h3 class="align-center"><router-link to="/" class=" d-flex align-center">Camions</router-link> </h3>
                    <div class="subMenu">
                      <router-link to="/inventaire?n=1"><h3 class="subText subTitle">Inventaire</h3></router-link>
                      <router-link to="/inventaire?n=1"><h3 class="subText">Neufs</h3></router-link>
                      <router-link to="/inventaire"><h3 class="subText">Usagés</h3></router-link>
                      <router-link to="/"><h3 class="subText subTitle">FAIRE ÉVALUER MON VÉHICULE</h3></router-link>
                      <a href="https://www.internationaltrucks.com/engines/s13integrated" target="_blank"><h3 class="subText subTitle">Moteur<br>S13 intégré</h3></a>
                      <router-link to="/camionElectrique"><h3 class="subText subTitle">Véhicules<br>Électriques</h3></router-link>
                      <router-link to="/inventaire"><h3 class="subText subTitle">Location<br>Pinard</h3></router-link>
                      <a href="https://www.internationaltrucks.com" target="_blank"><h3 class="subText subTitle">INTERNATIONAL</h3></a>
                      <a href="https://isuzucv.com/en/index" target="_blank"><h3 class="subText subTitle">Isuzu</h3></a>
                      <a href="https://www.kalmarottawa.com/" target="_blank"><h3 class="subText subTitle">Kalmar Ottawa</h3></a>
                      <a href="https://battlemotors.com/" target="_blank"><h3 class="subText subTitle">Battle Motors</h3></a>
                      <a href="https://www.doepker.com/" target="_blank"><h3 class="subText subTitle">Remorque<br>Doepker</h3></a>
                    </div>
                  </div>
                  <div class="categories">
                    <h3 class="align-center"><router-link to="/" class=" d-flex align-center">Configurez<br>votre&nbsp;véhicule</router-link> </h3>
                    <div class="subMenu">
                        <h3 class="subText subTitle">modèles</h3>
                        <a href="https://www.internationaltrucks.com/configurator/lt" target="_blank"><h3 class="subText">série lt</h3></a>
                        <a href="https://www.internationaltrucks.com/configurator/rh" target="_blank"><h3 class="subText">série rh</h3></a>
                        <a href="https://www.internationaltrucks.com/configurator/hx" target="_blank"><h3 class="subText">série hx</h3></a>
                        <a href="https://www.internationaltrucks.com/configurator/hv" target="_blank"><h3 class="subText">série hv</h3></a>
                        <a href="https://www.internationaltrucks.com/configurator/mv" target="_blank"><h3 class="subText">série mv</h3></a>
                        <a href="https://www.internationaltrucks.com/configurator/emv" target="_blank"><h3 class="subText">série emv</h3></a>
                        <a href="https://www.internationaltrucks.com/configurator/cv" target="_blank"><h3 class="subText">série cv</h3> </a>
                    </div>
                  </div>
                  <div class="categories">
                    <h3 class="align-center"><router-link to="/piece" class=" d-flex align-center">pièces</router-link> </h3>
                    <div class="subMenu">
                        <router-link to="/promotion"><h3 class="subText subTitle">promotions</h3></router-link>
                        <a href="https://www.nexpart.com/login.php?err=1&red=failed&return_url=" target="_blank"><h3 class="subText">Nexpart</h3></a>
                        <a href="https://repairlinkshop.com/Account/Login?ReturnUrl=%2f" target="_blank"><h3 class="subText">repairlink</h3></a>
                        <a href="https://www.fleetrite.com/" target="_blank"><h3 class="subText">fleetrite</h3></a>
                        <a href="https://www.fleetcharge.com/ui/home" target="_blank"><h3 class="subText">fleet charge</h3></a>
                    </div>
                  </div>
                  <div class="categories">
                    <h3 class="align-center"><router-link to="/services" class=" d-flex align-center">Service</router-link> </h3>
                    <div class="subMenu">
                        <router-link to="/urgenceRoutiere"><h3 class="subText">Urgence routière</h3></router-link>
                        <a href="https://www.internationaltrucks.com/connected-vehicle/solutions/international-360" target="_blank"><h3 class="subText">International 360</h3></a>
                    </div>
                  </div>
                  <div class="categories">
                    <h3 class="align-center"><router-link to="/nous-joindre" class=" d-flex align-center">Nos&nbsp;concessionnaires</router-link> </h3>
                  </div>
                  <div class="categories">
                    <h3 class="align-center"><router-link to="/carriere" class=" d-flex align-center">Carrières</router-link> </h3>
                    <div class="subMenu">
                        <router-link to="/carriere"><h3 class="subText">Ton avenir avec nous</h3></router-link>
                        <router-link to="/opportunites"><h3 class="subText">Opportunités d'emploi</h3></router-link>
                        <router-link to="/etudiant"><h3 class="subText">Stages et emplois étudiants</h3></router-link>
                    </div>
                  </div>
              </v-row>
            </v-col>
            <v-col class="logoWidth">
              <v-row>
                <v-col class="" style="position: relative; height: 50px;">
                  <div class="bg360" style="">
                    <div class="zoomHover" ><a href="https://www.internationaltrucks.com/trucks/emv-series" target="_blank"><img class="logo360" style=""  src="./../../public/ressources/images/logo/Idealease.png" /></a></div>
                  </div>
                </v-col>
                <v-col class="" style="position: relative; height: 50px;">
                  <div class="bgS13" style="">
                    <div class="zoomHover" ><a href="https://www.internationaltrucks.com/engines/s13integrated" target="_blank"><img class="logoS13" style=""  src="./../../public/ressources/images/logo/S13INTEGRATED.png" /></a></div>
                  </div>
                </v-col>
                <v-col class="" style="position: relative; height: 50px;">
                  <div class="bgE" style="height: 120%; width: 115px; background-color: rgb(var(--secondary)); position: absolute; top: -10%; right:0%; transform: skewX(0deg);  z-index: 10;">
                    <div class="zoomHover" ><a href="https://www.internationaltrucks.com/trucks/emv-series" target="_blank"><img class="logoE" style=""  src="./../../public/ressources/images/logo/S13INTEGRATED(2).png" /></a></div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="MenuMobile grid-pad">
                <v-col class="d-flex align-center "><img class="" style="width:35px; height:35px" @click.stop="this.drawer = !this.drawer" src="/ressources/images/icon/menu.svg"/></v-col>
                <v-navigation-drawer class="drawer" v-model="this.drawer" location="left" style="">
                    <v-list>
                        <v-list-item class="pa-0 ma-0">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="pl-5 pr-0">
                                        <v-row class="ma-0">
                                            <v-col cols="10" class="d-flex align-center justify-start"><h3 class="align-center"><router-link to="/" class=" d-flex align-center">Accueil</router-link> </h3></v-col>
                                            <v-col cols="2" class="d-flex align-center justify-end"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="/ressources/images/icon/expand_more_black_24dp.svg"/></div></v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text><router-link to="/"><h3 class="subText">À propos</h3></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/actualites"><h3 class="subText">Actualités</h3></router-link></v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>                        
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                            <v-expansion-panels>
                                <v-expansion-panel style="padding: 0px;">
                                    <v-expansion-panel-title class="pl-5 pr-0">
                                        <v-row class="ma-0">
                                            <v-col cols="10" class="d-flex align-center justify-start"><h3 class="align-center"><router-link to="/" class=" d-flex align-center">Inventaire</router-link> </h3></v-col>
                                            <v-col cols="2" class="d-flex align-center justify-end"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="/ressources/images/icon/expand_more_black_24dp.svg"/></div></v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text style="padding: 0px;"><h3 class="subText subTitle">Camions</h3></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/"><h3 class="subText">neufs</h3></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/"><h3 class="subText">usagés</h3></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><h3 class="subText subTitle">Remorques</h3></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/"><h3 class="subText">neuves</h3></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><router-link to="/"><h3 class="subText">usagées</h3></router-link></v-expansion-panel-text>
                                    <v-expansion-panel-text><h3 class="subText blueSubTitle">Liquidation</h3></v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="pl-5 pr-0">
                                        <v-row class="ma-0">
                                            <v-col cols="10" class="d-flex align-center justify-start"><h3 class="align-center"><router-link to="/" class=" d-flex align-center">Configurez votre véhicule</router-link> </h3></v-col>
                                            <v-col cols="2" class="d-flex align-center justify-end"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="/ressources/images/icon/expand_more_black_24dp.svg"/></div></v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text><h3 class="subText subTitle">Modèles</h3></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.internationaltrucks.com/configurator/lt"><h3 class="subText">série lt</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.internationaltrucks.com/configurator/rh"><h3 class="subText">série rh</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.internationaltrucks.com/configurator/hx"><h3 class="subText">série hx</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.internationaltrucks.com/configurator/hv"><h3 class="subText">série hv</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.internationaltrucks.com/configurator/mv"><h3 class="subText">série mv</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.internationaltrucks.com/configurator/emv"><h3 class="subText">série emv</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.internationaltrucks.com/configurator/cv"><h3 class="subText">série cv</h3> </a></v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="pl-5 pr-0">
                                        <v-row class="ma-0">
                                            <v-col cols="10" class="d-flex align-center justify-start"><h3 class="align-center"><router-link to="/" class=" d-flex align-center">pièces</router-link> </h3></v-col>
                                            <v-col cols="2" class="d-flex align-center justify-end"><div style="height:35px;"><img class="" style="width:100% ; height:100%;" src="/ressources/images/icon/expand_more_black_24dp.svg"/></div></v-col>
                                        </v-row>
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text><h3 class="subText subTitle">promotions</h3></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://repairlinkshop.com/Account/Login?ReturnUrl=%2f" target="_blank"><h3 class="subText">repairlink</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.fleetrite.com/" target="_blank"><h3 class="subText">fleetrite</h3></a></v-expansion-panel-text>
                                    <v-expansion-panel-text><a href="https://www.fleetcharge.com/ui/home" target="_blank"><h3 class="subText">fleetcharge</h3></a></v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>                        
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0"><h3 class="title align-center"><router-link to="/" class=" d-flex align-center">Services</router-link> </h3></v-list-item>
                        <v-list-item class="pa-0 ma-0"><h3 class="title align-center"><router-link to="/nous-joindre" class=" d-flex align-center">Nos concessionnaires</router-link> </h3></v-list-item>
                        <v-list-item class="pa-0 ma-0"><h3 class="title align-center"><router-link to="/" class=" d-flex align-center">Carrières</router-link> </h3></v-list-item>
                    </v-list>
                </v-navigation-drawer>         
            </v-row>
        </v-col>
    </v-row>
  </template>
  <style scoped>
    .v-expansion-panel-text {padding: 0px !important;}
  </style>
  <style scoped>
    /*#region emergency*/
      .emergencyWidth {min-width: 700px; max-width: 700px; padding: 0px;} /*Padding 0 is correcting vuetify*/
      .emergencyBox {border: 2px solid rgb(var(--black)); border-radius: 5px; margin: 10px 0px !important;}
      .phoneBG { background-color: rgb(var(--black)); padding: 0px; position: relative;}
      .emergencyTextPosition{ padding: 0px 20px !important; text-align: right;}
    /*#endregion */
    /*#region compagny*/
      .compagny {height: 100px; background-color: rgb(var(--secondary)); max-height: 100px; padding: 0px;}
      .compagnyWidth {min-width: 260px; max-width: 260px; padding: 0px;}
      .compagnyLogo { width: auto; max-height: 100px; padding: 10px;}
      .compagnyText {max-height: 99px; font-family: MontserratBI; text-align: justify; overflow: hidden; color: #2499FF; margin-top: -10px; }
    /*#endregion */
    /*#region Menu*/
      .menuBG { background-color: rgb(var(--gray));}
      .MenuDesktop{display: block;}
      .MenuMobile {display: none;}
      .menu h3 {font-family: MontserratBI; color: rgb(var(--white)); text-transform: uppercase; text-align: center; height: 100%;}
      .menu { height: auto; }
      .menu:hover {background-color: rgb(var(--primary)); }
      .categories a{font-family: MontserratBI; font-size: 16px; color: rgb(var(--white)); text-transform: uppercase; text-align: center; position: relative; height:50px !important; padding: 10px 20px; text-transform: uppercase; font-weight: 700; }
      .categories:hover a{background-color:rgb(var(--secondary));} 
      .categories .subMenu a{ height: unset; padding: 0px; text-align: left; background-color: rgb(var(--ligthGray)); color: rgb(var(--gray));}
      .categories .subMenu a:hover{color: rgb(var(--secondary));} 
      .subMenu { font-family: MontserratBI; display: none; position:absolute; background-color: rgb(var(--ligthGray)); min-width: 300px; }
      .subText { padding: 10px 20px; text-transform: uppercase;}
      .subTitle{ color: rgb(var(--white)); background-color: rgb(var(--gray));}
      .blueSubTitle { color: rgb(var(--white)); background-color: rgb(var(--secondary)); }
      .MenuMobile .title {margin: 10px; padding: 10px;}
      .v-expansion-panel-text__wrapper {padding: 0px !important;}
      .categories:hover .subMenu { display: block; }

      .zoomHover { height: inherit; max-height: 100%; width: auto;  overflow: hidden;  }
      .zoomHover img { transition: transform .5s ease; }
      .zoomHover:hover img { transform: scale(1.1); }
      .logoWidth { display: block; min-width: 350px; max-width: 350px; }
      .bg360 { height: 120%; width: 115px; background-color: rgb(var(--ligthGray)); position: relative; top: -10%; right:0%; transform: skewX(-10deg);  z-index: 10; }
      .bgS13 { height: 140%; width: 125px;background-color: rgb(var(--primary)); position: absolute; top: -20%; right:-6%; transform: skewX(-10deg); z-index: 100; }
      .bgE { height: 120%; width: 115px; background-color: rgb(var(--secondary)); position: absolute; top: -10%; right:0%; transform: skewX(0deg);  z-index: 10; } 
      .logo360 { position: absolute; height: auto; width: 100%; max-width: 110px; top: 30%; margin-left: auto; margin-right: auto; display: block; }
      .logoS13 { height: 100%; width: auto; max-width: 115px; margin-left: auto; margin-right: auto; display: block; }
      .logoE { height: 150%; width: auto;  max-width: 110px;  margin-left: auto; margin-right: auto; display: block; margin-top: -15px; }
    /*#endregion */

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
      .MenuDesktop{display: none !important;}
      .MenuMobile {display: block !important;}
      .compagnyText {display: none; }
    }
    @media handheld, only screen and (max-width: 1065px) { 
      .logoWidth {display: none;} 
    }
    @media handheld, only screen and (max-width: 1279px) {
      .categories a {font-size: 10px !important;}
    }
    @media handheld, only screen and (max-width: 1500px) {
      .categories a {font-size: 12px;}
      .logoWidth {  min-width: 250px; max-width: 250px; }
      .bg360 { width: 85px; }
      .bgS13 { width: 95px; }
      .bgE { width: 85px !important; } 
      .logo360 { height: auto; width: 90%; }
      .logoS13 { height: auto; width: 100%; margin-top:12%; }
    }
    
  </style>
  <script>
  
  export default {
    name: 'header',
    data:()=>({
      drawer: false,
      menu:[
            {title:'Accueil',path:'/'},
            {title:'Inventaire',path:'/'},
            {
              title:'Configurez<br>votre&nbsp;véhicule',
              subMenu: [
                {subTitle:'modèles'},
                {subTitle:'Série LT',path:'/'},
                {subTitle:'Série RH',path:'/'},
                {subTitle:'Série HX',path:'/'},
                {subTitle:'Série HV',path:'/'},
                {subTitle:'Série MV',path:'/'},
                {subTitle:'Série eMV',path:'/'},
                {subTitle:'Série CV',path:'/'}
              ]
            },
            {title:'pièces',path:'/'},
            {title:'Services',path:'/'},
            {title:'Nos&nbsp;concessionnaires',path:'/'},
            {title:'Carrières',path:'/'}
        ]
    }),
    mounted() {

    },
    methods:{

    },
    components: { }
  }
  </script>
  