<template>
  <div class="bodyColor">
    <!--Banner-->
    <section class="">
        <div class="banner">
            <h1 class="PageTitle" style="">Service</h1>
        </div>
    </section>
    <!--after Sale Info-->
    <section>
      <v-row class="afterSaleBg">
        <v-row class="container" no-gutters>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">{{ counterPro }}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">PROFESSIONNELS</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">certifiés qui possèdent le savoir-faire pour que vos camions continuent de fonctionner à des performances optimales</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">{{counterProOne}}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">PROFESSIONNELS</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">certifiés qui possèdent le savoir-faire pour que vos camions continuent de fonctionner à des performances optimales</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">{{counterProTwo}}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">PROFESSIONNELS</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">certifiés qui possèdent le savoir-faire pour que vos camions continuent de fonctionner à des performances optimales</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </section>
    <!--after Sale Info-->
    <section id="afterSale">
      <v-row class="afterSaleBgV2">
        <v-col class="conainterafterSaleBgV2Info">
          <v-row>
            <v-col cols="12"> <h2 class="MontserratBI">notre département du service</h2></v-col>
            <v-col cols="12"><p>Profitez d'un service après-vente incomparable avec Réseau Dynamique. Vous accéderez à un réseau composé de 23 points de service répartis sur le territoire québécois maintenus par des professionnels reconnus pour la mécanique&nbsp;de&nbsp;camion&nbsp;lourd.</p></v-col>
            <v-col cols="12"><p>Que vous vous présentiez pour un entretien mécanique, un examen annuel ou une réparation, nos mécaniciens procéderont avec exactitude et rapidité afin&nbsp;de&nbsp;protéger&nbsp;votre&nbsp;investissement.</p></v-col>
          </v-row>
          <v-row>
            <v-col><button class="priBtn mt-5 flt-r" >CONTACTEZ-NOUS</button></v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </section>
    <!--International 360-->
    <section id="serviceInt360">
      <v-row class="intBg">
        <v-col class="conainterInt360Info">
          <v-row>
            <v-col><img class="logoIntMax" src="../../public/ressources/images/logo/International360NoPa.png"  title="logo international 360" alt="logo international 360" /></v-col>
          </v-row>
          <v-row style="">
            <v-col cols="12"><p>Révolutionner votre entreprise grâce à International 360 ! C’est l’outil révolutionnaire de communication dont vous avez besoin pour transformer la façon dont vous gérez la maintenance et les réparations&nbsp;de&nbsp;votre&nbsp;flotte.</p></v-col>
            <v-col cols="12"><p>Facile à utiliser, la plateforme place toutes vos données dans un seul endroit accessible pour maximiser votre temps. Vous obtiendrez des données complètes sur le niveau du VIN avec des vues à jour sur l’état de vos véhicules, et&nbsp;bien&nbsp;plus&nbsp;encore&nbsp;!</p></v-col>
          </v-row>
          <v-row>
            <v-col><a href="https://www.internationaltrucks.com/connected-vehicle/solutions/international-360" target="_blank"><button class="priBtn mt-5" >En savoir plus</button></a></v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </section>
    <!--On commande-->
    <section id="onCommand">
      <v-row class="onCommandBg">
        <v-col class="conainterOnCommandInfo">
          <v-row>
            <v-col><img class="logoOnCommand" src="../../public/ressources/images/logo/onCommand.png"  title="logo international 360" alt="logo international 360" /></v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><p>Profitez de la puissance avancée de cet outil de diagnostic à distance pour faciliter le traitement des données de votre flotte, et ce, peu importe les modèles de camions commerciaux&nbsp;que&nbsp;vous&nbsp;avez.</p></v-col>
            <v-col cols="12"><p>Offert aussi en application mobile OCCGO, vous obtiendrez à portée de main vos rapports complets sur l’état de vos véhicules, vos rapports de codes d’erreur, l’inventaire disponible chez le concessionnaire de pièces nécessaire&nbsp;et&nbsp;plus&nbsp;encore!</p></v-col>
          </v-row>
          <v-row>
            <v-col><button class="priBtn mt-5 flt-r" >Découvrez-en plus</button></v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </section>
    <!--Urgence routiere-->
    <section id="roadUrgency">
      <v-row class="roadUrgencyBG">
        <v-col class="conainterRoadUrgencyInfo">
          <v-row>
            <v-col><h2 class="MontserratBI">BESOIN D’UNE ASSISTANCE ROUTIÈRE ?</h2></v-col>
          </v-row>
          <v-row style="">
            <v-col cols="12"><p>Économisez temps et coûts de remorquage pour tout souci de mécanique mineur grâce au service routier du Réseau Dynamique. En confiant votre camion lourd aux professionnels hautement certifiés de notre réseau, vous serez en mesure de reprendre la route dans un délai rapide. Vous miserez également sur un service courtois, fiable et sécuritaire effectué par un technicien comprenant les contraintes et exigences&nbsp;de&nbsp;votre&nbsp;domaine&nbsp;d'activités.</p></v-col>
            <v-col cols="12"><p>Dans le cas où votre camion a besoin de soins plus pointus, celui-ci et sa cargaison seront remorqués jusqu'à la succursale Réseau Dynamique la plus près. L'éventail de véhicules de service est bien équipé pour rapatrier votre chargement de façon sécuritaire quelles que soient sa taille et sa nature. Afin de vous aider dans la poursuite de vos activités, vous pourrez profiter de notre service de location de camions en attendant&nbsp;la&nbsp;réparation&nbsp;de&nbsp;votre&nbsp;véhicule.</p></v-col>
          </v-row>
          <v-row>
            <v-col><button class="priBtn mt-5" >RECEVOIR UNE ASSISTANCE</button></v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </section>
    <!--promo-->
    <section id="promo">
      <v-row class="promoBG">
        <v-col class="conainterpromoInfo">
          <v-row>
            <v-col><h2 class="MontserratBI">Promotions</h2></v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><p>Profitez des meilleurs tarifs sur l’achat de vos pièces mécaniques en ligne grâce à notre promotion en cours. Ces rabais ne dureront pas longtemps. Faites vite ! </p></v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center"><button class="priBtn" >OBTENIR</button></v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </section>
    <!--all services-->
    <section id="allServices">
      <v-row class="allServicesBG">
        <v-col><h2>DÉCOUVREZ L’ENSEMBLE DE NOS SERVICES</h2></v-col>
      </v-row>
      <v-row>
          <v-col class="serviceSection bgparts">
            <router-link to="/"><h2 class="sectionTitle serviceSubtitle">PIÈCES</h2></router-link>
          </v-col>
          <v-col class="serviceSection bgSales" style="">
            <router-link to="/ventes"><h2 class="sectionTitle serviceSubtitle">Ventes</h2></router-link>
          </v-col>
          <v-col class="serviceSection bglocation" >
            <router-link to="/"><h2 class="sectionTitle serviceSubtitle">LOCATION</h2></router-link>
          </v-col>
        </v-row>
    </section>
  </div>
</template>
<style scoped>
    .infoTitle { transition: opacity 0.1s ease-in-out; opacity: 1; }
    .infoTitle.change { opacity: 0.5; }
    .bodyColor { background-color: rgb(var(--ligthGray)); }
    .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center; }
    .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/services/headerService.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    /*#region info Sale*/
      .afterSaleBg { min-height: 400px;  padding: 10px 0px; }
      .container { padding: 0px 5%; max-height: 450px; }
      .containerInfo { position: relative; padding: 50px; height: fit-content; max-width: 450px; margin: -25% 0px 0px 0px !important; background-color: rgb(var(--gray)); }
      .infoTitle { font-family: MontserratBI; color: rgb(var(--primary)); font-size: 78px; }
      .infoSubtitle { font-family: MontserratBI; text-transform: uppercase; color: white; font-size: 24px; min-width: 300px; text-align: center; padding: 20px 0px; }
      .infoText { font-family: CooperHewittR;  color: white; font-size: 24px; text-align: center;}
    /*#endregion*/
    /*#region After sale*/
      .afterSaleBgV2 { position: relative; height: 800px; width: 100%; background-image: url('../../public/ressources/images/services/firstSection.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterafterSaleBgV2Info { position: absolute; right: 10%; top:20%; background-color: black; padding: 25px !important; max-width: 33%; }
      #afterSale h2 { color: white; text-align: right; text-transform: uppercase; }
      #afterSale p { color: white;  padding: 15px 0px; text-align: right; font-family: CooperHewittR; }
    /*#endregion*/
    /*#region International 360*/
      .intBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/services/International360BG.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterInt360Info { position: absolute; left: 10%; top:10%; background-color: black; padding: 25px !important; max-width: 33%; }
      #serviceInt360 p { color: white;  padding: 15px 0px; font-family: CooperHewittR; }
      .logoIntMax { max-width: 200px; }
    /*#endregion*/
    /*#region On commande*/
      .onCommandBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/services/onCommandBG.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .logoOnCommand {  float: right; }
      .conainterOnCommandInfo { position: absolute; right: 10%; top:10%; background-color: black; padding: 25px !important; max-width: 33%; }
      #onCommand p { color: white;  padding: 15px 0px; text-align: right; font-family: CooperHewittR; }
    /*#endregion*/
    /*#region roadUrgency*/
      .roadUrgencyBG { margin-top: 40px !important; position: relative; height: 800px; width: 100%; background-image: url('../../public/ressources/images/services/roadUrgencyBG.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterRoadUrgencyInfo { position: absolute; left: 10%; top:10%; background-color: black; padding: 25px !important; max-width: 33%; }
      #roadUrgency h2 { color: white; }
      #roadUrgency p { color: white;  padding: 15px 0px; font-family: CooperHewittR; }
    /*#endregion*/
    /*#region promo*/
      .promoBG { margin-top: 40px !important; position: relative; height: 900px; width: 100%; background-image: url('../../public/ressources/images/services/promoBG.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat;  }
      .conainterpromoInfo { position: absolute; left: 50%; top:55%; transform: translate(-50%,-50%); padding: 25px !important; max-width: 33%; }
      .conainterpromoInfo h2 { text-align: center; color: white;  text-transform: uppercase; font-size: 36px; }
      .conainterpromoInfo p { text-align: center; color: white; padding: 30px 0px; font-size: 24px; font-family: CooperHewittR; }
      /*#endregion*/
    /*#region allServices*/
      .allServicesBG { background-color: rgb(var(--gray)); }
      .allServicesBG h2 { text-align: center; color: white; padding: 100px 0px; font-size: 36px; font-family: MontserratBI; }
      .bgSales { background-image: url('../../public/ressources/images/home/serviceSales.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgparts { background-image: url('../../public/ressources/images/home/PIECES.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgservice { background-image: url('../../public/ressources/images/home/SERVICE.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bglocation { background-image: url('../../public/ressources/images/home/LOCATION.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .serviceTitle { font-family: MontserratBI; padding: 10% 0px; text-align: center; margin: 10px 0px !important; text-transform: uppercase; color: white; }
      .serviceSubtitle { font-family: MontserratBI; padding: 30% 0px; text-align: center; margin: 10px 0px; text-transform: uppercase; color: white; }
      .serviceSection:hover  .serviceTitle {color: rgb(var(--primary)); cursor: pointer;}
      .serviceSection:hover  .serviceSubtitle {color: rgb(var(--primary)); cursor: pointer;}
    /*#endregion*/
    @media handheld, only screen and (max-width: 600px) {
      .afterSaleBg { padding: 0px; }
      .containerInfo { min-width: 100% !important; padding: 20px 0px; }
      .containerImg { max-width: 100% !important; min-width: 100%; }
      .frameImg { min-width: auto; height: auto; }
      .textAfterSale p { font-size: 12px; }
      .titleAfterSale { margin: 0px 0px !important;  }
      .contactBtn { padding: 0px 5%; }
      .conainterInt360Info  { max-width: 80% !important; }
      #serviceInt360 p { font-size: 12px !important; }
      .conainterOnCommandInfo { max-width: 80% !important; }
      #onCommand p { font-size: 12px !important; }
      .conainterRoadUrgencyInfo { max-width: 80% !important; }
      #roadUrgency h2 { font-size: 16px !important; }
      #roadUrgency p { font-size: 12px !important; }
      .conainterpromoInfo p { padding: 20px 0px; font-size: 12px !important; }
      .priBtn { font-size: 18px; }
      .conainterpromoInfo { position: absolute; left: 50%; top:40%; transform: translate(-50%,-50%); padding: 25px !important; max-width: 100%; }
      .promoBG { height: 400px;  }
      .bgparts { min-width: 100%; }
      .bgservice { min-width: 100%; }
      .bglocation { min-width: 100%; }
      .logoOnCommand {  float: right; max-width: 100%; }
    }
    @media handheld, only screen and (max-width: 960px) {
      .conainterInt360Info  { max-width: 50%; }
      #serviceInt360 p { font-size: 16px; }
      .conainterOnCommandInfo { max-width: 50%; }
      #onCommand p { font-size: 16px; }
      .conainterRoadUrgencyInfo { max-width: 50%; }
      #roadUrgency h2 { font-size: 24px; }
      #roadUrgency p { font-size: 16px; }
      .conainterpromoInfo p { padding: 20px 0px; font-size: 16px ; }

      .container { padding: 0px 25px; max-height: unset; }
      .containerInfo { width: 100%; min-width: 500px; max-width: 100%; margin: 20px 0px !important; }

      .titleAfterSale { font-size: 24px; margin: 40px 0px;  }
      .containerImg { max-width: unset; min-width: 100%; margin: auto; }
      .frameImg { position: relative; margin: 40px auto; top: 0px; left: 0px; }
      .containerInfoAfterSale { margin: 40px 0px !important; padding: 0px 25px; }
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'Service',
  data:()=>({
    counterPro: 0,
    counterProOne: 0,
    counterProTwo: 0,
    animationDurationCount: 1000,
  }),
  mounted() {
    this.animateProCounter();
    this.animateProOneCounter();
    this.animateProTwoCounter();

  },
  methods:{
    animateProCounter() {
      const maxCount = 600;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterPro = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    animateProOneCounter() {
      const maxCount = 600;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterProOne = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    animateProTwoCounter() {
      const maxCount = 600;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterProTwo = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
  },
  components: { }
}
</script>
