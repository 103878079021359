<template>
    <div class="footer">
        <v-row class="" style="height: 100%; position: relative;">
          <v-col class="d-flex justify-center">
            <a class="d-flex align-center" href="https://instagram.com/reseaudynamique?igshid=M2RkZGJiMzhjOQ== " target="_Blank"><img class="icon" src="./../../public/ressources/images/icon/Instagram.png" /></a>
            <a class="d-flex align-center" href="https://www.linkedin.com/company/93787072/admin/feed/posts/" target="_Blank"><img class="icon" src="./../../public/ressources/images/icon/linkedin.png" /></a>
            <a class="d-flex align-center" href="https://www.facebook.com/profile.php?id=100063532932438" target="_Blank"><img class="icon" src="./../../public/ressources/images/icon/Facebook.png" /></a>
            <a class="d-flex align-center" href="https://www.tiktok.com/@reseaudynamique" target="_Blank"><img class="icon" src="./../../public/ressources/images/icon/TikTok.png" /></a>
            <a class="d-flex align-center" href="mailto:requete-ccb@camionbeaudoin.com" target="_Blank"><img class="icon" src="./../../public/ressources/images/icon/CourrielFooter.png" /></a>
            <a class="d-flex align-center" href="https://www.threads.net/@lereseaudynamique?fbclid=IwAR2qKIcDERUCtZ_-CMAsRVHC2JElW_yI0Xm4QjgheBp-pe0vbw1blqQgRdI_aem_ATkFQ60I9t_xu0Q65i9oCBVXqve_I5WVlG54Mb3UsBK8uuXg_EK_gMirthumeQU6uaVzUf4TMdgfJzkn5PdQO_AM" target="_Blank"><img class="icon" src="./../../public/ressources/images/icon/Threads.png" /></a>

            
          </v-col>
          <v-col class="cookieLink">
              <a style="cursor: pointer;" @click="openCookie()"><p>Mes témoins</p></a>
              <router-link to="/mentionsLegales"><p>Politique de confidentialité</p></router-link>
            </v-col>
        </v-row>
    </div>
  </template>
  <style scoped>
    .footer {width: 100%; height: 100px; background-color: rgb(var(--secondary)); padding: 5px;}
    .footer h2 { text-align: center; color:rgb(var(--white));}
    .icon { width: 50px; height: auto; margin: 0px 5px; }
    .cookieLink { position: absolute; right: 0; top: 50%; transform: translate(0%,-50%); text-align: right; }
    p { font-family: CooperHewittR; display: inline; padding-right: 10px; font-size: 14px; color:rgb(var(--white)); }
    p:hover { text-decoration: underline; }
    @media handheld, only screen and (max-width: 600px) { 
      .cookieLink { position: relative; top: 0; margin-top: 20px; text-align: center; } 
    }
  </style>
  <script>
  
  export default {
    name: 'footer',
    data:()=>({
  
    }),
    mounted() {
  
    },
    methods:{
      navigateToPolicy: function(){ this.$router.push('/PolitiqueDeConfidentialiter'); },
      openCookie: function(){ $App.event.$emit('openCookie'); }
    },
    components: { }
  }
  </script>
  