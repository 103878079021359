<template>
    <div>
        <!--Video Section-->
        <div class="videoContainer">
            <video width="100%" controls autoplay muted>
                <source src="../../public/ressources/images/video/LandingPage.mp4" type="video/mp4">
                Votre navigateur ne prend pas en charge la lecture de la vidéo.
            </video>
        </div>
        <div style="margin-top: -6px;" class="orangeStripe"></div>
        <div id="SecondeBloc" class="BGBloc2">
                <div class="grid grid-pad">
                    <div class="contenu">
                        <v-row>
                            <v-col cols="12"><h2 class="truckTitle">Nos Camions</h2></v-col>
                            <v-col cols="12"><h2 class="emissionTitle">Zéro Émission</h2></v-col>
                        </v-row>
                        <v-row >
                            <v-col class="truck" cols="12" md="4">
                                <div class="blocImg">
                                    <img src="../../public/ressources/images/truckElectrik/truck1.png" />
                                </div>
                                <h2>Poids léger</h2>
                                <button><a href="https://isuzucv.com/en/nseries/nseries_ev" target="_blank">Isuzu<br>Série N</a></button>
                            </v-col>
                            <v-col class="truck" cols="12" md="4">
                                <div class="blocImg">
                                    <img src="../../public/ressources/images/truckElectrik/truck2.png" />
                                </div>
                                <h2>Poids moyen</h2>
                                <button><a href="https://www.internationaltrucks.com/zero-emissions" target="_blank">International<br>eMV</a></button>
                            </v-col>
                            <v-col class="truck" cols="12" md="4">
                                <div class="blocImg">
                                    <img src="../../public/ressources/images/truckElectrik/truck3.png" />
                                </div>
                                <h2>Poids lourd</h2>
                                <button><a href="https://battlemotors.com/pages/let-ii-ev-specs" target="_blank">Battle Motors<br>LNT & LET</a></button>
                            </v-col>
                        </v-row>
                    </div>
                </div>
        </div>
        <div class="orangeStripe"></div>
        <div id="ThirdBloc" class="BGBloc3">
                <div class="grid grid-pad">
                    <v-row class="contenu">
                        <v-row>
                            <v-col cols="12"><h2 class="txt-c Bloc3Title" style="">Planifiez une rencontre</h2></v-col>
                            <v-col cols="12"><p class="electrikText">Laissez notre équipe d'experts faire de votre transition énergétique une réussite en commerçant par</p></v-col>
                        </v-row>
                        <v-row class="iconBox">
                            <v-col cols="12" md="3" class="boxPad" > 
                                <div class="box">
                                    <div class="boximg">
                                        <img src="../../public/ressources/images/truckElectrik/icon1.png" />
                                    </div>
                                    <div class="textBox">
                                        <h3 class="title"><i>L'analyse de vos opérations</i></h3>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" class="boxPad" > 
                                <div class="box">
                                    <div class="boximg">
                                        <img src="../../public/ressources/images/truckElectrik/icon2.png" />                                      
                                    </div>
                                    <div class="textBox">
                                        <h3 class="title"><i>L'extraction de vos données télématiques</i></h3>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" class="boxPad"> 
                                <div class="box">
                                    <div class="boximg">
                                        <img src="../../public/ressources/images/truckElectrik/icon3.png" />                                       
                                    </div>
                                    <div class="textBox">
                                       <h3 class="title"><i>La planification de vos infrastructures de recharge</i></h3>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" class="boxPad"> 
                                <div class="box">
                                    <div class="boximg">
                                        <img src="../../public/ressources/images/truckElectrik/icon4.png" />  
                                    </div>
                                    <div class="textBox">
                                        <h3 class="title"><i>L'identification du bon véhicule pour votre application</i></h3>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-row>
                </div>
            <div class="blueBox"></div>
        </div>
        <form role="form" method="POST" action="/<?php echo $NOMPAGE; ?>">
            <v-row id="fourthBloc" class=" BGBloc4">
                <v-col cols="12"><p class="electrikText">Électrifiez-vous dès maintenant en nous laissant vos coordonnées</p></v-col> 
                <v-row class="grid boxForm form">
                    <v-col cols="12" md="6" class="inputForm"> 
                        <input name="prenom" id="prenom" type="text" placeholder="Nom complet" class=""  :value="form.prenom">  
                    </v-col>
                    <v-col cols="12" md="6"  class="inputForm"> 
                        <select name="fleetSize" id="fleetSize" type="text" placeholder="Taille de la flotte" class="">
                            <option :value="form.fleetSize = 'Taille de la flotte'">Taille de la flotte</option>
                            <option :value="form.fleetSize = '1 à 25'">1 à 25</option>
                            <option :value="form.fleetSize = '26 à 50'">26 à 50</option>
                            <option :value="form.fleetSize = '51 à 100'">51 à 100</option>
                            <option :value="form.fleetSize = '101 et plus'">101 et plus</option>
                        </select>:
                    </v-col>
                    <v-col cols="12" md="6" class="inputForm"> 
                        <input name="function" id="function" type="text" placeholder="Fonction" class="" :value="form.function"> 
                    </v-col>
                    <v-col cols="12" md="6" class="inputForm"> 
                        <select name="truckChoice" id="truckChoice" type="text" placeholder="Choisissez votre camion" class="">
                            <option :value="form.truckChoice = 'Choisissez votre camion'">Choisissez votre camion</option>
                            <option :value="form.truckChoice = 'Isuzu Série N'">Isuzu Série N</option>
                            <option :value="form.truckChoice = 'International eMV'">International eMV</option>
                            <option :value="form.truckChoice = 'Battle Motors LNT & LET'">Battle Motors LNT & LET</option>
                        </select>
                    </v-col>
                    <v-col cols="12" md="6" class="inputForm"> 
                        <input name="compagnie" id="compagnie" type="text" placeholder="Nom de l'entreprise" class="" :value="form.compagnie"> 
                    </v-col>
                    <v-col cols="12" md="6" class="inputForm"> 
                        <input name="telephone" id="telephone" type="text" placeholder="Numéro de téléphone" class="" :value="form.telephone"> 
                    </v-col>
                    <v-col cols="12" md="6" class="inputForm"> 
                        <input name="activityArea" id="activityArea" type="text" placeholder="Secteur d'activité" class="" :value="form.activityArea">
                    </v-col>
                    <v-col cols="12" md="6" class="inputForm"> 
                        <input name="email" id="email" type="text" placeholder="Courriel" class="" :value="form.email"> 
                    </v-col>
                </v-row>
                <button type="submit" name="btnSendMail" id="btnSendMail" value="Envoyer" style=""><a>Soumettre</a></button>
                <h2 v-if="sendForm == true" class="electrikText">Votre demande a bien été envoyée</h2>
            </v-row>
        </form>
        <div class="bandeOrange"></div>
    </div>
  </template>
  <style scoped>
    /*Region truck Transition*/
    .BGBloc2 { height: 100%; padding: 20px 0px; background-image: url('../../public/ressources/images/truckElectrik/Bloc2BG.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;}
    #SecondeBloc .truckTitle { font-family: MontserratBI; font-size: 36px; text-transform: uppercase; color: #fff; text-align: right; margin: 0px;}
    #SecondeBloc .emissionTitle { font-family: MontserratBI; color: rgb(var(--primary)); font-size: 64px; text-transform: uppercase; text-align: right; margin: -15px 0px 0px 0px; }
    .truck button { margin-top:40px; padding: 10px; margin-right: auto; margin-left: auto; display: block; min-width: 200px; background-color: rgb(var(--secondary)); font-weight: 100; }
    .truck button a { color: #FFF; }
    .truck button:hover {box-shadow: 0px 0px 10px rgb(var(--primary)); cursor: pointer;}
    #SecondeBloc .blocImg { height: auto; max-width: 100%; margin-left: auto; margin-right: auto; }
    .blocImg img { width: 100%; height: auto; }
    .truck h2 { margin-top:10px; color: #FFF; font-weight: 400; text-align: center; }
    /*#endregion */
    /*Region section 3*/
        .BGBloc3 {position:relative;  height: 100%; padding: 100px 0px 0px 0px; background-image: url('../../public/ressources/images/truckElectrik/bloc3BG.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;}
        #ThirdBloc .title {text-transform: uppercase;  margin-top: 20px; text-align: center; font-weight: 700; font-size: 18px; margin-left: auto; margin-right: auto; display: block;}
        #ThirdBloc .box {min-height: 300px; background-color: rgb(241,241,241); border-radius: 10%; padding:10px; }
        .boximg { height: auto; max-width: 50%; margin-left: auto; margin-right: auto;  }
        .boximg img { width: 100%; height: auto; }
        .textBox { height: auto; max-width: 75%; margin-left: auto; margin-right: auto;  }
        #ThirdBloc .boxPad {padding: 0px 25px;}
        #ThirdBloc .blueBox { height: 200px; background-color: rgb(var(--secondary)); position: relative; bottom: 0; z-index: 0; margin-top: -8%;  }
        #ThirdBloc .iconBox { margin-top:10px; position: relative; z-index: 50; }
        #ThirdBloc .Bloc3Title {color: #fff; font-size: 74px; margin-top: 0px; margin-bottom: 0px; font-weight: 900;  text-transform: uppercase;}

        .electrikText { text-align: center; color: #fff; padding: 20px 0px; font-size: 24px; font-weight: 100; line-height: normal;}

    /*#endregion */
    .BGBloc4 {position:relative;  height: 100%; padding: 10px 0px 10px 0px; background-image: url('../../public/ressources/images/truckElectrik/bloc3BG.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;}
    .boxForm { padding: 0px 350px; }
    .realization { padding: 25px 0px; }
    .form input { color: white; background-color: rgba(17,17,17,1); padding: 10px 20px; height: 50px; border: none; font-weight: 100; font-size: 24px;  width: 90%;}
    .form select { color: white; background-color: rgba(17,17,17,1); padding: 10px 20px; height: 50px; border: none; font-weight: 100; font-size: 24px;  width: 90%;}
    .inputForm { padding: 20px; }
    #ElectrikPage a {text-decoration: none; color:white;}
    #fourthBloc { padding: 100px 0px !important; }
    #fourthBloc button { margin-top:40px; margin-right: auto; margin-left: auto; font-size:24px; display: block; min-width: 200px; padding:20px; background-color: rgb(var(--secondary)); font-weight: 700; text-transform: uppercase; }
    #fourthBloc button a { color: #FFF; font-family: MontserratBI; }
    #fourthBloc button:hover {box-shadow: 0px 0px 10px rgb(var(--primary)); cursor: pointer;}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
        #ThirdBloc .box { margin: 20px 0px; }
        #ThirdBloc .boxPad {padding: 0px 10px;}
        .boxForm { padding: 0px 50px !important; }
        .form input { width: 100%; }
        .form select { width: 100%; }
        .boximg {max-width: 150px;}
    }
    @media handheld, only screen and (max-width: 1279px) {
        
    }
    @media handheld, only screen and (max-width: 1378px) {
        .boxForm { padding: 0px 100px; }
    }
  </style>
  <script>
  
  export default {
    name: 'TruckElectrik',
    data:()=>({
        sendForm: null, // send, error, pending
        form: {
            prenom: '',
            fleetSize: '',
            function: '',
            truckChoice: '',
            compagnie: '',
            telephone: '',
            activityArea: '',
            email: ''
        }
    }),
    mounted() {
  
    },
    methods:{
  
    },
    components: { }
  }
  </script>
  