<template>
  <div>
    <!--Banner-->
    <section class="">
        <div class="banner">
            <h1 class="PageTitle" style="">Promotions</h1>
        </div>
        <div class="orangeStripe"></div>
    </section>
    <!--Promo-->
    <section class="bgPromo ">
      <v-row class="grid-pad">
        <v-row  class="paPromo">
          <v-col cols="12"><h2 class="textPromo">Profitez des meilleurs tarifs sur l’achat de vos pièces mécaniques en ligne grâce à notre promotion en cours valide du 1ᵉʳ août au 30 septembre 2023 seulement sur www.repairlinkshop.com. Ces rabais ne dureront pas longtemps. Faites vite !</h2></v-col>
          <v-col cols="12"><h3 class="subtextPromo">Prix sujet à changement sans préavis. Quantité limitée. Valide jusqu’à épuisement des stocks. </h3></v-col>
        </v-row>
      </v-row>
      <v-row class="paSlider">
        <v-carousel id="sliderPromo" class="sliderHeight" show-arrows cycle hide-delimiters style="">
            <template v-slot:prev="{ props }"> <h2 class="storyArrow arrowPaging arrowPrev" @click="props.onClick"> &lt; </h2></template>
            <template  v-slot:next="{ props }"> <h2 class="storyArrow arrowPaging arrowNext" @click="props.onClick"> > </h2></template>
            <!--Shell Rotella T4-->
            <v-carousel-item >
              <v-row  class="d-flex  Container" style="" no-gutters> 
                <v-col >
                  <v-row class="itemText d-flex align-center justify-center" style="min-width: 350px;">
                    <v-col class="widthPrice" ><h2 class="price">3</h2></v-col>
                    <v-col class="widthSold" ><h2 class="sold">$<br>De rabais</h2></v-col>
                  </v-row>
                  <v-row class="itemText" >
                    <v-col>
                      <p style="font-size: 27px; padding-left: 20%; padding-right: 20%;">Applicable sur le Shell Rotella T4, 15W40, format de 18,9 L #SH550045128</p>
                    </v-col>
                  </v-row>
                  <v-row class="itemText " ><v-col class="d-flex justify-center"><button class="priBtn" style="font-size: 28px;">COMMANDEZ MAINTENANT</button></v-col></v-row>
                </v-col>
                <v-col class="d-flex justify-center  imgPromoContainer" style="">
                  <img class="promoImg" style="" src="../../public/ressources/images/promotions/shellRotellaT4.png" title="promotion sur Shell Rotella T4" alt="le produit présent est le Shell Rotella T4" >
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
      </v-row>
      <div class="orangeStripe"></div>
    </section>
    <section>
      <v-row no-gutters>
        <v-col class="heightNewsletter" style="">
          <div class="newsletterOverlay" style=""></div>
          <img src="../../public/ressources/images/promotions/promoNewsletter.jpg" style="height: 100%; width: 100%; object-fit: cover; object-position: center;" title="Promotion pièce réseau dynamique" alt="image d'un téléphone pour montrer une photo pour des promotions et logo de réseau dynamique" />
        </v-col>
        <v-col class="d-flex align-center justify-center bgPromoNewsletter" >
          <v-row class="paNewsletter">
            <v-row> <v-col><h2>ABONNEZ-VOUS !</h2></v-col> </v-row>
            <v-row> <v-col><p>Vous aimeriez recevoir des rabais exclusifs chaque semaine. Il suffit de vous inscrire à notre infolettre pour recevoir en premier nos promotions. Profitez-en dès maintenant ! </p></v-col> </v-row>
            <v-row><v-col><a href="https://app.cyberimpact.com/clients/47814/subscribe-forms/4703B382-EB45-468E-A900-F7EA2052DCD9" target="_blank"><button class="priBtn" style="padding: 0px 60px;">S'inscrire</button></a></v-col></v-row>
          </v-row>

        </v-col>
      </v-row>
    </section>
  </div>
</template>
<style scoped>
    .PageTitle { position: absolute; top: 40%; left: 50%; transform: translate(-50%,-50%); text-align: center; }
    .banner { position: relative; height: 500px; background-image: url('../../public/ressources/images/promotions/headerPromo.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .bgPromo { height: 100%; background-image: url('../../public/ressources/images/promotions/bgPromo.jpg'); background-position: bottom center; background-repeat: no-repeat; background-size: cover;  }
    .paPromo { padding: 150px 5%; }
    .textPromo { text-align: center; font-family: MontserratBI; font-size: 24px; color: white; text-transform: uppercase; }
    .subtextPromo { text-align: center; padding: 10px 0px; font-family: MontserratBI; font-size: 18px; color: white; text-transform: uppercase; }
    /*#region compagny*/
      .heightNewsletter { position: relative; max-height: 550px; min-width: 350px; }
      .newsletterOverlay { position: absolute; height: 100%; width: 100%; background-color: rgba(000,000,000, 0.5); }   
      .bgPromoNewsletter { background-color: black; color: white; min-width: 350px; }
      .bgPromoNewsletter h2 { font-family: MontserratBI; font-size: 36px; }
      .bgPromoNewsletter p { font-family: CooperHewittR; font-size: 27px; padding: 25px 0px; }
      .paNewsletter { padding: 0px 15%; }
    /*#endregion */
    /*#region slider*/
      .Container {padding: 0px 50px; line-height: normal; height: 100%;}
      .imgPromoContainer { height: 400px; max-width: 50%; min-width: 350px; position: relative;}
      .widthPrice { max-width: 80px; }
      .price { font-size: 113px !important; }
      .widthSold { max-width: 300px; }
      .sold { font-size: 46px !important; line-height: 1em; text-transform: uppercase; }
      .promoImg { position: absolute; height: 100%; min-width: 350px; width: auto; object-fit: contain; object-position: bottom; max-height: 450px; }
      .sliderHeight {height: fit-content !important;}
      .paSlider { padding-bottom: 100px; }
      .itemText h2 { font-family: MontserratBI; font-size: 36px; color: white;}
      .itemText p { font-family: CooperHewittR; font-size: 18px; padding: 25px 0px; color: white; text-align: center; }
      .storyArrow { display: block; }
      .arrowPaging { position: absolute; font-size: 76px; cursor: pointer; color: rgb(var(--primary)); z-index: 1000;}
      .arrowPaging:hover { color: white; text-shadow: 0px 0px 8px rgb(var(--primary)); }
      .arrowNext { padding-left: 40px; top:40%; right: 1%; }
      .arrowPrev { padding-right: 20px; top:40%; left: 1%; }
    /*#endregion */
      
    
    @media handheld, only screen and (max-width: 600px) {
      .sliderHeight {height: fit-content !important;}
      .arrowNext { top:50%; }
      .arrowPrev { top:50%; }
      .paPromo { padding: 50px 10px; }
      .textPromo { font-size: 18px; }
      .subtextPromo { font-size: 14px; }
      
    }
    @media handheld, only screen and (max-width: 960px) {
      .Container { padding: 0px 10px; height: unset; }
      .bgPromoNewsletter { padding: 10px !important;}
      .imgPromoContainer { max-width: unset; max-height: unset; }
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'Promo',
  data:()=>({

  }),
  mounted() {

  },
  methods:{

  },
  components: { }
}
</script>
