<template>
    <div>
      <section class="">
        <div class="bgTopNews">
          <h1 class="PageTitle" style="">ACTUALITÉS</h1>
        </div>
      </section>
      <div class="grayStripe"></div>
      <section v-if="data" class="bgContentNews">
        <v-row class="blocSearch">
          <v-col id="serchBar">
            <v-select  class="mon-select-personnalise" v-model="selectedSearch" :items="searchItems" item-value="type" variant="solo-filled" density="compact">{{ selectedSearch.title }}</v-select>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
        <v-row class="blocNews">
          <v-col class="bloc" v-for="(item, index) in paginatedNews" :key="index">
            <v-row><v-col cols="12"> <div style="height: 200px; width:100%; "><img style="display: block; margin-left: auto; margin-right: auto; height: 100%; width: inherit;" :src="item.img" /></div></v-col></v-row>
            <v-row><v-col cols="12"><div style="height: 225px; width:100%; overflow: hidden;"><p v-html="item.subtitle"></p></div></v-col></v-row>
            <v-row><v-col cols="12"><div><router-link class="" :to="item.path">Lire la suite</router-link></div></v-col></v-row>
          </v-col>
        </v-row>
        <v-row>

          <v-col v-if="totalPages > 1" class="txt-c" style="color: white; font-family: MontserratBI;">
            <div id="newsPaging">
              <h2 class="arrowPaging arrowPrev" :disabled="page >= totalPages" @click="prevPage">&lt;</h2>
              <v-pagination style="display: inline-block; margin-top: -20px; transform: translate(0%,-35%); font-size: 24px !important;" v-model="page" :length="totalPages"></v-pagination>
              <h2 class="arrowPaging arrowNext"  :disabled="page >= totalPages" @click="nextPage">&gt;</h2>
            </div>
            
          </v-col >
        </v-row>
      </section>
    </div>
  </template>
  <style>
    #newsPaging .v-pagination__prev { display: none ; }
    #newsPaging .v-pagination__next { display: none; }
    #newsPaging .v-pagination__more { display: none ; }
    #newsPaging .v-pagination__item--is-active { color: rgb(var(--primary));}
    #newsPaging .v-pagination__item--is-active .v-btn__overlay { opacity: 0 !important;}
    #newsPaging .v-pagination__item:hover { color: rgb(var(--primary));  }
    #newsPaging .v-pagination__item:hover .v-btn__overlay { opacity: 0 !important; }
    #newsPaging .v-btn__content { font-size: 24px;}
    .v-field  {background-color: white;}
    #serchBar .v-select { font-family: MontserratBI !important; text-transform: uppercase; }
  </style>
  <style scoped>
    #serchBar .mon-select-personnalise .v-select .v-select__selection, #serchBar .mon-select-personnalise .v-menu__content { font-family: MontserratBI !important; }
    .PageTitle { position: absolute; top: 50%; left: 35%; transform: translate(-50%,-50%); }
    .blocSearch { padding: 50px 15% 0px 15%; }
    .blocNews { padding: 0px 15% 50px 15%; }
    .bgTopNews { position: relative; height: 400px; background-image: url('../../public/ressources/images/news/actualitésBanner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .bgContentNews { background-image: url('../../public/ressources/images/news/BgActualty.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .bloc { display: block; margin-left: auto; margin-right: auto; height: auto; margin-top: 50px; min-width: 350px; max-width: 350px; margin-bottom: 50px; background-color: black; padding-bottom: 40px !important; color: white ; }
    .bloc img { padding: 10px;}
    .bloc p { padding: 20px 10px; font-family: CooperHewittR; }
    .bloc a { color: white; padding: 20px 10px; font-family: CooperHewittR; font-weight: 700; }
    .arrowPaging { display: inline-block;  font-size: 76px; cursor: pointer; color: rgb(var(--primary));}
    .arrowPaging:hover { color: white; text-shadow: 0px 0px 8px rgb(var(--primary)); }
    .arrowNext { padding-left: 40px; }
    .arrowPrev { padding-right: 20px; }
    .btnNext { display: inline-block; cursor: pointer; transform: translate(0%,-50%); }
    .btnNext:hover { color: rgb(var(--primary)); text-shadow: 0px 0px 2px black; }
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {
      .blocNews { padding: 50px 10px !important; }
    }
    @media handheld, only screen and (max-width: 1279px) {
      .blocNews { padding: 50px 5%; }
    }
    @media handheld, only screen and (max-width: 1378px) {}
  </style>
  <script>
  
  export default {
    name: 'News',
    data:()=>({
      selectedSearch: {type: 'all', title: "Toutes les nouvelles" },
      searchItems: [
        {type: 'all', title: "Toutes les nouvelles" },
        {type: 'news', title: "Nouvelles" },
        {type: 'event', title: "Événements" },
        {type: 'blog', title: "Blogue" }
      ],
      page: 1,
      pageSize: 9,
      news: null,
      data: null,
    }),
    async mounted() {
      await this.dataRead();
      this.SortNews();
    },
    watch:{
      async selectedSearch() {
        await this.filteredNews();
        
      }
    },
    computed: {
      totalPages() {
        let pages;
        if(this.news) { pages = Math.ceil(this.news.length / this.pageSize); }
        return pages;
      },
      paginatedNews() {
        let pageContent;
        if(this.news ){
          const start = (this.page - 1) * this.pageSize;
          const end = start + this.pageSize;
          pageContent = this.news.slice(start, end);
        }
        return pageContent;
      }

    },
    methods:{
      prevPage() {
        if (this.page > 1) this.page--;
      },
      nextPage() {
        if (this.page < this.totalPages) this.page++;
      },
      dataRead: async function () {
        try {
            const response = await fetch('/ressources/json/news.json');
            if (!response.ok) { throw new Error('Failed to fetch'); }
            this.data = await response.json();
            if(this.data) { this.news = this.data; }
        } catch (error) { console.error('Error loading JSON data:', error); }
      },
      filteredNews() {

        if(this.data){
          if (!this.selectedSearch ) { this.news = this.data; } // retourne toutes les nouvelles si aucun type n'est sélectionné
          else if(this.selectedSearch == "all") { this.news = this.data; }
          else {
            this.news = this.data.filter(item => item.type === this.selectedSearch);
          }
          this.SortNews();
        }
      },
      SortNews(){ this.news.sort(function(a,b){ return new Date(a.Date) - new Date(b.Date);}); }
    },
    components: { }
  }
  </script>
  