<template>
  <div >
    <div class="newsBg">
      <section>
        <v-row>
          <v-col><h1 class="titleNews">LES BIENFAITS DE L’INSPECTION PRÉ-HIVERNALE SUR VOTRE CAMION LOURD</h1></v-col>
        </v-row>
      </section>
      <section class="iconBg">
        <v-row>
          <v-col>
            <v-row class="d-flex align-center justify-center">
              <v-col class="iconWidth"><img src="/ressources/images/icon/dateIcon.png" truesrc="/ressources/images/icon/dateIcon.png"  alt="date icon"/></v-col>
              <v-col class="textWidth" ><p>13 novembre 2023</p></v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="d-flex align-center justify-center">
              <v-col class="iconWidth"><img src="/ressources/images/icon/typeIcon.png" truesrc="/ressources/images/icon/typeIcon.png" alt="type icon"/></v-col>
              <v-col class="textWidth"><p>Blogue</p></v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row @click="back" class="d-flex align-center justify-center iconZone">
              <v-col class="iconWidth"><img src="/ressources/images/icon/backIcon.png" truesrc="/ressources/images/icon/backIcon.png" alt="back icon"/></v-col>
              <v-col class="textWidthBack"><p>Retour aux actualités</p></v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <section class="articleSection">
        <v-row>
          <v-col>
            <p>Il n’y a presque plus de feuilles dans les arbres et vous avez besoin de mettre votre manteau pour sortir à l’extérieur… C’est qu’il est maintenant temps de planifier un rendez-vous avant la fin de l’automne pour l’inspection de votre véhicule lourd !</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Garantir votre sécurité routière avant que le temps glacial s’installe contribue à restreindre les mauvaises surprises en hiver, tout en maximisant votre portefeuille. </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Dans cet article, nous dévoilons les avantages de mettre l’accent sur la sécurité routière avec une inspection pré-hivernale de votre véhicule lourd.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <img style="margin: 100px auto;" src="../../../public/ressources/images/news/preWinterImg.jpg" />
          </v-col>
        </v-row>
        <v-row>
          <v-col><h2>Pourquoi faire une inspection pré-hivernale ?</h2></v-col>
        </v-row>
        <v-row>
          <v-col><p>Que ce soit la neige, la glace, le froid, l’humidité ou encore le sel, votre véhicule affronte toutes sortes d’intempéries durant l’hiver. C’est bien connu, nos saisons hivernales au Québec mettent à rude épreuve nos batteries, freins, pneus, systèmes de chauffage et bien plus encore ! En effet, le froid réduit considérablement la durée de vie de certaines pièces d’un véhicule et peut nuire à l’exécution de nos manœuvres de conduite. </p></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>Grâce à une inspection minutieuse de votre camion avant l’hiver par un expert, vous vous éviterez bien des pannes et des réparations souvent onéreuses par la détection d’anomalies mineures. Sans oublier que cette prévention permet de vous informer sur l’usure de certaines de vos pièces et l’approche de la fin de leur garantie. Ainsi, cet entretien pourrait vous faire économiser plus de 6 000 $ par année selon nos estimations !</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col><h2>En quoi consiste une inspection pré-hivernale ?</h2></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>Afin de s’assurer que votre véhicule lourd est en bon état pour affronter l’hiver, nos équipes spécialistes vérifieront l’ensemble de ses composantes pour assurer votre sécurité : </p>
          </v-col>
          <v-col >
            <ul>
              <li>Le fonctionnement du système HVAC (filtres)</li>
              <li>Le fonctionnement du chauffage à air (Wabasto/Espar)</li>
              <li>Purger le réservoir d’air</li>
              <li>Le fonctionnement de l’élément chauffant (air dryer)</li>
              <li>L’état des pneus</li>
              <li>L’état des essuie-glaces (remplacer les lames au besoin) et du lave-vitre</li>
              <li>Le système de charge, de démarrage et de la batterie (Midtronic)</li>
              <li>Le fonctionnement du chauffe-moteur (block heater)</li>
              <li>Les miroirs chauffants</li>
              <li>L’élément chauffant (Water separator) et le purger au besoin</li>
            </ul>
          </v-col>
        </v-row>
        <v-row>
          <v-col><h2>Conclusion</h2></v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><p>Gain en termes d’argent, de temps et en sécurité routière : voilà l’ensemble des bienfaits que vous obtiendrez en optant pour l’inspection pré-hivernale de votre véhicule lourd. Vous l’aurez compris, il ne suffit pas de mettre vos pneus d’hiver pour garantir votre sécurité sur les routes glacées et enneigées : vous devez veiller à offrir une inspection complète de votre véhicule avant la fin de l’automne.</p></v-col>
          <v-col cols="12"><p>Passez maintenant à l’action en obtenant un rendez-vous dans l’un de nos concessionnaires !</p></v-col>
          <v-col cols="12"><router-link to="/nous-joindre"><button class="secBtn">OBTENIR UN RENDEZ-VOUS</button></router-link></v-col>
        </v-row>       
      </section>
    </div>
    <section>
      <diapoNews />
    </section>
  </div>
</template>
<style scoped>
  p { font-size: 24px; font-family: CooperHewittR; }
  h2 { font-family: CooperHewittB; }
  .newsBg { background-color: black; padding: 100px 250px; color: #FFF; }
  .titleNews { text-align: center; font-family: MontserratBI; text-transform: uppercase; }
  .iconWidth { max-width: 80px; }
  .textWidth { max-width: 200px;}
  .textWidthBack { max-width: 300px; }
  .iconBg { margin-top: 100px; }
  .iconBg p { font-family: CooperHewittR; }
  .iconZone:hover { cursor: pointer; text-decoration: underline; }
  .articleSection { font-family: CooperHewittR; margin-top: 100px; }
  .articleSection p { padding: 20px 0px;  }
  
  ul { margin: 50px 0px 50px 30px; font-size: 24px;}
  .video { display: block; width: 80%; height: 600px; margin: 50px auto; }
  .button { background-color: rgb(var(--primary)); padding: 0px 40px; }
  .button:hover { box-shadow: 0px 0px 10px rgb(var(--primary)); color: black; }



  @media handheld, only screen and (max-width: 600px) {}
  @media handheld, only screen and (max-width: 960px) {}
  @media handheld, only screen and (max-width: 1279px) {}
  @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
  import diapoNews from '../../components/diapo-news.vue';
export default {
  name: 'preWinter',
  data:()=>({

  }),
  mounted() {

  },
  methods:{
    back: function (){
      this.$router.push('/actualites')
    }
  },
  components: { diapoNews }
}
</script>
