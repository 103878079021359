<template>
  <div class="bodyColor">
    <!--Banner-->
    <section class="">
        <div class="banner">
            <h1 class="PageTitle" style="">Pièces</h1>
        </div>
    </section>
    <!--after Sale Info-->
    <section>
      <v-row class="afterSaleBg">
        <v-row class="container" no-gutters>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">{{ counterPro }}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">PROFESSIONNELS</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">certifiés qui possèdent le savoir-faire pour que vos camions continuent de fonctionner à des performances optimales</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">{{ counterProOne }}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">PROFESSIONNELS</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">certifiés qui possèdent le savoir-faire pour que vos camions continuent de fonctionner à des performances optimales</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">{{ counterProTwo }}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">PROFESSIONNELS</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">certifiés qui possèdent le savoir-faire pour que vos camions continuent de fonctionner à des performances optimales</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </section>
    <!--NextPart-->
    <section id="nextPart">
      <v-row class="nextBg">
        <v-col class="conainterNextPartInfo">
          <v-row style="">
            <v-col cols="12"><p>La plateforme Nexpart est désormais disponible pour que vous puissiez prendre des décisions d’achat plus rapides en&nbsp;un&nbsp;seul&nbsp;clic.</p></v-col>
            <v-col cols="12"><p>Cette plateforme en ligne vous offre un catalogue centralisé contenant une large gamme de pièces provenant de plusieurs vendeurs. Ces fonctionnalités avancées vous permettent de trouver rapidement ce dont vous avez de besoin et facilite vos achats à&nbsp;un&nbsp;seul&nbsp;endroit.</p></v-col>
          </v-row>
          <v-row>
            <v-col><a href="https://www.nexpart.com/login.php" target="_Blank"><button class="priBtn mt-5 flt-r" >CONNECTEZ-VOUS</button></a></v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--Departement piece-->
    <section id="dptParts">
      <v-row class="dptPartsBg">
        <v-col class="conainterdptPartsInfo">
          <v-row style="">
            <v-col cols="12"> <h2>notre département des pièces</h2></v-col>
            <v-col cols="12"> <h3>VOUS AIDERA À TROUVEZ CE DONT VOUS AVEZ DE BESOIN</h3></v-col>
            <v-col cols="12"><p>Que ce soit pour un entretien, une réparation, des pièces de carrosserie et des accessoires, nos départements des pièces ont l'expertise et les connaissances pour répondre à tous vos besoins, peu importe la marque ou le modèle&nbsp;de&nbsp;votre&nbsp;camion.</p></v-col>
            <v-col cols="12"><p>Nous vous offrons seulement des pièces de qualité conçues par les plus grandes marques en Amérique du Nord. Les concessionnaires du Réseau Dynamique disposent d'un large inventaire de pièces d'origine et d’après-marché dédié à vous faire reprendre la route le&nbsp;plus&nbsp;rapidement&nbsp;possible.</p></v-col>
          </v-row>
          <v-row>
            <v-col><router-link to="/nous-joindre"><button class="priBtn mt-5" >CONTACTEZ-NOUS</button></router-link></v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--fleetrite-->
    <section id="fleetrite">
      <v-row class="fleetriteBg">
        <v-col class="conainterfleetriteInfo">
          <v-row style="">
            <v-col cols="12"> <h2>LES pièces fleetrite</h2></v-col>
            <v-col cols="12"> <h3>DES PIÈCES DE HAUTE QUALITÉ, SANS LE PRIX ÉLEVÉ</h3></v-col>
            <v-col cols="12"><p>Savez-vous que vous pouvez bénéficier de pièces Fleetrite de haute qualité à un prix compétitif à travers l’ensemble de&nbsp;nos&nbsp;concessionnaires&nbsp;?</p></v-col>
            <v-col cols="12"><p>La large gamme de produits de la marque Fleetrite vous fournit des pièces fiables et des solutions innovantes pour votre flotte de transports : freins à disque pneumatiques, filtres, articles de sécurité, contrôle du fret, pièces de remorque, et bien plus encore. Expert dans l’industrie du camionnage depuis plus de 50 ans, Fleetrite vous offre plus de 15 000 pièces avec une garantie nationale d’un an sur les pièces et main-d'œuvre lorsqu’elles sont installées dans un emplacement autorisé&nbsp;comme&nbsp;les&nbsp;nôtres</p></v-col>
          </v-row>
          <v-row>
            <v-col><a href="https://www.fleetrite.com/" target="_Blank"><button class="priBtn mt-5 flt-r" >APPRENEZ-EN PLUS</button></a></v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--RepairLink-->
    <section id="RepairLink">
      <v-row class="RepairLinkBg">
        <v-col class="conainterRepairLinkInfo">
          <v-row style="">
            <v-col cols="12"> <h2>BIENVENUE SUR REPAIRLINK</h2></v-col>
            <v-col cols="12"> <h3>LA PLUS COMPRÉHENSIVE SOLUTION E-COMMERCE</h3></v-col>
            <v-col cols="12"><p>Besoin de commander des pièces mécaniques facilement à toute heure de&nbsp;la&nbsp;journée&nbsp;?</p></v-col>
            <v-col cols="12"><p>Faite une soumission à la boutique en ligne Repairlink afin d’obtenir rapidement ce dont vous avez besoin. Votre commande sera par la suite envoyée directement au concessionnaire de votre choix. Il vous suffit de vous inscrire gratuitement sur la plateforme pour avoir accès à un catalogue complet de pièces avec des schémas illustrés. Sans compter que Repairlink vous offre des promotions pour vous&nbsp;faire&nbsp;économiser&nbsp;gros.</p></v-col>
          </v-row>
          <v-row>
            <v-col><a href="https://www.repairlinkshop.com/Account/Login" target="_blank"><button class="priBtn mt-5" >COMMANDEZ OU S’INSCRIRE </button></a></v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--promo-->
    <section id="promo">
      <v-row class="promoBG">
        <v-col class="conainterpromoInfo">
          <v-row>
            <v-col><h2 class="MontserratBI">Promotions</h2></v-col>
          </v-row>
          <v-row >
            <v-col cols="12"><p >Profitez des meilleurs tarifs sur l’achat de vos pièces mécaniques en ligne grâce à notre promotion en cours. Ces rabais ne dureront pas longtemps. Faites vite ! </p></v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center"><button class="priBtn" >OBTENIR</button></v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </section>
    <!--all services-->
    <section id="allServices">
      <v-row class="allServicesBG">
        <v-col><h2>DÉCOUVREZ L’ENSEMBLE DE NOS SERVICES</h2></v-col>
      </v-row>
      <v-row>
          <v-col class="serviceSection bgservice">
            <router-link to="/services"><h2 class="sectionTitle serviceSubtitle">Service</h2></router-link>
          </v-col>
          <v-col class="serviceSection bgSales" style="">
            <router-link to="/ventes"><h2 class="sectionTitle serviceSubtitle">Ventes</h2></router-link>
          </v-col>
          <v-col class="serviceSection bglocation" >
            <router-link to="/"><h2 class="sectionTitle serviceSubtitle">LOCATION</h2></router-link>
          </v-col>
        </v-row>
    </section>
  </div>
</template>
<style scoped>
    .bodyColor { background-color: rgb(var(--ligthGray)); }
    .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center; }
    .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/services/headerService.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    /*#region info Sale*/
      .afterSaleBg { min-height: 400px;  padding: 10px 0px; }
      .container { padding: 0px 5%; max-height: 450px; }
      .containerInfo { position: relative; padding: 50px; height: fit-content; max-width: 450px; margin: -20% 0px 0px 0px !important; background-color: rgb(var(--gray)); }
      .infoTitle { font-family: MontserratBI; color: rgb(var(--primary)); font-size: 78px; }
      .infoSubtitle { font-family: MontserratBI; text-transform: uppercase; color: white; font-size: 24px; min-width: 300px; text-align: center; padding: 20px 0px; }
      .infoText { font-family: CooperHewittR;  color: white; font-size: 24px; text-align: center;}
      .titleAfterSale { font-family: MontserratBI; text-transform: uppercase; text-align: center; font-size: 38px; min-width: 100%; margin: 100px 0px;  }
      .textAfterSale p { font-family: CooperHewittR; padding: 20px 0px; color: white; }
      .bgTextAfterSale { background-color: black; padding: 5% 10% !important; }
      .containerInfoAfterSale { margin: 100px 0px 50px 0px !important; padding: 0px 15%; }
      .containerImg { max-width: 350px; position: relative; }
      .frameImg { position: absolute; height: 350px; min-width: 500px; top:-50px; left: -50px; overflow: hidden; }
      .frameImg img { height: 100%; width: 100%; object-fit: cover; object-position: center center; }
      .contactBtn { padding: 0px 20%; }
    /*#endregion*/
    /*#region NextPart*/
      .nextBg { position: relative; height: 800px; width: 100%; background-image: url('../../public/ressources/images/parts/NEXPART.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .conainterNextPartInfo { position: absolute; right: 10%; top:50%; transform: translate(0%,-50%); background-color: black; padding: 25px !important; max-width: 33%; }
      #nextPart p { color: white;  padding: 15px 0px; font-family: CooperHewittR; text-align: right; }
    /*#endregion*/
    /*#region Departement piece*/
      .dptPartsBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/parts/DptPieces.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterdptPartsInfo { position: absolute; left: 5%; top:40%; transform: translate(0%,-50%); background-color: black; padding: 25px !important; max-width: 33%; }
      #dptParts H2 {color: white; font-family: MontserratBI; text-transform: uppercase; text-align: justify; font-size: 1.75em; }
      #dptParts H3 {color: rgb(var(--primary)); font-family: MontserratBI; text-transform: uppercase; text-align: justify; font-size: 1em; }
      #dptParts p { color: white;  padding: 15px 0px; font-family: CooperHewittR;}
    /*#endregion*/
    /*#region fleetrite*/
      .fleetriteBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/parts/fleetrite.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterfleetriteInfo { position: absolute; right: 10%; top:50%; transform: translate(0%,-50%); background-color: black; padding: 25px !important; max-width: 33%; }
      #fleetrite H2 {color: white; font-family: MontserratBI; text-transform: uppercase; text-align: right; font-size: 1.75em; }
      #fleetrite H3 {color: rgb(41,126,135); font-family: MontserratBI; text-transform: uppercase; text-align: right; font-size: 1em; }
      #fleetrite p { color: white;  padding: 15px 0px; font-family: CooperHewittR; text-align: right; }
      #fleetrite button { background-color: rgb(41,126,135); }
    /*#endregion*/
    /*#region RepairLink*/
      .RepairLinkBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/parts/REPAIRLINK.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterRepairLinkInfo { position: absolute; left: 5%; top:40%; transform: translate(0%,-50%); background-color: black; padding: 25px !important; max-width: 33%; }
      #RepairLink H2 {color: white; font-family: MontserratBI; text-transform: uppercase; text-align: justify; font-size: 1.75em; }
      #RepairLink H3 {color: rgb(var(--primary)); font-family: MontserratBI; text-transform: uppercase; text-align: justify; font-size: 1em; }
      #RepairLink p { color: white;  padding: 15px 0px; font-family: CooperHewittR;}
    /*#endregion*/
    /*#region promo*/
      .promoBG { margin-top: 40px !important; position: relative; height: 900px; width: 100%; background-image: url('../../public/ressources/images/services/promoBG.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat;  }
      .conainterpromoInfo { position: absolute; left: 50%; top:55%; transform: translate(-50%,-50%); padding: 25px !important; max-width: 33%; }
      .conainterpromoInfo h2 { text-align: center; color: white;  text-transform: uppercase; font-size: 36px; }
      .conainterpromoInfo p { text-align: center; color: white; padding: 30px 0px; font-size: 24px; font-family: CooperHewittR; }
    /*#endregion*/
    /*#region allServices*/
      .allServicesBG { background-color: rgb(var(--gray)); }
      .allServicesBG h2 { text-align: center; color: white; padding: 100px 0px; font-size: 36px; font-family: MontserratBI; }
      .bgSales { background-image: url('../../public/ressources/images/home/serviceSales.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgparts { background-image: url('../../public/ressources/images/home/PIECES.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgservice { background-image: url('../../public/ressources/images/home/SERVICE.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bglocation { background-image: url('../../public/ressources/images/home/LOCATION.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .serviceTitle { font-family: MontserratBI; padding: 10% 0px; text-align: center; margin: 10px 0px !important; text-transform: uppercase; color: white; }
      .serviceSubtitle { font-family: MontserratBI; padding: 30% 0px; text-align: center; margin: 10px 0px; text-transform: uppercase; color: white; }
      .serviceSection:hover  .serviceTitle {color: rgb(var(--primary)); cursor: pointer;}
      .serviceSection:hover  .serviceSubtitle {color: rgb(var(--primary)); cursor: pointer;}
    /*#endregion*/
    @media handheld, only screen and (max-width: 600px) {
      .afterSaleBg { padding: 0px; }
      .containerInfo { min-width: 100% !important; padding: 20px 0px; }
      .containerImg { max-width: 100% !important; min-width: 100%; }
      .frameImg { min-width: auto; height: auto; }
      .textAfterSale p { font-size: 12px; }
      .titleAfterSale { margin: 0px 0px !important;  }
      .contactBtn { padding: 0px 5%; }
      .conainterNextPartInfo  { max-width: 80% !important; }
      #nextPart p { font-size: 12px !important; }
      .conainterpromoInfo p { padding: 20px 0px; font-size: 12px !important; }
      .priBtn { font-size: 18px; }
      .conainterpromoInfo { position: absolute; left: 50%; top:40%; transform: translate(-50%,-50%); padding: 25px !important; max-width: 100%; }
      .promoBG { height: 400px;  }
      .bgparts { min-width: 100%; }
      .bgservice { min-width: 100%; }
      .bglocation { min-width: 100%; }
      .logoOnCommand {  float: right; max-width: 100%; }
    }
    @media handheld, only screen and (max-width: 960px) {
      .conainterNextPartInfo  { max-width: 50%; }
      #nextPart p { font-size: 16px; }
      .conainterpromoInfo p { padding: 20px 0px; font-size: 16px ; }

      .container { padding: 0px 25px; max-height: unset; }
      .containerInfo { width: 100%; min-width: 500px; max-width: 100%; margin: 20px 0px !important; }

      .titleAfterSale { font-size: 24px; margin: 40px 0px;  }
      .containerImg { max-width: unset; min-width: 100%; margin: auto; }
      .frameImg { position: relative; margin: 40px auto; top: 0px; left: 0px; }
      .containerInfoAfterSale { margin: 40px 0px !important; padding: 0px 25px; }
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'Parts',
  data:()=>({
    counterPro: 0,
    counterProOne: 0,
    counterProTwo: 0,
    animationDurationCount: 1000,
  }),
  mounted() {
    this.animateProCounter();
    this.animateProOneCounter();
    this.animateProTwoCounter();

  },
  methods:{
    animateProCounter() {
      const maxCount = 600;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterPro = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    animateProOneCounter() {
      const maxCount = 600;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterProOne = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    animateProTwoCounter() {
      const maxCount = 600;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterProTwo = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
  },
  components: { }
}
</script>
