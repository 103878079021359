<template>
  <div>
    <!--Banner-->
    <section class="">
        <div class="banner">
            <h1 class="PageTitle" style="">RÉALISE TON PLEIN POTENTIEL</h1>
        </div>
        <div class="orangeStripe"></div>
    </section>
    <!--Futur-->
    <section id="futur">
      <v-row>
          <v-col cols="12" md="6" style="padding: 0px;">
              <div class="futurBG">
                  <div class="futurAlignText">
                      <v-row><v-col><h2 class="sectionTitle futurTitle">DEVIENS NOTRE FUTUR(E) STAGIAIRE</h2></v-col></v-row>
                      <v-row><v-col><p class="futurText">Tu étudies en mécanique de véhicules lourds routiers ou d’engins de chantier, viens essayer de nouveaux défis passionnants et apprendre de nos experts chevronnés dans l’un de nos concessionnaires à travers la province. Tu auras la possibilité de travailler sur des camions, autobus, remorques, drive-shafts, moteurs, transmissions, différentiels, de la maintenance, de l’électricité, et bien plus encore. Deviens l’expert que tu souhaites devenir&nbsp;avec&nbsp;Réseau&nbsp;Dynamique!</p></v-col></v-row>
                  </div>
              </div>
          </v-col>
          <v-col cols="12" md="6" style="padding: 0px;">
              <v-row>
                  <v-col class="containerImg">
                      <img src="/ressources/images/career/student/firstBloc.jpg" title="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE" alt="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE"/>
                  </v-col>
              </v-row>
          </v-col>
      </v-row>
    </section>
    <!--Advantage-->
    <section id="advantageStage">
      <v-row class="advantageStageBG">
        <v-row>
          <v-col cols="12"><h2 class="sectionTitle">TES AVANTAGES</h2></v-col>
          <v-col cols="12"><p class="pl-5">comme stagiaire</p></v-col>
        </v-row>
        <v-row  class="mt-Section">
          <v-col>
            <img class="advantageStageCircleImg" src="../../public/ressources/images/career/student/stage.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageStageCircleText">Stage offert de jour ou de soir selon ta préférence</p>
          </v-col>
          <v-col>
            <img class="advantageStageCircleImg" src="../../public/ressources/images/career/student/allSphere.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageStageCircleText">Possibilité de toucher à différentes sphères de la mécanique</p>
          </v-col>
          <v-col>
            <img class="advantageStageCircleImg" src="../../public/ressources/images/career/student/offreEmploi.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageStageCircleText">Transformer ton expérience de stage en offre d’emploi</p>
          </v-col>
          <v-col>
            <img class="advantageStageCircleImg" src="../../public/ressources/images/career/student/aspirations.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageStageCircleText">Accomplir ta passion selon tes propres aspirations </p>
          </v-col>
        </v-row>
      </v-row>
      <div class="orangeStripe"></div>
    </section>
    <!--needJob-->
    <section id="needJob">
      <v-row>
          <v-col cols="12" md="6" style="padding: 0px;">
              <v-row>
                  <v-col class="containerImg">
                      <img src="../../public/ressources/images/career/student/need.jpg" title="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE" alt="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE"/>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="6" style="padding: 0px;">
              <div class="needJobBG">
                  <div class="needJobAlignText">
                      <v-row><v-col><h2 class="sectionTitle needJobTitle">BESOIN D’UN EMPLOI ÉTUDIANT ?</h2></v-col></v-row>
                      <v-row><v-col><p class="needJobText">Pendant tes études, viens travailler avec nous à temps partiel du lundi au jeudi soir après ton école ou encore à temps plein durant l’été. Notre contremaître et nos techniciens sont là pour te transmettre leurs savoirs, leurs expertises et être à l’écoute de tes besoins&nbsp;en&nbsp;termes&nbsp;d’apprentissage.</p></v-col></v-row>
                  </div>
              </div>
          </v-col>
      </v-row>
    </section>
    <!--Advantage-->
    <section id="advantageJob">
      <v-row class="advantageJobBG">
        <v-row>
          <v-col cols="12"><h2 class="sectionTitle txt-r">TES AVANTAGES</h2></v-col>
          <v-col cols="12"><p class="pr-5 txt-r">comme employé(e) étudiant(e)</p></v-col>
        </v-row>
        <v-row  class="mt-Section">
          <v-col>
            <img class="advantageJobCircleImg" src="../../public/ressources/images/career/student/horaire.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageJobCircleText">Nous adaptons ton horaire de travail afin que la priorité demeure sur tes études</p>
          </v-col>
          <v-col>
            <img class="advantageJobCircleImg" src="../../public/ressources/images/career/student/formationContinue.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageJobCircleText">Formation continue selon tes aspirations en présentiel et sur le Web</p>
          </v-col>
          <v-col>
            <img class="advantageJobCircleImg" src="../../public/ressources/images/career/student/Conge.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageJobCircleText">Congés tous les vendredis soir et les fins de semaine</p>
          </v-col>
          <v-col>
            <img class="advantageJobCircleImg" src="../../public/ressources/images/career/student/stimulants.png" title="image Stage offert" alt="image Stage offert"/>
            <p class="advantageJobCircleText">T’épanouir en travaillant sur des projets stimulants</p>
          </v-col>
        </v-row>
      </v-row>
    </section>
    <!--why Wait-->
    <section id="whyWait">
      <v-row>
          <v-col cols="12" md="6" style="padding: 0px;">
              <div class="whyWaitBG">
                  <div class="whyWaitAlignText">
                      <v-row><v-col><h2 class="sectionTitle whyWaitTitle">QU’ATTENDS-TU ?</h2></v-col></v-row>
                      <v-row class="mt-5">
                        <v-col>
                          <button class="priBtn mt-5"><h2>APPLIQUE ICI POUR UN STAGE</h2></button>
                          <button class="priBtn mt-5"><h2>POSTULE ICI POUR UN EMPLOI ÉTUDIANT</h2></button>
                          <button class="priBtn mt-5"><h2>ÉCRIS-NOUS POUR PLANIFIER UNE VISITE</h2></button>
                        </v-col>
                      </v-row>
                  </div>
              </div>
          </v-col>
          <v-col cols="12" md="6" style="padding: 0px;">
              <v-row>
                  <v-col class="containerImg">
                      <img src="/ressources/images/career/student/attend.jpg" title="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE" alt="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE"/>
                  </v-col>
              </v-row>
          </v-col>
      </v-row>
    </section>
  </div>
</template>
<style scoped>
    .v-row { width: 100%;}
    .sectionTitle { font-size: 32px; }
    .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center; }
    .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/career/student/Banner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    p { font-family: CooperHewittR; font-size: 24px; }
    .containerImg { height: 600px; overflow: hidden; display: flex; align-items: center; justify-content: center; }
    .containerImg img {  width: 100%; height: 100%; object-fit: cover; }
    /*#region futur*/
      .futurBG { position: relative; width: 100%; background-color: rgb(var(--ligthGray)); padding: 0px 50px; height: 100%; }
      .futurAlignText { position: relative; top:50%; left: 50%; transform: translate(-50%,-50%);}
      .futurTitle { color: rgb(var(--secondary)); font-family: MontserratBI; text-transform: uppercase;  }
      .futurText { font-family: CooperHewittR; margin-top: 20px;  }
    /*#endregion*/
    /*#region Advantage Stage*/
      .advantageStageBG { padding: 50px; background-color: rgb(var(--gray)); color: white; }
      .advantageStageCircleImg { display: block; margin: auto; max-width: 250px;  }
      .advantageStageCircleText { text-align: center; font-family: CooperHewittB; max-width: 250px; margin: auto; }
      .mt-Section {margin-top: 50px !important;}
    /*#endregion*/

    /*#region needJob*/
      .needJobBG { position: relative; width: 100%; background-color: rgb(var(--ligthGray)); padding: 0px 50px; height: 100%; }
      .needJobAlignText { position: relative; top:50%; left: 50%; transform: translate(-50%,-50%);}
      .needJobTitle { color: rgb(var(--secondary)); font-family: MontserratBI; text-transform: uppercase;  }
      .needJobText { font-family: CooperHewittR; margin-top: 20px;  }
    /*#endregion*/

    /*#region AdvantageJob*/
      .advantageJobBG { padding: 50px; background-color: rgb(var(--gray)); color: white; }
      .advantageJobCircleImg { display: block; margin: auto; max-width: 250px;  }
      .advantageJobCircleText { text-align: center; font-family: CooperHewittB; max-width: 250px; margin: auto; }
    /*#endregion*/

    /*#region whyWait*/
      .whyWaitBG { position: relative; width: 100%; background-color: black; padding: 0px 50px; height: 100%; }
      .whyWaitAlignText { position: relative; top:50%; left: 50%; transform: translate(-50%,-50%);}
      .whyWaitTitle { color: white; font-family: MontserratBI; text-transform: uppercase;  }
      .whyWaitText { font-family: CooperHewittR; margin-top: 20px;  }
    /*#endregion*/

    @media handheld, only screen and (max-width: 600px) {
      #whyWait button h2 { font-size: 0.75em; }
      .futurBG {padding: 20px 20px;}
      .needJobBG {padding: 20px 20px;}
      .whyWaitBG {padding: 20px 20px;}
      .sectionTitle { font-size: 18px; }
      p { font-size: 14px; }
      .containerImg { height: 400px; }
    }
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'student',
  data:()=>({

  }),
  mounted() {

  },
  methods:{

  },
  components: { }
}
</script>
