<template>
  <div class="grid-pad">
    <v-row><v-col><h1 class="sectionTitle">MENTIONS LÉGALES</h1></v-col></v-row>
    <v-row>
      <v-col cols="12">
        <h2>Condition d'utilisation</h2>
      </v-col>
      <v-col class="section" cols="12">
        <p class="textSubtitle">NOUS VOUS PRIONS DE LIRE ATTENTIVEMENT LES CONDITIONS D’UTILISATION DÉCRITES CI-DESSOUS.</p>
        <p>Bienvenue sur le www.reseaudynamique.com En accédant et en naviguant sur le site Web de Réseau Dynamique (« Réseau Dynamique » ou « nous »), vous acceptez les modalités et conditions d'utilisation qui suivent (les « Conditions d’utilisation »). Si telle n'est pas votre intention, nous vous invitons à quitter le site.</p>
        <p>Nous nous réservons le droit de modifier en tout temps et sans préavis les présentes Conditions d’utilisation. La date de la dernière mise à jour des Conditions d’utilisation est inscrite au bas de cette page.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>1. Propriété et modalités d’utilisation</h2>
        <p>Tout le contenu, les renseignements, les données, les photographies, les images, les vidéos, les logiciels, les marques de commerce, les logos, les dessins, les slogans publicitaires et/ou autres documents protégés par des droits d’auteur figurant sur le présent site Web (le « Contenu ») sont la propriété de Réseau Dynamique, ses filiales et/ou de tiers concédants de licence. Tel Contenu est protégé par les lois canadiennes sur les droits d’auteur et les marques de commerce.</p>
        <p>Le Contenu de ce site est offert uniquement à titre d’information et de promotion quant aux services offerts par Réseau Dynamique au Canada. Vous n’êtes pas autorisé à modifier, reproduire, rééditer, transférer, envoyer, transmettre, publier, distribuer, ni faire des travaux ou créer des œuvres dérivés du Contenu, sans le consentement écrit préalable de Réseau Dynamique. Par contre, vous pouvez télécharger, imprimer et reproduire le Contenu à des fins d’usage personnel et/ou tel qu’autrement prévu dans la loi, pourvu que vous conserviez tous les avis de droits d’auteur et tous autres avis de propriété qu’il renferme. Toute utilisation non autorisée de ce Contenu enfreindra les droits de propriété intellectuelle de Réseau Dynamique.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>2. Limitation de responsabilité</h2>
        <p>Réseau Dynamique ne garantit d’aucune façon la qualité, l’exactitude ou l’intégralité de tout renseignement contenu sur le site Web. Ces renseignements sont diffusés de bonne foi, « tels quels » et sans aucune garantie ou condition. Le Contenu du site Web pourrait contenir notamment des inexactitudes techniques ou des erreurs typographiques. De plus, Réseau Dynamique ne garantit pas que le Contenu de ce site soit ininterrompu ou qu’il soit sans défaut et décline toute responsabilité en cas de dommages résultant de tous virus, vers ou programmes informatiques de nature invasive ou destructive. En aucun temps Réseau Dynamique ne saurait être responsable de tout dommage direct, indirect, accessoire, consécutif ou autre découlant ou qui a trait à l’utilisation du site Web ou relativement à son Contenu. Vous êtes l’unique responsable de l’utilisation et de l’interprétation des informations contenues sur le site Web.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>3. Liens vers d’autres sites</h2>
        <p>Ce site Web pourrait contenir des liens vers d’autres sites dont des tiers sont les propriétaires. Nous vous prions de noter que Réseau Dynamique n’exerce aucun contrôle sur le contenu de ces sites. Par conséquent, Réseau Dynamique ne peut pas être tenue responsable des renseignements figurant sur de tels sites Web, et n’offre aucune garantie et ne fait aucune déclaration quelles qu’elles soient à cet égard.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>4. Services offert de Réseau Dynamique</h2>
        <p>Réseau Dynamique se réserve le droit de cesser la disponibilité des services offerts sur ce site ou de changer leurs spécifications sans préavis et sans encourir aucune responsabilité.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>5. Indemnisation</h2>
        <p>En accédant et en naviguant sur ce site Web, vous convenez d’indemniser et d’exonérer Réseau Dynamique, ses filiales, actionnaires, administrateurs, dirigeants, employés, mandataires et concédants de licence contre toutes les pertes, frais, dommages-intérêts, réclamations, pénalités, ou autres frais (notamment les frais d’avocat raisonnables), qui pourraient résulter de l’utilisation que vous faites de ce site Web ou de toute allégation de violation des Conditions d’utilisation.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>6. Droit applicable</h2>
        <p>Les présentes Conditions d’utilisation ainsi que la Politique de confidentialité de Réseau Dynamique sont régies et interprétées en vertu des lois en vigueur dans la province de Québec et les lois du Canada qui y sont applicables.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2 class="sectionTitle">Politique de confidentialité</h2>
        <p>Réseau Dynamique (« Réseau Dynamique » ou « <span class="bold">nous</span> ») accorde une grande importance au droit à la vie privée de tous. C’est pourquoi nous avons mis en place la présente politique de confidentialité visant à assurer la protection de votre intégrité et votre droit de protéger vos renseignements personnels.</p>
        <p>La présente politique vise à régir la collecte et l’utilisation de renseignements 1) sur notre site Web (http://www .reseaudynamique.com) et/ou (2) que vous nous communiquez sur une base volontaire dans le cadre de votre participation à un service quelconque offert par Réseau Dynamique , et ce, sous réserve de tout refus que vous pourriez dûment nous exprimer à cet égard.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>Responsable de la Loi 25</h2>
        <p>Loi sur la protection des renseignements personnels</p>
        <p>Nathalie Hallé</p>
        <p>Tél. : 514 353-9720 ext 222</p>
        <a href="mailto:vieprivee@reseaudynamique.com">vieprivee@reseaudynamique.com</a>
      </v-col>
      <v-col class="section" cols="12">
        <h2>1. Application de la présente politique</h2>
        <p>Nous nous réservons le droit de modifier notre politique en tout temps et sans préavis. Vous pouvez consulter en tout temps la politique applicable sur le présent site. La date de la dernière mise à jour de la présente politique de confidentialité est inscrite au bas de cette page.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>2. Votre consentement</h2>
        <p>Le fait 1) d’accéder à notre site Web de et/ou 2) de nous communiquer certains renseignements personnels sur une base volontaire en ayant accepté de vous soumettre à la présente politique de confidentialité, signifie que vous êtes d’accord avec la collecte, l’utilisation et la communication de vos renseignements telles que définies dans cette politique de confidentialité en matière de renseignements personnels.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>3. Collecte et traitement de renseignements recueillis par Réseau Dynamique</h2>
        <p class="textSubtitle">a) Collecte</p>
        <p>Aucun renseignement personnel à votre égard n’est recueilli lorsque vous accédez ou naviguez simplement sur le site Web de Réseau Dynamique . Il doit cependant être noté que lors de tout accès au site Web de Réseau Dynamique , certaines informations sont recueillies automatiquement par notre réseau informatique. Ces informations ne permettent pas à Réseau Dynamique de vous identifier personnellement, mais comprennent notamment des informations relatives à votre nom de domaine ainsi que l’adresse IP par le biais desquels vous accédez à ce site Web. Ces informations pourront être utilisées par Réseau Dynamique pour des fins statistiques, aux fins de notamment lui permettre d’évaluer et d’analyser la fréquence d’accès à son site Web et l’achalandage y afférent.</p>
        <p>Vos renseignements personnels ne sont recueillis que lorsque vous nous les fournissez volontairement, notamment dans le cadre de la participation à un service quelconque lequel requiert que vous donniez de tels renseignements, et que vous n’avez pas exprimé de refus à cet égard. Dans tous les cas où vous fournissez volontairement à Réseau Dynamique des renseignements personnels, celle-ci s’engage à les recueillir, utiliser et/ou communiquer conformément à la présente politique et à la législation applicable.</p>
        <p class="textSubtitle">b) Traitement</p>
        <p>Les renseignements personnels vous concernant, lorsque recueillis sous forme électronique par Réseau Dynamique conformément à la présente politique, seront conservés sur le serveur de Réseau Dynamique.</p>
        <p>Les renseignements personnels vous concernant, lorsque recueillis sous forme physique par Réseau Dynamique conformément à la présente politique, seront conservés aux locaux de Réseau Dynamique.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>4. Fins pour lesquelles Réseau Dynamique peut utiliser les renseignements personnels qui lui sont dûment fournis</h2>
        <p class="textSubtitle">a) Sondage, concours ou promotion</p>
        <p>Dans le cadre de l’administration d’un sondage, concours et/ou promotion, Réseau Dynamique pourrait utiliser, sous réserve de tout refus exprimé de votre part, les renseignements personnels tels qu’ils lui seront fournis sur une base volontaire par l'envoi de message(s) ou par suite d'une participation relative à un sondage, concours ou promotion. Les renseignements recueillis à toute telle fin serviront essentiellement à communiquer avec vous si vous gagnez. Les concours et/ou promotions offerts par Réseau Dynamique pourront être soumis à des règles particulières qui seront plus amplement décrites dans le cadre du lancement de tout tel concours ou promotion.</p>
        <p class="textSubtitle">b) Autres fins</p>
        <p>Réseau Dynamique s’engage à ne pas utiliser les renseignements personnels recueillis conformément à la présente politique ou de la législation applicable à d’autres fins, à moins d’obtenir votre consentement préalable à cette fin ou dans certaines circonstances exceptionnelles, lorsque Réseau Dynamique croit de bonne foi que la loi le permet ou l’exige.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>5. Communication à des tiers</h2>
        <p>Réseau Dynamique s’engage à ne pas divulguer à des tiers les renseignements personnels recueillis conformément à la présente politique ou de la législation applicable, à moins d’obtenir votre consentement préalable à cette fin ou dans certaines circonstances exceptionnelles, lorsque Réseau Dynamique croit de bonne foi que la loi le permet ou l’exige.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>6. Mineur</h2>
        <p>L’autorisation d’un parent ou tuteur est requise pour qu’un enfant ou un jeune âgé de moins de dix-huit (18) ans (un « <span class="bold">Mineur</span> ») puisse participer à une activité organisée par Réseau Dynamique, notamment tout sondage, concours ou promotion. Dans l’éventualité où toute telle activité s’adresse particulièrement à un Mineur, l’autorisation du parent ou tuteur sera expressément requise.</p>
        <p>Réseau Dynamique ne recueillera ni n’utilisera quelque renseignement personnel sur les Mineurs sans le consentement d’un parent ou d’un tuteur.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>7. Utilisation de témoins (« cookies »)</h2>
        <p>L’utilisation d’un témoin n’est pas liée aux renseignements personnels identifiables durant votre navigation sur notre site Web. Vous pouvez en tout temps désactiver lesdits témoins en modifiant la configuration de votre navigateur, mais cette action pourrait vous priver de l’accès à certaines parties de notre site Web.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>8. Liens vers d’autres sites</h2>
        <p>Ce site Web de peut contenir des liens permettant aux utilisateurs de visiter d’autres sites exploités par d’autres entreprises. Réseau Dynamique n’assume aucune responsabilité en ce qui a trait aux pratiques de confidentialité des renseignements ni au contenu de tels sites.</p>
      </v-col>
      <v-col class="section" cols="12">
        <h2>9. Pour nous joindre</h2>
        <p>Pour toute question relativement aux présentes Conditions d’utilisation et politique de confidentialité, veuillez nous écrire à : <a href="mailto:dpaquet@inter-quebec.com">dpaquet@inter-quebec.com</a></p>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
    .sectionTitle { color: rgb(var(--primary)); margin: 25px 0px; }
    h2 { color: rgb(var(--secondary)); font-family: MontserratBI; }
    p { font-family: CooperHewittR; padding: 10px 0px; font-size: 16px; }
    .section { padding-top: 20px !important; }
    .textSubtitle { font-size: 18px; }
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'Home',
  data:()=>({

  }),
  mounted() {

  },
  methods:{

  },
  components: { }
}
</script>
