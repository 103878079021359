<template>
  <div >
    <div v-if="this.current">
      <!--Banner-->
      <section class="">
          <div class="banner">
              <h1 class="PageTitle" v-html="this.current.title"></h1>
              <img style="width: 100%; height: 100%; object-fit: cover; object-position: center center;" :src="this.current.banner"/>
          </div>
          <div class="orangeStripe"></div>
      </section>
      <!--info-->
      <section class="">
        <v-row>
          <v-col cols="12" md="6" class="infoSection">
              <v-row><v-col><h2 class="infoTitle">INFORMATIONS</h2> </v-col></v-row>
              <v-row class="textSection">
                <v-col v-if="this.current.dealer.name" cols="12" >
                    <v-row class="infoRow">
                        <v-col cols="1"><img src="/ressources/images/icon/jobMapIcon.png" /></v-col>
                        <v-col class="d-flex align-center"><p v-html="this.current.dealer.name"></p></v-col>
                    </v-row>
                </v-col>
                <v-col v-if="this.current.infoJob" cols="12">
                    <v-row class="infoRow">
                        <v-col cols="1"><img src="../../public/ressources/images/icon/caseIcon.png" /></v-col>
                        <v-col class="d-flex align-center"><p v-html="this.current.infoJob"></p></v-col>
                    </v-row>
                </v-col>
                <v-col v-if="this.current.schedule" cols="12">
                    <v-row class="infoRow">
                        <v-col cols="1"><img src="../../public/ressources/images/icon/dateIcon.png" /></v-col>
                        <v-col class="d-flex align-center"><p v-html="this.current.schedule"></p></v-col>
                    </v-row>
                </v-col>
                <v-col v-if="this.current.startJob" cols="12">
                    <v-row class="infoRow">
                        <v-col cols="1"><img src="../../public/ressources/images/icon/startJobIcon.png" /></v-col>
                        <v-col class="d-flex align-center"><p v-html="this.current.startJob"></p></v-col>
                    </v-row>
                </v-col>
                <v-col v-if="this.current.salary" cols="12">
                    <v-row class="infoRow">
                        <v-col cols="1"><img src="../../public/ressources/images/icon/salaireIcon.png" /></v-col>
                        <v-col class="d-flex align-center"><p v-html="this.current.salary"></p></v-col>
                    </v-row>
                </v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="6" style="padding: 0px;">
              <v-row>
                  <v-col class="containerImg">
                      <img :src="this.current.dealer.img" :truesrc="this.current.dealer.img" title="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE" alt="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE"/>
                  </v-col>
              </v-row>
          </v-col>
        </v-row>
      </section>
      <!--Mission-->
      <section v-if="this.current.mission" class="missionSection">
        <v-row>
          <v-row><v-col><h2 class="missionTitle">VOTRE MISSION</h2> </v-col></v-row>
          <v-row>
            <v-col>
              <p class="missionText" v-html="this.current.mission"></p>
            </v-col>
          </v-row>
        </v-row>
      </section>
      <!--Responsability-->
      <section v-if="this.current.responsability" class="responsabilitySection">
        <v-row>
          <v-row><v-col><h2 class="responsabilityTitle">VOS RESPONSABILITÉS</h2> </v-col></v-row>
          <v-row>
            <v-col v-for="(item,i) in this.current.responsability" :key="i"> 
              <img class="advantageStageCircleImg" :src="item.img" :title="item.imgTitle" :alt="item.imgAlt"/>
              <p class="responsabilityText">{{ item.title }}</p>
            </v-col>
          </v-row>
        </v-row>
      </section>
      <!--Requirements-->
      <section class="">
        <v-row>
          <v-col cols="12" md="6" style="padding: 0px;">
              <v-row>
                  <v-col class="containerRequireImg">
                      <img v-if="this.current.type == 'garage'" src="/ressources/images/career/job/postesGarage.jpg" title="image section exigences et conditions" alt="image section exigences et conditions"/>
                      <img v-else src="/ressources/images/career/job/postesAdministratif.jpg" title="image section exigences et conditions" alt="image section exigences et conditions"/>
                    </v-col>
              </v-row>
          </v-col>
          <v-col cols="12" md="6" class="requireSection">
              <v-row style="position: absolute; top:50%; left:50%; transform: translate(-40%,-50%);">
                <v-row><v-col><h2 class="requireTitle">EXIGENCES ET CONDITIONS</h2> </v-col></v-row>
                <v-row >
                  <v-col v-if="this.current.yearsExperience" cols="12" >
                      <v-row class="infoRow">
                          <v-col cols="1"><img src="/ressources/images/career/job/arrow.svg" title="image d'une flèche" alt="image d'une flèche"/></v-col>
                          <v-col class="d-flex align-center"><p v-html="this.current.yearsExperience"></p></v-col>
                      </v-row>
                  </v-col>
                  <v-col v-if="this.current.spokenLanguages" cols="12">
                      <v-row class="infoRow">
                          <v-col cols="1"><img src="/ressources/images/career/job/arrow.svg" title="image d'une flèche" alt="image d'une flèche"/></v-col>
                          <v-col class="d-flex align-center"><p v-html="this.current.spokenLanguages"></p></v-col>
                      </v-row>
                  </v-col>
                  <v-col v-if="this.current.writtenLanguages" cols="12">
                      <v-row class="infoRow">
                          <v-col cols="1"><img src="/ressources/images/career/job/arrow.svg" title="image d'une flèche" alt="image d'une flèche"/></v-col>
                          <v-col class="d-flex align-center"><p v-html="this.current.writtenLanguages"></p></v-col>
                      </v-row>
                  </v-col>
                  <v-col v-if="this.current.levelStudy" cols="12">
                      <v-row class="infoRow">
                          <v-col cols="1"><img src="/ressources/images/career/job/arrow.svg" title="image d'une flèche" alt="image d'une flèche"/></v-col>
                          <v-col class="d-flex align-center"><p v-html="this.current.levelStudy"></p></v-col>
                      </v-row>
                  </v-col>
                  <v-col v-if="this.current.otherRequirement" cols="12">
                      <v-row class="infoRow">
                          <v-col cols="1"><img src="/ressources/images/career/job/arrow.svg" title="image d'une flèche" alt="image d'une flèche"/></v-col>
                          <v-col class="d-flex align-center"><p v-html="this.current.otherRequirement"></p></v-col>
                      </v-row>
                  </v-col>
                </v-row>
              </v-row>

          </v-col>
        </v-row>
      </section>
      <!--Quality-->
      <section v-if="this.current.jobQualities" class="qualitySection">
        <v-row>
          <v-row no-gutters><v-col><h2 class="qualityTitle">QUALITÉS REQUISES</h2> </v-col></v-row>
          <v-row class="mt-10">
            <v-col v-for="(item,i) in this.current.jobQualities" :key="i">
              <img class="qualityImg" :src="item.img" :title="item.imgTitle" :alt="item.imgAlt"/>
              <p class="qualityText">{{item.title}}</p>
            </v-col>
          </v-row>
        </v-row>
      </section>
      <!--Benefits-->
      <section v-if="this.current.benefit" class="benefitsSection">
        <v-row>
          <v-row><v-col><h2 class="benefitsTitle">VOS AVANTAGES</h2> </v-col></v-row>
          <v-row>
            <v-col class="d-flex align-center mt-10" style="min-width: 33%; max-width: 33%;" v-for="(item,i) in this.current.benefit" :key="i" >
              <img src="../../public/ressources/images/career/job/arrow.svg" title="image d'une flèche" alt="image d'une flèche"/>
              <p class="benefitsText">{{item.title}}</p>
            </v-col>
          </v-row>

        </v-row>
      </section>
      <!--apply-->
      <section id="apply">
        <v-row>
            <v-col cols="12" md="6" style="padding: 0px;">
                <v-row>
                    <v-col class="containerImg">
                        <img src="../../public/ressources/images/career/job/applyImg.jpg" title="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE" alt="image section DEVIENS NOTRE FUTUR(E) STAGIAIRE"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0px;">
                <div class="applyBG">
                    <div class="applyAlignText">
                        <v-row><v-col><h2 class="sectionTitle applyTitle">QU’ATTENDS-TU ?</h2></v-col></v-row>
                        <v-row class="mt-10">
                          <v-col>
                            <button class="priBtn"><h2>POSTULE DÈS MAINTENANT</h2></button>
                            <a href="https://app.cyberimpact.com/clients/47814/subscribe-forms/8F9576B8-21A3-49D4-9B9C-DA19428BAE27" target="_Blank" ><button class="priBtn mt-10"><h2>ABONNE-TOI À L’INFOLETTRE CARRIÈRE</h2></button></a> 
                          </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
      </section>
    </div>
    <div  v-if="!this.current">
        <v-row class="titleBg">
            <v-row class="bgCharging">
                <v-col><h1 class="titleCharging txt-c">La fiche de travail est en chargement <v-progress-circular style="color: rgb(var(--primary));" :size="75" :width="8" indeterminate></v-progress-circular></h1> 
                </v-col>
            </v-row>

        </v-row>
    </div>
  </div>
</template>
<style scoped>
    .v-row { width: 100%; }
    .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center; z-index: 100; }
    .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/sales/banner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; overflow: hidden; }
    .titleCharging { position: absolute; top:50%; left: 50%; transform: translate(-50%,-50%); font-family: MontserratBI; color: black; font-size: 36px; text-transform: uppercase;}
    .bgCharging { min-height: 700px; background-image: url('../../public/ressources/images/background-truck.jpg'); background-position: center center; background-size: cover; }
    /*#region info*/
      .containerImg { height: 600px; padding: 0px; overflow: hidden; display: flex; align-items: center; justify-content: center; }
      .containerImg img {  object-fit: cover; object-position: center center; }
      .infoSection { background-color: rgb(var(--ligthGray)); padding: 50px; }
      .infoTitle { color: rgb(var(--secondary)); text-transform: uppercase; font-size: 32px; font-family: MontserratBI;  }
      .textSection { margin-top: 50px !important; }
      .textSection p { font-family: CooperHewittR;  font-size: 28px; padding-left: 20px;}
      .textSection a { font-family: CooperHewittR;  font-size: 28px; padding-left: 20px;}
      .textSection img { width: 60px; }
      .infoRow { padding: 5px 0px; }
    /*#endregion*/
    /*#region mission*/
      .missionSection { background-color: black; padding: 100px 50px; }
      .missionTitle { color: rgb(var(--white)); text-transform: uppercase; font-size: 32px; font-family: MontserratBI; }
      .missionText { color: rgb(var(--white)); font-family: CooperHewittR;  font-size: 28px; padding-top: 20px; text-align: justify; }
    /*#endregion*/
    /*#region Responsability*/
      .responsabilitySection { background-color: rgb(var(--gray)); padding: 100px 50px; }
      .responsabilityTitle { color: rgb(var(--white)); text-transform: uppercase; font-size: 32px; font-family: MontserratBI; margin-bottom: 30px; }
      .responsabilityText { color: rgb(var(--white)); font-family: CooperHewittR;  font-size: 28px; font-weight: 700; padding: 20px 20%; text-align: center; }
      .responsabilitySection img { display: block; margin: auto; }
    /*#endregion*/
    /*#region require*/
      .containerRequireImg { height: 600px; padding: 0px; overflow: hidden; display: flex; align-items: center; justify-content: center; }
      .containerRequireImg img { width: 100%; height: 100%; object-fit: cover; object-position: center center; }
      .requireSection {  background-color: rgb(var(--ligthGray)); padding: 50px; position: relative; }
      .requireTitle { color: rgb(var(--secondary)); text-transform: uppercase; font-size: 32px; font-family: MontserratBI; margin-bottom: 30px; }
      .requireText { color: rgb(var(--white)); font-family: CooperHewittR;  font-size: 28px; font-weight: 700; padding: 20px 20%; text-align: center; }
      .requireSection p { font-family: CooperHewittR;  font-size: 28px; padding-left: 20px;}
      .requireSection a { font-family: CooperHewittR;  font-size: 28px; padding-left: 20px;}
      .requireSection img { width: 30px; margin-left: 20px;}
    /*#endregion*/
    /*#region Quality*/
      .qualitySection { background-color: black; padding: 100px 50px; }
      .qualityTitle { color: rgb(var(--white)); text-transform: uppercase; font-size: 32px; font-family: MontserratBI; display: block; }
      .qualityText { color: rgb(var(--white)); font-family: CooperHewittR;  font-size: 28px; padding-top: 20px; text-align: center; }
      .qualityImg { display: block; margin: auto; }
    /*#endregion*/
    /*#region Benefits*/
      .benefitsSection { background-color: rgb(var(--gray)); padding: 100px 50px; }
      .benefitsTitle { color: rgb(var(--white)); text-transform: uppercase; font-size: 32px; font-family: MontserratBI; margin-bottom: 30px; padding-left: 30px; }
      .benefitsText { color: rgb(var(--white)); font-family: CooperHewittR;  font-size: 24px; font-weight: 700; padding: 20px 20px; display: inline;  }
      .benefitsSection img { display: inline; max-width: 30px; }
    /*#endregion*/
    /*#region apply*/
      .applyBG { position: relative; width: 100%; background-color: black; padding: 0px 50px; height: 100%; }
      .applyAlignText { position: relative; top:50%; left: 50%; transform: translate(-50%,-50%);}
      .applyTitle { color: white; font-family: MontserratBI; text-transform: uppercase;  }
      .applyText { font-family: CooperHewittR; margin-top: 20px;  }
    /*#endregion*/
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'job',
  data:()=>({
    current:null,
    errorMessage:null,
    jobs:null,
    dealers:null,
    responsabilities:null,
    qualities:null,
    benefits:null
  }),
  async beforeMount(){
    

  },
  async mounted() {
    await this.jobsJsonRead();
    await this.dealersJsonRead();
    await this.benefitsJsonRead();
    await this.responsabilitiesJsonRead();
    await this.qualitiesJsonRead();

    let jobkey = this.$route.params.jobkey;
    let dealerkey = this.$route.params.dealerkey.toLowerCase();
    this.jobRead(jobkey,dealerkey);
    

  },
  methods:{
    jobsJsonRead: async function () {
        try {
            const response = await fetch('/ressources/json/jobs.json');
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            this.jobs = await response.json();

        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
    dealersJsonRead: async function () {
        try {
            const response = await fetch('/ressources/json/dealers.json');
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            this.dealers = await response.json();
        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
    benefitsJsonRead: async function () {
        try {
            const response = await fetch('/ressources/json/benefits.json');
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            this.benefits = await response.json();
        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
    qualitiesJsonRead: async function () {
        try {
            const response = await fetch('/ressources/json/qualities.json');
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            this.qualities = await response.json();
        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
    responsabilitiesJsonRead: async function () {
        try {
            const response = await fetch('/ressources/json/responsabilities.json');
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            this.responsabilities = await response.json();
        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
    jobRead: async function(jobSelected,dealerSelected) {
      try {
            //API read
            // const response = await fetch('/ressources/json/dealers.json');
            // if (!response.ok) {
            //     throw new Error('Failed to fetch');
            // }
            let data = {
              "key":"technicalAdvisor",
              "title":"Aviseur(e) technique",
              "dealersKey": ["anjou","drummondville"],
              "infoJob":"Permanent, Temps plein de soir (40 h / semaine)",
              "schedule":"Du lundi au vendredi, de 14 h à 00 h 30",
              "startJob":"Dès que possible ",
              "salary":"À discuter",
              "yearsExperience":"Avoir 2 d’expérience",
              "spokenLanguages":null,
              "writtenLanguages":"Capacité à lire en anglais (un atout)",
              "levelStudy":"Détenir un DEP (un atout)",
              "otherRequirement":"Détenir un DEP (un atout)",
            }
            let job;
            // trouver job
            if(this.jobs){ job = await this.findJob(jobSelected); } 
            else {  job = await this.findJob(jobSelected); }
            // compare Api avec parametre dealer (url);

            if(job){
              let dealerFound = false;
              data.dealersKey.forEach(e => {
                if(e == dealerSelected) { dealerFound = true; }
              });

              if(dealerFound == true){
                job = this.feedData(job,data);
                // trouver dealer dans this.dealers et remplir job.dealers
                job = this.feedDealer(job,dealerSelected);
                
                // trouver et remplir responsability et benefits et qualities
                job = this.feedResponsabilities(job,jobSelected);
                job = this.qualitiesSelection(job,jobSelected);
                job = this.feedbenefits(job,jobSelected);
                
              }

              this.current = job;
            }

        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
    findJob: function(jobKey){
      let job;
      try {
        let f = this.jobs.find( e => e.UId == jobKey);
        if(f){job = f;}
        
      } 
      catch (error) { this.errorMessage = "Une erreur c'est produite dans la fiche technique"; }
      return job;
    },
    feedData: function(job,data){
      if(job && data){
        if(data.title){job.title = data.title;}
        if(data.infoJob){job.infoJob = data.infoJob;}
        if(data.schedule){job.schedule = data.schedule;}
        if(data.startJob){job.startJob = data.startJob;}
        if(data.salary){job.salary = data.salary;}
        if(data.yearsExperience){job.yearsExperience = data.yearsExperience;}
        if(data.spokenLanguages){job.spokenLanguages = data.spokenLanguages;}
        if(data.writtenLanguages){job.writtenLanguages = data.writtenLanguages;}
        if(data.levelStudy){job.levelStudy = data.levelStudy;}
        if(data.otherRequirement){job.otherRequirement = data.otherRequirement;}
      }
      return job;
    },
    feedDealer: function(job,dealer) {
      try { if(this.dealers){ job.dealer = this.dealers[dealer]; } else { }} 
      catch (error) { this.errorMessage = "Une erreur c'est produite dans la fiche technique"; }
      
      return job;
    },
    feedbenefits: function(job,key){
      let selectedBenefits = [];
      Object.keys(this.benefits).forEach(e => {
        if(this.benefits[e].jobs.includes(key)) { selectedBenefits.push(this.benefits[e]); }
      });
      if(selectedBenefits.length > 0){ job.benefit = selectedBenefits;}

      return job;
    },
    qualitiesSelection: function(job,key){
      let selectedQualities = [];
      Object.keys(this.qualities).forEach(e => {
        if(this.qualities[e].jobs.includes(key)) { selectedQualities.push(this.qualities[e]); }
      });
      if(selectedQualities.length > 0){ job.jobQualities = selectedQualities;}

      return job;
    },
    feedResponsabilities: function(job,key){
      let selectedResponsabilities = [];
      Object.keys(this.responsabilities).forEach(e => {
        if(this.responsabilities[e].jobs.includes(key)) { selectedResponsabilities.push(this.responsabilities[e]); }
      });
      if(selectedResponsabilities.length > 0){ job.responsability = selectedResponsabilities;}

      return job;
    }
  },
  components: { }
}
</script>
