<template>
  <div class="bodyColor">
    <!--Banner-->
    <section class="">
        <div class="banner">
            <h1 class="PageTitle" style="">Ventes</h1>
        </div>
    </section>
    <!--after Sale Info-->
    <section>
      <v-row class="afterSaleBg">
        <v-row class="container" no-gutters>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">{{ counterPro }}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">MARQUES RECONNUES</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">à l’international proposant fiabilité inégalée, design raffiné et technologie avancée pour rehausser votre expérience de conduite</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">+ {{counterProOne}}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">MODÈLES NEUFS ET USAGÉS</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">de qualité en inventaire, alliant puissant et capacité pour répondre à vos besoins et faire de vos transports un succès</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-row class="containerInfo d-flex  align-center" >
                <v-row class="justify-center" style="height: 100%;">
                  <row><v-col cols="12"><h2 class="infoTitle">+ {{counterProTwo}}</h2></v-col></row>
                  <v-row><v-col cols="12"><h3 class="infoSubtitle">RÉPRENSANT(E)S DES VENTES</h3></v-col></v-row>
                  <v-row><v-col cols="12"><p class="infoText">ayant l’expertise et le service client à cœur pour vous aider à choisir le bon véhicule adapté à vos attentes et vos besoins selon votre secteur d’activités</p></v-col></v-row>
                </v-row>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </section>
    <!--new Truck-->
    <section id="newTruck">
      <v-row class="newTruckBg">
        <v-col class="conainterNewTruckInfo">
          <v-row style="">
            <v-col cols="12"><h2>nos Camions et remorques neuves </h2></v-col>
            <v-col cols="12"><p>Notre inventaire de classe 3-4-5 et 6-7-8, ainsi que nos remorques nous permettent de proposer l’offre la plus complète sur le marché au Québec en termes de véhicules légers, moyens et lourds. Nous avons un large choix de fournisseurs, dont les 5 plus grandes marques regroupées sous le même toit : International, Isuzu, Kalmar Ottawa, Battle&nbsp;Motors&nbsp;et&nbsp;Doepker.</p></v-col>
            <v-col cols="12"><p>Grâce à l’intégration d’innovations et de technologies, notre vaste gamme de produits reconnue pour sa robustesse et sa durabilité permet d’aider nos clients à relever tous les défis du transport. De Montréal à Sept-Îles, nos experts répartis dans nos 23 concessionnaires vous offriront un service à la hauteur de vos exigences, que ce soit de la vente à l’achat en passant par le financement, et ce, peu importe&nbsp;où&nbsp;vous&nbsp;vous&nbsp;trouvez.</p></v-col>
          </v-row>
          <v-row>
            <v-col><router-link to="/inventaire?n=1"><button class="priBtn mt-5" >CONSULTEZ NOTRE INVENTAIRE</button></router-link></v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--used Truck-->
    <section id="usedTruck">
      <v-row class="usedTruckBg">
        <v-col class="conainterUsedTruckInfo">
          <v-row style="">
            <v-col cols="12"><h2>Nos camions et remorques usagées</h2></v-col>
            <v-col cols="12"><p>Profitez de la force de notre réseau, c’est aussi avoir accès à une grande sélection de camions et remorques d’occasion à vendre. Quelle que soit la tâche, nous avons une variété de véhicules usagés équipés des options, des composantes et des spécifications dont vous avez besoin pour accomplir le travail. Si vous le désirez, nous pouvons échanger ou racheter votre ou vos véhicules usagés en contrepartie. Vous n’avez qu’à le ou les faire évaluer gratuitement par nos experts chevronnés&nbsp;pour&nbsp;obtenir&nbsp;un&nbsp;prix.</p></v-col>
            <v-col cols="12"><p>Sans compter que vous pouvez rouler vers une bonne affaire grâce à nos plans de garantie en option, ainsi que du financement adapté à vos opérations. Notre collaboration avec une multitude d’institutions financières nous permet de vous trouver les meilleures conditions de financement sur le marché en fonction de vos besoins. Devenir propriétaire de votre camion ou remorque n’aura jamais été aussi&nbsp;simple&nbsp;et&nbsp;efficace!</p></v-col>
          </v-row>
          <v-row>
            <v-col><router-link to="/inventaire"><button class="priBtn mt-5 flt-r" >consultez notre inventaire</button></router-link></v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--electricTruck-->
    <section id="electricTruck">
      <v-row class="electricTruckBg">
        <v-col class="conainterElectricTruckInfo">
          <v-row style="">
            <v-col cols="12"><h2>nos camions zéro émission</h2></v-col>
            <v-col cols="12"><p>L’électrification de votre flotte ne se fera pas du jour au lendemain. Cela doit commencer par une analyse de vos données télématiques et la planification de votre infrastructure de recharge afin de déterminer ensuite le bon&nbsp;véhicule&nbsp;pour&nbsp;votre&nbsp;application.</p></v-col>
            <v-col cols="12"><p>Partout à travers le Réseau Dynamique, nous vous accompagnons dans votre transition grâce au plus grand choix de camions lourds zéro émission au Québec. Avec notre expertise ainsi que nos partenariats clés avec les leaders de l’industrie en télématique et de l’infrastructure électrique, il est possible de considérer l’électrification de vos&nbsp;opérations&nbsp;dès&nbsp;aujourd’hui!</p></v-col>
          </v-row>
          <v-row>
            <v-col><router-link to="/camionElectrique"><button class="priBtn mt-5" >EN SAVOIR PLUS</button></router-link></v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--month Truck-->
    <section id="monthTruck" v-if="this.monthTruck">
      <v-row class="monthTruckBg">
        <v-col style="position: absolute; z-index: 1; height: 250px; width: 100%; background-color: rgb(var(--primary)); top:30%; left: 0;"></v-col>
        <v-col style="position: relative; z-index: 10;">
          <v-row><v-col cols="12"><h2>LE CAMION USAGÉ DU MOIS</h2></v-col></v-row>
          <v-row><v-col cols="12"><router-link :to="getHref(this.monthTruck.id)"><button class="secBtn mt-5" >CONSULTER LA FICHE DÉTAILLÉE</button></router-link></v-col></v-row>
          <v-row class="" style="margin-top: 50px !important;">
            <v-col v-for="(item,i) in truckPicture" :key="i" >
              <v-col style="height: 500px; min-width: 250px;">
                <img style="width: 100%; height: 100%; object-fit: cover; object-position: center; padding: 20px;" :src="item" :title="'image ' + i + 'du camion' + this.monthTruck.id_encode" :alt="'image ' + i + 'du camion' + this.monthTruck.id_encode" />
              </v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <img class="icon" src="../../public/ressources/images/sales/MARQUE.png" title="icon Marque du camion" alt="icon qui démontre la marque du camion"/>
              <h3>Marque</h3>
              <p>{{ this.monthTruck.marque }}</p>
            </v-col>
            <v-col>
              <img class="icon" src="../../public/ressources/images/sales/Modele.png" title="icon Marque du camion" alt="icon qui démontre la marque du camion"/>
              <h3>MODÈLE</h3>
              <p>{{ this.monthTruck.modele }}</p>
            </v-col>
            <v-col>
              <img class="icon" src="../../public/ressources/images/sales/annee.png" title="icon Marque du camion" alt="icon qui démontre la marque du camion"/>
              <h3>ANNÉE</h3>
              <p>{{ this.monthTruck.annee }}</p>
            </v-col>
            <v-col>
              <img class="icon" src="../../public/ressources/images/sales/KM.png" title="icon Marque du camion" alt="icon qui démontre la marque du camion"/>
              <h3>KILOMÉTRAGE</h3>
              <p>{{ this.monthTruck.intMillage }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <!--all services-->
    <section id="allServices">
      <v-row class="allServicesBG">
        <v-col><h2>DÉCOUVREZ L’ENSEMBLE DE NOS SERVICES</h2></v-col>
      </v-row>
      <v-row>
          <v-col class="serviceSection bgservice">
            <router-link to="/"><h2 class="sectionTitle serviceSubtitle">SERVICE</h2></router-link>
          </v-col>
          <v-col class="serviceSection bgparts" style="">
            <router-link to="/ventes"><h2 class="sectionTitle serviceSubtitle">PIÈCES</h2></router-link>
          </v-col>
          <v-col class="serviceSection bglocation" >
            <router-link to="/"><h2 class="sectionTitle serviceSubtitle">LOCATION</h2></router-link>
          </v-col>
        </v-row>
    </section>
  </div>
</template>
<style scoped>
    .borderGreen { border: 1px solid green;}
    .infoTitle { transition: opacity 0.1s ease-in-out; opacity: 1; }
    .infoTitle.change { opacity: 0.5; }
    .bodyColor { background-color: rgb(var(--ligthGray)); }
    .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center; }
    .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/sales/banner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    /*#region info Sale*/
      .afterSaleBg { min-height: 400px;  padding: 10px 0px; }
      .container { padding: 0px 5%; max-height: 450px; }
      .containerInfo { position: relative; padding: 50px; height: 500px; max-width: 450px; margin: -25% 0px 0px 0px !important; background-color: rgb(var(--gray)); }
      .infoTitle { font-family: MontserratBI; color: rgb(var(--primary)); font-size: 78px; }
      .infoSubtitle { font-family: MontserratBI; text-transform: uppercase; color: white; font-size: 24px; min-width: 300px; text-align: center; padding: 20px 0px; }
      .infoText { font-family: CooperHewittR;  color: white; font-size: 24px; text-align: center;}
    /*#endregion*/
    /*#region new Truck*/
      .newTruckBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/sales/newTrucks.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterNewTruckInfo { position: absolute; left: 5%; top:40%; transform: translate(0%,-50%); background-color: black; padding: 25px !important; max-width:40%; }
      #newTruck H2 {color: white; font-family: MontserratBI; text-transform: uppercase; font-size: 1.75em; }
      #newTruck p { color: white;  padding: 15px 0px; font-family: CooperHewittR;}
    /*#endregion*/
    /*#region used Truck*/
      .usedTruckBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/sales/usedTrucks.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterUsedTruckInfo { position: absolute; right: 10%; top:50%; transform: translate(0%,-50%); background-color: black; padding: 25px !important; max-width: 40%; }
      #usedTruck H2 {color: white; font-family: MontserratBI; text-transform: uppercase; text-align: right; font-size: 1.75em; }
      #usedTruck p { color: white;  padding: 15px 0px; font-family: CooperHewittR; text-align: right; }
    /*#endregion*/
    /*#region electric Truck*/
      .electricTruckBg { position: relative; height: 800px; width: 100%; margin-top: 40px !important; background-image: url('../../public/ressources/images/sales/electricTruck.jpg'); background-size: cover; background-position: center center; background-repeat: no-repeat; }
      .conainterElectricTruckInfo { position: absolute; left: 5%; top:40%; transform: translate(0%,-50%); background-color: black; padding: 25px !important; max-width:33%; }
      #electricTruck H2 {color: white; font-family: MontserratBI; text-transform: uppercase; font-size: 1.75em; }
      #electricTruck p { color: white;  padding: 15px 0px; font-family: CooperHewittR;}
    /*#endregion*/
    /*#region month Truck*/
    .monthTruckBg { background-color: black; padding: 50px; position: relative; z-index: 10; }
     #monthTruck H2 { color: white; font-family: MontserratBI; text-transform: uppercase; font-size: 1.75em; text-align: center; }
     #monthTruck H3 { color: white; font-family: MontserratBI; text-transform: uppercase; font-size: 1.25em; text-align: center; }
     #monthTruck p { color: white; font-family: CooperHewittR; text-transform: uppercase; font-size: 1em; text-align: center; }
     #monthTruck button { display: block; margin: auto; }
     #monthTruck .icon { display: block; margin: auto; min-width: 100px; max-width: 200px; }
    /*#endregion*/
    /*#region allServices*/
      .allServicesBG { background-color: rgb(var(--gray)); }
      .allServicesBG h2 { text-align: center; color: white; padding: 100px 0px; font-size: 36px; font-family: MontserratBI; }
      .bgSales { background-image: url('../../public/ressources/images/home/serviceSales.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgparts { background-image: url('../../public/ressources/images/home/PIECES.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bgservice { background-image: url('../../public/ressources/images/home/SERVICE.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .bglocation { background-image: url('../../public/ressources/images/home/LOCATION.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
      .serviceTitle { font-family: MontserratBI; padding: 10% 0px; text-align: center; margin: 10px 0px !important; text-transform: uppercase; color: white; }
      .serviceSubtitle { font-family: MontserratBI; padding: 30% 0px; text-align: center; margin: 10px 0px; text-transform: uppercase; color: white; }
      .serviceSection:hover  .serviceTitle {color: rgb(var(--primary)); cursor: pointer;}
      .serviceSection:hover  .serviceSubtitle {color: rgb(var(--primary)); cursor: pointer;}
    /*#endregion*/
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'Ventes',
  data:()=>({
    counterPro: 0,
    counterProOne: 0,
    counterProTwo: 0,
    animationDurationCount: 1000,
    idSpecialTruck:'MzAxMg%3D%3D', //3067 et encode: MzAxMg== || MzAxMg%3D%3D
    truckPicture:null,
    monthTruck:null
  }),
  mounted() {
    if(this.idSpecialTruck) {this.readData();}
    this.animateProCounter();
    this.animateProOneCounter();
    this.animateProTwoCounter();

  },
  methods:{
    async readData() {

      //let api = '/api/singleUsedTruck_.php';
      let api = 'https://reseaudynamique.com/api/singleUsedTruck_.php';

      let isNew = (window.location.search.match(new RegExp('[?&]' + 'n' + '=([^&]+)')) || [,null])[1];
      if(isNew && isNew == 1){
          //api = '/api/singleNewTruck_.php';
          api = 'https://reseaudynamique.com/api/singleNewTruck_.php';
          this.isNew = 1;
      }

      let id = this.idSpecialTruck;
      if (id) {
          // Show loading spinner
          //$('.loading-overlay').show();

          try {
              const response = await fetch(api + '?id=' + id)
              const data = await response.json()
              this.monthTruck = data;
              
              this.setPicture();

          } catch (error) {
              console.error(error);
              this.errorMessage = error.toString();
          }
      }
    },
    animateProCounter() {
      const maxCount = 10;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterPro = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    animateProOneCounter() {
      const maxCount = 2000;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterProOne = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    animateProTwoCounter() {
      const maxCount = 25;
      const duration = this.animationDurationCount; // Durée totale de l'animation en millisecondes
      const stepTime = Math.abs(Math.floor(duration / maxCount));

      let currentCount = 0;
      const timer = setInterval(() => {
        currentCount++;
        this.counterProTwo = currentCount;
        if(currentCount === maxCount) {
          clearInterval(timer);
        }
      }, stepTime);
    },
    setPicture(){
      if(this.monthTruck.pictures){
        console.log(this.monthTruck.pictures.length);
        if(this.monthTruck.pictures.length > 4 ) { this.truckPicture = this.monthTruck.pictures.slice(0,4); }
        else { this.truckPicture = this.monthTruck.pictures; }
      }
      console.log('peut-etre fonction pour setup les images') 
    },
    getHref(id) {
        let url = "/camion?"
        if (this.isNew == 1) { url = url + "n=1&" }
        return url + "id=" + encodeURI(btoa(id));
    },
  },
  components: { }
}
</script>
