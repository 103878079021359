<template>
  <div v-if="dealers">
    <section class="">
      <div class="banner">
        <h1 class="PageTitle" style="">Nous Joindre</h1>
      </div>
    </section>
    <section class="">
      <div class="orangeStripe"></div>
      <div style="height: 400px; position: relative;">
        <h1 class="PageTitle" style="color: black;">Map</h1>
      </div>
    </section>
    <section style="height: 100%; position: relative;" class="">
      <v-row class="">
        <v-col cols="12" class="bgServiceGray"><h2 class="sectionTitle txt-c">NOS CONCESSIONNAIRES</h2></v-col>
        <v-row class="bgDealers">
          <v-col  v-for="(item,i) in dealers" :key="i" class="bgDealer">
            <div class="card dealerFront">
              <img style="position: absolute; z-index: 10; height: 300px; width: 350px;" :src="item.img" />
              <v-row class="d-flex align-center textDealerFront" style="">
                <v-col cols="12">
                  <h2 class="town">{{ item.town }}</h2>
                  <p class="name">{{ item.name }}</p>
                </v-col>
              </v-row>

            </div> 
            <div class="card dealerBack" >

              <img class="imgDealerBack" style="" src="/ressources/images/contactUs/baseSquare.jpg" />
              <v-row class="textDealerBack" style="">
                <v-col cols="12"><h2 class="titleDealerBack">{{ item.town }}</h2></v-col>
                <v-col cols="12 d-flex align-center" style="margin-top: 20px;">
                  <img class="icon" src="/ressources/images/icon/phone.png" />
                  <a class="phoneText" :href="'tel:' + item.phone" style="" v-html="item.phone"></a>
                </v-col>
                <v-col cols="12 d-flex align-center" style="margin-top: 20px;">
                  <img class="icon" src="/ressources/images/icon/Courriel.png" />
                  <a :href="'mailto:' + item.email" class="emailText">{{ item.email }}</a>
                </v-col>
                <v-col cols="12 d-flex align-center">
                  <h2 style="color: rgb(var(--primary)); font-size: 48px; font-family: CooperHewittR; font-weight: 700;">></h2>
                  <router-link :to="item.path"><p class="fiche">Voir la fiche</p></router-link>
                </v-col>
              </v-row>
            </div> 
        </v-col>
        </v-row>
      </v-row>
      
    </section>

  </div>
</template>
<style scoped>
    .bgServiceGray { background-color: rgb(var(--gray)); padding: 50px 0px 50px 0px !important; }
    .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); }
    .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/contactUs/BannerContactUs.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .bgDealer { position: relative; max-width: 350px; min-width: 350px; width: 250px; height: 300px; margin: 20px auto; perspective: 1000px;  }
    .bgDealers {padding: 50px 5%; background-color: rgb(var(--ligthGray));}
    .fiche { padding-left: 10px; display: inline-block; color: white; cursor: pointer; }
    .fiche:hover { color: rgb(var(--secondary)); }
    .town { font-family: MontserratBI; text-transform: uppercase; color: #FFFFFF; text-align: center; }
    .name { font-family: CooperHewittR; color: #FFFFFF; text-align: center; }
    .textDealerFront { position: relative; z-index: 100; padding: 40px; width: 100%; height: 100%; }
    .imgDealerBack { position: absolute; z-index: 10; height: 300px; width: 350px; }
    .textDealerBack { position: relative; z-index: 100; padding: 40px; width: 100%; }
    .titleDealerBack { font-family: MontserratBI; text-transform: uppercase; color: #FFFFFF; }
    .icon { display: inline-block; height: 35px; width: auto; }
    .phoneText {padding-left: 10px; display: inline-block; color: white;}
    .emailText { font-size: small;  padding-left: 10px; display: inline-block; color: rgb(var(--secondary)); cursor: pointer;}
    .card { width: 100%; height: 100%; position: absolute; backface-visibility: hidden; transition: transform 0.6s; }
    .dealerBack { transform: rotateY(180deg); }
    .bgDealer:hover .dealerBack { transform: rotateY(0deg); }
    .bgDealer:hover .dealerFront { transform: rotateY(180deg); }

</style>
<script>

export default {
  name: 'ContactUs',
  data:()=>({
    dealers: null,
  }),
  mounted() {
    this.dataRead();
  },
  methods:{
    dataRead: async function () {
        try {
            const response = await fetch('/ressources/json/dealers.json');
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            this.dealers = await response.json();
        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
  },
  components: { }
}
</script>
