<template>
  <v-app>
    <v-main>
      <Cookie />
      <cHeader />
      <div class="fixHeader"></div>
      <div style="">
        <router-view/>
      </div>
      <cFooter />
    </v-main>
  </v-app>
</template>

<style>
  .fixHeader {height: 150px;}
  @media handheld, only screen and (max-width: 600px) {}
  @media handheld, only screen and (max-width: 960px) {
    .fixHeader {height: 130px;}
  }
  @media handheld, only screen and (max-width: 1279px) {}
  @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
  import Cookie from '../src/components/cookie.vue';
  export default {
    name: 'App',
    components: {
      Cookie,
    }

  }
</script>
