<template>
    <div class="bg" >
        <div class="printPage" v-if="item">
            <section>
                <v-row><v-col class="d-flex align-center justify-center"><img style="padding-bottom: 20px;" src="../../public/ressources/images/logo/dynamique.png" /></v-col></v-row>
            </section>
            <section >
                <v-row class="titleBg">
                    <v-row class=""><v-col><h1 class="title txt-c" v-html="item.beauTitre"></h1></v-col></v-row>
                    <v-row class="">
                        <v-col class="d-flex align-center justify-center"><img style="max-width:50px; height: auto;" src="../../public/ressources/images/icon/mapIcon.png" /><h2 class="dealer" v-if="item.succursale">{{ item.location }}</h2><h2 class="dealer" v-else> Non disponible</h2></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2 class="price" v-if="item.prix">{{ item.prix }}$</h2>
                            <p class="price" v-else>prix non disponible</p>
                        </v-col>
                    </v-row>
                </v-row>
            </section>
            <section >
                <v-carousel v-if="item.pictures" style="height: auto;  max-height: 450px; margin-top:25px;" cycle show-arrows hide-delimiters infinite>
                    <template v-slot:prev="{ props }"> <h2 class="storyArrow arrowPaging arrowPrev" @click="props.onClick"> &lt; </h2></template>
                    <template  v-slot:next="{ props }"> <h2 class="storyArrow arrowPaging arrowNext" @click="props.onClick"> > </h2></template>
                    <template v-for="(pic, index) in item.pictures"> 
                        <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
                            <v-row class="flex-nowrap" style="height:100%">
                            <template v-for="(n,i) in columns"> 
                                <template v-if="(+index + i) < item.pictures.length"> 
                                    <v-col :key="i">
                                        <v-sheet v-if="(+index + i) < item.pictures.length" style="height: 100%; margin: 0px 10px; background-color: transparent;" >
                                            <v-row class="" justify="center" >
                                                <div class="display-3">
                                                    <!--ici ton image ou information necessaire @click="selectedProduct(Dist[index + i].img)" -->
                                                    <img @click="selectedProduct(item.pictures[index + i], index + i)" style="position: relative; object-fit: contain; z-index: 1; width: 100%; max-width: 450px; height: auto; max-height: 400px; " :src="item.pictures[index + i]"/>
                                                </div>
                                            </v-row>
                                        </v-sheet>
                                    </v-col>
                                </template>
                            </template>
                            </v-row>
                        </v-carousel-item>
                    </template>
                </v-carousel>
            </section>
            <section class="specbg">
                <v-row><v-col><h2 class="title">SPÉCIFICATIONS</h2></v-col></v-row>
                <v-row style="margin-top: 25px !important;" class="d-flex align-center">
                    <v-col>
                        <v-row><v-col><img class="specIcon" src="../../public/ressources/images/icon/iconMarque.png" /></v-col></v-row>
                        <v-row><v-col><h3 class="specTitle">Marque</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.marque">{{ item.marque }}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col>
                        <v-row><v-col><img class="specIcon" src="../../public/ressources/images/icon/iconModele.png" /></v-col></v-row>
                        <v-row><v-col><h3 class="specTitle">MODÈLE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.modele">{{ item.modele }}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col>
                        <v-row><v-col><img class="specIcon" src="../../public/ressources/images/icon/iconNoserie.png" /></v-col></v-row>
                        <v-row><v-col><h3 class="specTitle">NO&nbsp;SÉRIE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.noSerie">{{ item.noSerie }}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col>
                        <v-row><v-col><img class="specIcon" src="../../public/ressources/images/icon/iconInventory.png" /></v-col></v-row>
                        <v-row><v-col><h3 class="specTitle">NO&nbsp;D’INVENTAIRE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.noInventaire">{{ item.noInventaire }}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col>
                        <v-row><v-col><img class="specIcon" src="../../public/ressources/images/icon/iconYear.png" /></v-col></v-row>    
                        <v-row><v-col><h3 class="specTitle">ANNÉE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.annee">{{ item.annee }}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col>
                        <v-row><v-col><img class="specIcon" src="../../public/ressources/images/icon/iconKM.png" /></v-col></v-row>                   
                        <v-row><v-col><h3 class="specTitle">KILOMÉTRAGE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.km_moteur">{{item.km_moteur}} km</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                </v-row>
            </section>
            <section class="specbg mt-10">
                <v-row><v-col><h2 class="title">CARACTÉRISTIQUES</h2></v-col></v-row>
                <v-row>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">EMPATTEMENT</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.empattement">{{item.empattement}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">ESSIEU&nbsp;AVANT</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.essieuAvant">{{item.essieuAvant}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">ESSIEU&nbsp;ARRIÈRE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText"  v-if="item.essieuArriere">{{item.essieuArriere}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">SUSPENSION&nbsp;ARRIÈRE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.suspensionArriere">{{item.suspensionArriere}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">TRANSMISSION</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.transmission">{{item.transmission}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">MOTEUR</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.moteur">{{item.moteur}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">RATIO&nbsp;ESSIEU&nbsp;ARRIÈRE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.ratio_ar">{{item.ratio_ar}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">DIMENSION&nbsp;PNEU</h3></v-col></v-row>
                        <v-row><v-col>
                            <p class="specText" v-if="item.pneu_av_dim">Avant : {{item.pneu_av_dim}}</p><p class="specText" v-else>N/D</p>
                            <p class="specText" v-if="item.pneu_av_dim">Arrière : {{item.pneu_av_dim}}</p><p class="specText" v-else>N/D</p>
                        </v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">FREINS</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.freins">{{item.freins}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">RÉSERVOIR</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.freins">{{item.reservoirs}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">COULEUR&nbsp;INTÉRIEURE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.couleur_in">{{item.couleur_in}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                    <v-col class="spec">
                        <v-row><v-col><h3 class="specTitle">COULEUR&nbsp;EXTÉRIEURE</h3></v-col></v-row>
                        <v-row><v-col><p class="specText" v-if="item.couleur_ex">{{item.couleur_ex}}</p><p class="specText" v-else>N/D</p></v-col></v-row>
                    </v-col>
                </v-row>
            </section>
            <section>
                <v-row style="margin-top: 25px !important;">
                    <v-col cols="12" md="4" class="equipmentBg">
                        <v-row><v-col><h2 class="title">ÉQUIPEMENTS</h2></v-col></v-row>
                        <v-row>
                            <v-col v-if="item.equipment">
                                <p class="equipmentText"  v-for="subitem in item.equipment" :key="subitem" >{{ subitem }}</p>
                            </v-col>
                            <v-col v-else>
                                <p class="equipmentText">N/D</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col  class="warrantyBg">
                        <v-row><v-col><h2 class="title">GARANTIES</h2></v-col></v-row>
                        <v-row>
                            <v-col v-if="item.garantie">
                                <p class="equipmentText" v-for="gar in item.garantie" :key="gar">{{ gar }}</p>
                            </v-col>
                            <v-col v-else>
                                <p v-if="item.new == 0" class="equipmentText">N/D</p>
                                <p v-else class="equipmentText">Garanties prolongées disponibles</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>
        </div>
        <div  v-if="!item">
            <v-row class="titleBg">
                <v-row class="bgCharging">
                    <v-col><h1 class="titleCharging txt-c">Le document est en chargement <v-progress-circular style="color: rgb(var(--primary));" :size="75" :width="8" indeterminate></v-progress-circular></h1> 
                    </v-col>
                </v-row>

            </v-row>
        </div>
    </div>
  </template>
  <style scoped>
        .fixHeader { height: 0px !important; }
        .bg { padding: 25px; background-color: rgb(var(--ligthGray)); }
        .printPage { max-width: 827px; margin:auto; }
        .titleBg { padding: 25px; background-color: white; }
        .titleBg .v-row { width: 100%; }
        .title { font-family: MontserratBI; color: black; font-size: 18px; text-transform: uppercase; }
        .dealer { font-family: CooperHewittR; font-weight: 100; text-align: center;  color: black; }
        .price { font-family: MontserratRe; margin-top: 20px; font-weight: 100; text-align: center; color: rgb(var(--secondary)); }
        .grayButton { background-color: rgb(var(--ligthGray)); text-align: left; color: rgb(var(--secondary)); min-width: 250px; width: 100%; padding: 10px; }
        .grayButton:hover { box-shadow: 0px 0px 10px rgb(var(--primary)); cursor: pointer;}
        .grayButton h2 { text-transform: uppercase; font-family: MontserratBI; }
        .titleBg a {display: inline-block; margin: 10px;}
        .specbg { background-color: white; padding: 25px; margin-top:25px;}
        .specTitle { margin-top: 25px; padding: 0px 10px; font-family: MontserratBI; color: black; text-align: center; font-size: 12px; text-transform: uppercase; }
        .specIcon { display: block; margin-left: auto; margin-right: auto; width: auto; height: 50px; }
        .specText { margin-top: 10px; font-family: CooperHewittR; color: black; text-align: center; font-size: 10px; text-transform: uppercase; }
        .equipmentBg { padding: 25px !important; background-color: white; margin-right: 25px; }
        .equipmentText { margin-top: 10px; font-family: CooperHewittR; color: black; font-size: 12px; text-transform: uppercase; }
        .warrantyBg { padding: 25px !important; background-color: white;}
        .storyArrow { display: block; }
        .valueArrow { display: none; }
        .arrowPaging { position: absolute; font-size: 76px; cursor: pointer; color: rgb(var(--primary)); z-index: 1000;}
        .arrowPaging:hover { color: white; text-shadow: 0px 0px 8px rgb(var(--primary)); }
        .arrowNext { padding-left: 40px; top:40%; right: 1%; }
        .arrowPrev { padding-right: 20px; top:40%; left: 1%; }
        .arrowDialogNext { padding-left: 40px; top:70%; right: 1%; }
        .arrowDialogPrev { padding-right: 20px; top:70%; left: 1%; }
        .titleCharging { position: absolute; top:50%; left: 50%; transform: translate(-50%,-50%); font-family: MontserratBI; color: black; font-size: 36px; text-transform: uppercase;}
        .bgCharging { min-height: 600px; background-image: url('../../public/ressources/images/background-truck.jpg'); background-position: center center; background-size: cover; }
        .widthSalesBtn { min-width: 850px; }
        @media handheld, only screen and (max-width: 600px) { 
            .spec { min-width: 250px; } 
            .grayButton h2 { text-transform: uppercase; font-family: MontserratBI; font-size: medium; }
        }
        @media handheld, only screen and (max-width: 960px) { .warrantyBg { margin-top:25px; } .widthSalesBtn { min-width:fit-content; } }
        @media handheld, only screen and (max-width: 1279px) {}
        @media handheld, only screen and (max-width: 1378px) {}
  </style>
  <script>
  export default {
    name: 'Home',
    data:()=>({
        Dist: [
            { img:'/ressources/images/inventory/imgtest1.png', },
            { img:'/ressources/images/inventory/imgtest2.png', },
            { img:'/ressources/images/inventory/imgtest3.png', },
            { img:'/ressources/images/inventory/imgtest5.png', }
        ],
        columns: 3,
        showSelectedProduct: false,
        productSelected: null,
        productSelectedIndex: 0,
        dealers: [
            {dealer:'',path:''}
        ],
        MainPicture: {src:null, alt:""},
        isNew : 0,
        errorMessage: "",
        item: null
    }),
    async mounted() {
        try {
            this.EraseComponent();
            await this.readData();
            this.Dist = this.item.pictures;
            this.print();

        } catch (error) {
            console.error(error);
            this.errorMessage = error.toString();
        }
    },
    methods:{

        selectedProduct: function(img, index) { this.productSelected = img; this.productSelectedIndex = index; console.log(index); this.showSelectedProduct = true; },
        setPicture(index){ this.MainPicture = {src:this.item.pictures[index],alt:"Reseau dynamique photo camion"} },              
        async readData() {

            //let api = '/api/singleUsedTruck_.php';
            let api = 'https://reseaudynamique.com/api/singleUsedTruck_.php';

            let isNew = (window.location.search.match(new RegExp('[?&]' + 'n' + '=([^&]+)')) || [,null])[1];
            if(isNew && isNew == 1){
                //api = '/api/singleNewTruck_.php';
                api = 'https://reseaudynamique.com/api/singleNewTruck_.php';
                this.isNew = 1;
            }

            let id = (window.location.search.match(new RegExp('[?&]' + 'id' + '=([^&]+)')) || [,null])[1];
            if (id) {
                // Show loading spinner
                //$('.loading-overlay').show();

                try {
                    console.log(api + '?id=' + id);
                    const response = await fetch(api + '?id=' + id)
                    const data = await response.json()
                    this.item = data;
                    console.log(this.item);
                    
                    this.setPicture(0);

                } catch (error) {
                    console.error(error);
                    this.errorMessage = error.toString();
                }

                // Hide loading spinner
                //$('.loading-overlay').hide();
            }
        },
        print() {
            window.print();
        },
        EraseComponent(){
            var header = document.getElementsByClassName('header');
            for (var i = header.length - 1; i >= 0; i--) {
                header[i].remove();
            }
            var fixHeader = document.getElementsByClassName('fixHeader')[0];
            if (fixHeader) { fixHeader.remove(); }

            var footer = document.getElementsByClassName('footer');
            for (var i = footer.length - 1; i >= 0; i--) {
                footer[i].remove();
            }

        }

    },
    components: { }
  }
  </script>