<template>
    <section v-if="diapoNews" class="bgDiapoNews" style="height: auto;">
      <v-row>
        <v-col cols="12">
          <div class="orangeStripe"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="boxContact">
            <div class=" d-flex align-center justify-center">
              <h1 class="introTitle" >VOUS AIMEREZ AUSSI</h1>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-carousel v-if="diapoNews" style="height: auto;  max-height: 450px; margin-top:25px; padding-bottom: 500px !important;" cycle show-arrows hide-delimiters infinite>
          <template v-slot:prev="{ props }"> <h2 class="storyArrow arrowPaging arrowPrev" @click="props.onClick"> &lt; </h2></template>
          <template  v-slot:next="{ props }"> <h2 class="storyArrow arrowPaging arrowNext" @click="props.onClick"> > </h2></template>
          <template v-for="(pic, index) in diapoNews"> 
              <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
                  <v-row class="flex-nowrap" style="height:100%">
                  <template v-for="(n,i) in columns"> 
                      <template v-if="(+index + i) < diapoNews.length"> 
                          <v-col :key="i">
                              <v-sheet v-if="(+index + i) < diapoNews.length" style="height: 100%; margin: 0px 10px; background-color: transparent;" >
                                  <v-row class="" justify="center" >
                                      <div class="bloc display-3">
                                          <!--ici ton image ou information necessaire @click="selectedProduct(Dist[index + i].img)" -->
                                          <v-row><v-col cols="12"> <div style="height: 200px; width:100%; "><img style="display: block; margin-left: auto; margin-right: auto; height: 100%; width: inherit;" :src="diapoNews[index + i].img" /></div></v-col></v-row>
                                          <v-row><v-col cols="12"><div style="height: 225px; width:100%; overflow: hidden;"><p v-html="diapoNews[index + i].subtitle"></p></div></v-col></v-row>
                                          <v-row><v-col cols="12"><div><router-link class="" :to="diapoNews[index + i].path">Lire la suite</router-link></div></v-col></v-row>
                                      </div>
                                  </v-row>
                              </v-sheet>
                          </v-col>
                      </template>
                  </template>
                  </v-row>
              </v-carousel-item>
          </template>
      </v-carousel>
    </section>
</template>
<style scoped>
  .introTitle {  font-family: MontserratBI; color: white; font-weight: 700; font-size: 30px; text-transform: uppercase; margin: 50px 0px;}
  .bgDiapoNews {background-image: url('../../public/ressources/images/news/BgActualty.jpg');  background-position: center center; background-size: cover; background-repeat: no-repeat; }
    .bloc { display: block; margin-left: auto; margin-right: auto; height: auto; min-width: 350px; max-width: 400px; background-color: black; color: white ; padding-bottom: 10px; }
    .bloc img { padding: 10px;}
    .bloc p { padding: 20px 10px; font-family: CooperHewittR; font-size: large; }
    .bloc a { color: white; padding: 20px 10px; font-family: CooperHewittR; font-weight: 700; }
    .storyArrow { display: block; }
    .valueArrow { display: none; }
    .arrowPaging { position: absolute; font-size: 76px; cursor: pointer; color: rgb(var(--primary)); z-index: 1000;}
    .arrowPaging:hover { color: white; text-shadow: 0px 0px 8px rgb(var(--primary)); }
    .arrowNext { padding-left: 40px; top:40%; right: 1%; }
    .arrowPrev { padding-right: 20px; top:40%; left: 1%; }
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'DiapoNews',
  data:()=>({
    diapoNews: null,
    columns: 3,
  }),
  async mounted() {
    try {
            this.changeColumns();
            await this.newsRead();
        } catch (error) {
            console.error(error);
            this.errorMessage = error.toString();
        }
  },
  created() { window.addEventListener("resize", this.changeColumns); },
  destroyed() { window.removeEventListener("resize", this.changeColumns); },
  methods:{
    back: function (){
      this.$router.push('/actualites')
    },
    changeColumns() {
        console.log('change col : ' + this.columns );
        let width = window.innerWidth;
        if (width > 2000) { this.columns = 4; }
        else if (width > 1200) { this.columns = 3; }
        else if (width > 800) { this.columns = 2; } 
        else if (width < 800) { this.columns = 1; } 
    },
    newsRead: async function () {
      try {
          const response = await fetch('/ressources/json/news.json');
          if (!response.ok) { throw new Error('Failed to fetch'); }
          let diapoData = await response.json();
          if(diapoData) { this.diapoNews = diapoData; }
          console.log(this.diapoNews);
      } catch (error) { console.error('Error loading JSON data:', error); }
    },
  },
  components: { }
}
</script>
