<template>
    <div>
        <!--Banner-->
        <section class="">
            <div class="banner">
                <h1 class="PageTitle" style="">Vient joindre ta dynamique<br>à notre équipe !</h1>
            </div>
            <div class="orangeStripe"></div>
        </section>
        <!--Futur-->
        <section>
            <v-row>
                <v-col cols="12" md="6" style="padding: 0px;">
                    <div class="futurBG">
                        <div class="futurAlignText">
                            <v-row><v-col><h2 class="sectionTitle futurTitle">TON AVENIR AVEC NOUS</h2></v-col></v-row>
                            <v-row><v-col><p class="futurText">Choisir Réseau Dynamique, c’est entreprendre ta passion avec des possibilités d’avancement, une équipe dynamique, et ce, partout à travers&nbsp;la&nbsp;province. Avec nous, tu vivras des expériences enrichissantes dans une ambiance de travail familiale. Fais route vers une carrière prometteuse&nbsp;avec&nbsp;nous&nbsp;!</p></v-col></v-row>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0px;">
                    <v-row>
                        <v-col style="height: 400px; overflow: hidden; display: flex; align-items: center; justify-content: center;">
                            <video style="width: 100%; height: 100%; object-fit: cover;" loop autoplay muted>
                                <source src="/ressources/images/video/careerVideo.mp4" type="video/mp4">
                                Votre navigateur ne prend pas en charge la lecture de la vidéo.
                            </video>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </section>
        <!--Advantage-->
        <section>
            <v-row class="advantageWidth">
                <v-col cols="12"><h2 class="sectionTitle advantageTitle">NOS AVANTAGES</h2></v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col class="maItem">                   
                            <img class="advantageLogo" src="../../public/ressources/images/career/assurance.png" />
                            <h3 class="advantageText">Programme d’assurance collective</h3>
                        </v-col>
                        <v-col class="maItem">
                            <img class="advantageLogo" src="../../public/ressources/images/career/allocation.png" />
                            <h3 class="advantageText">Allocation pour l’achat d’outils et bottes&nbsp;de&nbsp;sécurité</h3>
                        </v-col>
                        <v-col class="maItem">
                            <img class="advantageLogo" src="../../public/ressources/images/career/formation.png" />
                            <h3 class="advantageText">Formation continue</h3>
                        </v-col>
                        <v-col class="maItem">
                            <img class="advantageLogo" src="../../public/ressources/images/career/plus.png" />
                            <h3 class="advantageText">Et bien plus encore&nbsp;!</h3>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </section>
        <!--citation-->
        <section>
            <v-row>
                <v-col cols="12" md="6" class="citationImgBG"  style="padding: 0px;"></v-col>
                <v-col cols="12" md="6" style="padding: 0px;">
                    <div class="citationBG">
                        <div class="citationAlignText">
                            <v-row><v-col cols="12"><img style="display: block; width: 100px; height: auto; margin-left: auto; margin-right: auto;" src="../../public/ressources/images/career/commas.png" /></v-col></v-row>
                            <v-row><v-col><p class="citationText">« Chez Réseau Dynamique, nous nous assurons que tu sois toujours à la fine pointe de la technologie en t’offrant de la formation continue et personnalisée sur place tout au long de l’année grâce à notre remorque fermée et équipée pour développer&nbsp;tes&nbsp;connaissances&nbsp;!&nbsp;»</p></v-col></v-row>
                            <v-row><v-col><p class="citationEmploye">Sylvain Lalumière & Stephane Tremblay</p></v-col></v-row>
                            <v-row><v-col><p class="citationJob">Formateurs, soutien technique</p></v-col></v-row>
                        </div>
                    </div>
                </v-col>
                
            </v-row>
            <div class="orangeStripe"></div>
        </section>
        <!--Testimony-->
        <section>
            <div class="bgTestimony">
                <v-row >
                    <v-col><h2 class="sectionTitle TitleTestimony">TÉMOIGNAGES DE NOS EMPLOYÉS</h2></v-col>
                </v-row>
                <v-row >
                    <v-col cols="12" md="4">
                        <v-row><v-col><div class="avatarNaomi"></div></v-col></v-row>
                        <v-row><v-col><p class="avatarText">Faire un stage chez Réseau Dynamique, c’est miser sur ton développement professionnel. L’expertise et la qualité des gens qui s’y trouvent te font vite sentir comme chez toi ! Sans compter les projets concrets et motivants que l’on te&nbsp;confie&nbsp;rapidement.</p></v-col></v-row>
                        <v-row><v-col><p class="avatarName">Naomi&nbsp;Burney</p></v-col></v-row>
                        <v-row><v-col><p class="avatarSector">Marketing</p></v-col></v-row>
                    </v-col>
                    <v-col  cols="12" md="4">
                        <v-row><v-col><div class="avatarElliot"></div></v-col></v-row>
                        <v-row><v-col><p class="avatarText">Ce que j'aime le plus, c'est l'équipe qui est toujours prête à aider les autres en partageant leurs compétences. C'est aussi un milieu de travail où l’on peut se dépasser en ayant les outils nécessaires pour obtenir des connaissances supérieures, tout en pouvant être fier de ce que l'on&nbsp;a&nbsp;accompli&nbsp;!</p></v-col></v-row>
                        <v-row><v-col><p class="avatarName">Elliot&nbsp;Dauphinais</p></v-col></v-row>
                        <v-row><v-col><p class="avatarSector">Technicien</p></v-col></v-row>
                    </v-col>
                    <v-col  cols="12" md="4">
                        <v-row><v-col><div class="avatarNaomi"></div></v-col></v-row>
                        <v-row><v-col><p class="avatarText">Nisi mihi Phaedrum, inquam, tu mentitum aut Zenonem putas, quorum utrumque audivi, cum mihi nihil sane praeter sedulitatem probarent, omnes mihi Epicuri sententiae satis Nisi mihi Phaedrum, inquam, tu mentitum aut Zenonem. Nisi mihi Phaedrum, inquam, tu mentitum.</p></v-col></v-row>
                        <v-row><v-col><p class="avatarName">Naomi Burney</p></v-col></v-row>
                        <v-row><v-col><p class="avatarSector">Marketing</p></v-col></v-row>
                    </v-col>
                </v-row>
            </div>
        </section>
        <!--join-->
        <section>
            <v-row>
                <v-col cols="12" md="6" style="padding: 0px;">
                    <div class="citationBG">
                        <div class="centeringJoinText">
                            <v-row><v-col cols="12"><h2 class="sectionTitle">JOINS-TOI À NOTRE FAMILLE</h2></v-col></v-row>
                            <v-row><v-col><router-link to="/opportunites"><button class="priBtn" style="margin-top: 40px;">OPPORTUNITÉS D’EMPLOI</button></router-link></v-col></v-row>
                            <v-row>
                                <v-col><a href="https://app.cyberimpact.com/clients/47814/subscribe-forms/8F9576B8-21A3-49D4-9B9C-DA19428BAE27" target="_Blank"><button class="priBtn" style="margin-top: 40px;">ABONNE-TOI À L’INFOLETTRE CARRIÈRE </button></a></v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0px;">
                    <div class=""></div>
                    <v-carousel height="450" cycle :interval="3000" infinite :show-arrows="false" hide-delimiter-background >
                        <v-carousel-item v-for="(image, index) in sliders" :key="index">
                            <img style="position: relative; object-fit: cover; z-index: 1; width: 100%; height: 100%; max-height: 600px; " :src="image" aspect-ratio="1" />
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
        </section>
    </div>
  </template>
  <style scoped>
        .PageTitle { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center; }
        .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/career/banner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
        .futurImg { background-image: url('../../public/ressources/images/career/banner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
        .futurBG { position: relative; width: 100%; background-color: rgb(var(--ligthGray)); padding: 0px 50px; height: 100%; }
        .futurAlignText { position: relative; top:50%; left: 50%; transform: translate(-50%,-50%);}
        .futurTitle { color: rgb(var(--secondary)); font-family: MontserratBI; text-transform: uppercase;  }
        .futurText { font-family: CooperHewittR; margin-top: 20px; }
        .advantageWidth {background-color: rgb(var(--gray)); padding: 75px 50px;}
        .advantageTitle { color: white; font-family: MontserratBI; text-transform: uppercase; padding: 0px 0px 20px 0px; }
        .advantageText { color: white; text-align: center; font-family: CooperHewittR; font-weight: 700;  }
        .advantageLogo { min-width: 150px; max-width: 200px; margin-left: auto; margin-right: auto; display: block; }
        .citationImgBG { background-image: url('../../public/ressources/images/career/citation.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
        .citationBG { position: relative; width: 100%; background-color: black; padding: 50px; height: 100%; }
        .citationAlignText { position: relative; top:50%; left: 50%; transform: translate(-50%,-50%);}
        .citationText { font-family: CooperHewittR; text-align: center; margin-top: 20px; color: white; font-style: italic;}
        .citationEmploye {font-family: CooperHewittB; text-align: center; margin-top: 20px; color: white; font-weight: 700;}
        .citationJob { font-family: CooperHewittR; text-align: center; margin-top: 20px; color: white; }
        .bgTestimony { background-color: rgb(var(--ligthGray)); padding: 50px; }
        .TitleTestimony { text-align: center; color: black; margin-bottom: 50px; }
        .avatarNaomi { background-image: url('../../public/ressources/images/career/NaomiBurney.jpg');  margin-left: auto; margin-right: auto;  width: 100px; height: 100px; border-radius: 50%; background-size: cover; background-position: center; }
        .avatarElliot { background-image: url('../../public/ressources/images/career/ElliotDauphinais.jpg'); margin-left: auto; margin-right: auto;  width: 100px; height: 100px; border-radius: 50%; background-size: cover; background-position: center; }
        .avatar3 { margin-left: auto; margin-right: auto;  width: 100px; height: 100px; border-radius: 50%; background-color: black; background-size: cover; background-position: center; }
        .avatarText { font-family: CooperHewittR; text-align: center; margin-top: 20px; font-style: italic; padding: 0px 20px; height: 200px; }
        .avatarName { font-family: CooperHewittB; text-align: center; margin-top: 20px; font-style: italic; }
        .avatarSector { font-family: CooperHewittR; text-align: center; margin-top: 10px; }
        .maItem {margin: 20px;}
        .futurVideoWidth { width: 50%; }
        .centeringJoinText { position: absolute; top: 50%; transform: translate(0%,-50%); }

        @media handheld, only screen and (max-width: 600px) {}
        @media handheld, only screen and (max-width: 960px) {
            .avatarText { height: auto !important; }
        }
        @media handheld, only screen and (max-width: 1279px) {
            .advantageWidth {background-color: rgb(var(--gray)); padding: 75px 50px;}
            .futurVideoWidth { width: 100%; }
            .avatarText { height: 300px; }
        }
        @media handheld, only screen and (max-width: 1378px) {}
  </style>
  <script>
  
  export default {
    name: 'Career',
    data:()=>({
        sliders: {
            slider1: "/ressources/images/career/slider/slide1.jpg",
            slider2: "/ressources/images/career/slider/slide2.jpg",
            slider4: "/ressources/images/career/slider/slide4.jpg",
            slider5: "/ressources/images/career/slider/slide5.jpg",
            slider6: "/ressources/images/career/slider/slide6.jpg",
            slider7: "/ressources/images/career/slider/slide7.jpg",
            slider8: "/ressources/images/career/slider/slide8.jpg",
            slider9: "/ressources/images/career/slider/slide9.jpg"
        }
    }),
    mounted() {
  
    },
    methods:{
  
    },
    components: { }
  }
  </script>
  