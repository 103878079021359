<template>
  <div style="">
    <!--Banner-->
    <section>
      <v-row class="banner">
          <img style="width: 100%; height: 100%; object-fit: cover; object-position: center;"  />
          <h1 class="sectionTitle PageTitle">urgence routière 24h pour camions lourds</h1>
      </v-row>
    </section>
    <!--section bgText-->
    <section>
      <v-row class="bgText">
        <h2 style="color: rgb(var(--secondary));" class="sectionTitle">NOUS SOMMES LÀ POUR VOUS</h2>
        <p>Savez-vous que vous pouvez profiter d’une assistance routière ou un remorquage grâce à nos services et partenaires qui desservent nos clients à travers la province ? Peu importe l’imprévu, vous pouvez compter&nbsp;sur&nbsp;nous&nbsp;!</p>
        <p>Notre service est effectué par un(e) technicien(ne) comprenant les contraintes et exigences de votre domaine d'activités. Dans le cas où votre camion a besoin de soins plus pointus, celui-ci et sa cargaison seront remorqués jusqu'à la succursale Réseau Dynamique la plus près ou dans l’une des entreprises partenaires. L'éventail de véhicules de service est bien équipé pour rapatrier votre chargement de façon sécuritaire, quelles que soient sa taille et sa nature.</p>
        <p>Il vous suffit de contacter rapidement l’une de nos succursales la plus près de votre emplacement par téléphone pour toute urgence sur la route. À noter que la disponibilité du service routier est 24h ou la même que celle des heures d’ouverture du département des pièces et du service des succursales du Réseau Dynamique.</p>
      </v-row>
    </section>
    <!--section bgText-->
    <section>
      <v-row class="bgWhy">
        <h2 class="sectionTitle">POURQUOI NOUS CHOISIR ? </h2>
        <v-row>
            <v-col v-for="(item, i) in whyUs" :key="i" style="min-width: 350px;" class="whyItem">
                <img class="whyImg" :src="item.img" />
                <p class="whyTitle" v-html="item.title"></p>
            </v-col>
        </v-row>
      </v-row>
    </section>
    <section style="height: 100%; position: relative;" class="">
      <v-row class="">
        <v-col cols="12" class="bgServiceGray"><h2 class="sectionTitle txt-c">APPELEZ VOTRE CONCESSIONNAIRE LE PLUS PRÈS</h2></v-col>
        <v-row class="bgDealers">
          <v-row style="margin-top: -125px !important;">
            <v-col  v-for="(item,i) in emergencyDealers" :key="i" class="bgDealer">
                <div v-if="item.emergency" class="card dealerFront">
                  <img class="dealerImg" :src="item.emergency.img" />
                  <v-row class="d-flex align-center textDealerFront" style="">
                    <v-col cols="12">
                      <h2 class="town txt-c">{{ item.town }}</h2>
                      <p class="name">{{ item.name }}</p>
                    </v-col>
                  </v-row>

                </div>
                <div class="card dealerBack" >
                  <img class="imgDealerBack" style="" src="/ressources/images/emergency/baseSquare.jpg" />
                  <v-row class="textDealerBack" style="">
                    <v-col cols="12"><h2 class="town">{{ item.town }}</h2></v-col>
                    <v-row cols="12" class="d-flex align-center" style="margin-top: 20px;">
                      <v-col style="max-width: 75px; min-width: 75px;">
                        <img class="icon" src="/ressources/images/emergency/telEmergency.png" />
                      </v-col>
                      <v-col>
                        <v-col cols="12" class="d-flex align-center"><a class="phoneText" :href="'tel:' + item.emergency.phone" style="" v-html="item.emergency.phone"></a></v-col>
                        <v-col cols="12" v-if="item.emergency.openHours"><p style="font-size: 12px; color: white; padding-left: 10px;" >Selon les heures d’ouverture</p></v-col>
                      </v-col>                 
                    </v-row>
                    <v-col cols="12" class="d-flex align-center">
                      <h2 style="color: rgb(var(--primary)); font-size: 48px; font-family: CooperHewittR; font-weight: 700; text-align: right; max-width: 75px; min-width: 75px;">></h2>
                      <router-link :to="item.path"><p v-if="!item.emergency.towingPhone" class="fiche">Voir plus de détails</p><p class="fiche" v-else>Besoin d’un remorquage ?</p></router-link>
                    </v-col>
                  </v-row>
                </div> 
            </v-col>
          </v-row>
          
        </v-row>
      </v-row>
      
    </section>
  </div>
</template>
<style scoped>
    p { font-family: CooperHewittR; } 
    .banner { position: relative; height: 400px; background-image: url('../../public/ressources/images/emergency/banner.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; }
    .PageTitle { position: absolute; top: 50%; transform: translate(45%,-50%); text-transform: uppercase; }
    .banner img { width: 100%; height: auto; margin-top: -1px; max-height: 100%; object-position: center; object-fit: cover; }
    .bgText {background-color: rgb(var(--ligthGray)); padding: 100px;}
    .sectionTitle { color: white;  } 
    .bgText p { padding-top: 25px;  font-family: CooperHewittR;}
    .bgWhy { background-color: rgb(var(--gray)); padding: 100px; }
    .whyItem { margin-top: 50px; }
    .whyImg { height: 200px; width: auto; display: block; margin-left: auto; margin-right: auto; }
    .whyTitle { text-align: center; padding: 0px 25px; font-family: CooperHewittR; color: white; }

    .bgServiceGray { background-color: black; padding: 100px 0px 150px 0px !important; }
    .bgDealer { position: relative; max-width: 450px; min-width: 450px; height: 350px; margin: 20px auto; perspective: 1000px;  }
    .dealerImg { position: absolute; z-index: 10; height: 100%; width: 100%; }
    .bgDealers { padding: 50px 5%; background-color: rgb(var(--ligthGray));}
    .fiche { padding-left: 10px; display: inline-block; color: white; cursor: pointer; }
    .fiche:hover { color: rgb(var(--secondary)); }
    .town { font-family: MontserratBI; text-transform: uppercase; color: #FFFFFF; }
    .name { font-family: CooperHewittR; color: #FFFFFF; text-align: center; }
    .textDealerFront { position: relative; z-index: 100; padding: 40px; width: 100%; height: 100%; }
    .imgDealerBack { position: absolute; z-index: 10; max-width: 450px; min-width: 450px; height: 350px; }
    .textDealerBack { position: relative; z-index: 100; padding: 40px; width: 100%; height: 100%; }
    .icon { display: inline-block; height: 75px; width: auto; }
    .phoneText {padding-left: 10px; display: inline-block; color: white; font-size: 24px;}
    .emailText { font-size: small;  padding-left: 10px; display: inline-block; color: rgb(var(--secondary)); cursor: pointer;}
    .card { width: 100%; height: 100%; position: absolute; backface-visibility: hidden; transition: transform 0.6s; }
    .dealerBack { transform: rotateY(180deg); }
    .bgDealer:hover .dealerBack { transform: rotateY(0deg); }
    .bgDealer:hover .dealerFront { transform: rotateY(180deg); }

    @media handheld, only screen and (max-width: 600px) {
      h2 { font-size: 24px; }
      .bgDealer { position: relative; max-width: 350px; min-width: 100%; height: 350px; margin: 20px auto; perspective: 1000px;  }
      .PageTitle { position: absolute; top: 50%; transform: translate(0%,-50%); text-transform: uppercase; text-align: center; }
    }
    @media handheld, only screen and (max-width: 960px) {
      .bgText { padding: 10px; text-align: justify;}
      .bgWhy { padding: 10px; }
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'Home',
  data:()=>({
    whyUs:[
      { title:"Service courtois, fiable&nbsp;et&nbsp;sécuritaire", img:"/ressources/images/dealer/serviceRoutier.png" },
      { title:"Véhicules de dépannage équipés d’outils spécialisés, pièces et technologies&nbsp;de&nbsp;pointe", img:"/ressources/images/dealer/ServiceRemorquage.png" },
      { title:"Service de location de camion selon&nbsp;la&nbsp;succursale", img:"/ressources/images/dealer/cle.png" },
      { title:"Équipe de professionnels dévoués&nbsp;hautement&nbsp;certifiés", img:"/ressources/images/dealer/atelierCarrosserie.png" }
    ],
    emergencyDealers: null,
  }),
  async mounted() {
    this.dataRead();
  },
  methods:{
    dataRead: async function () {
        try {
            const response = await fetch('/ressources/json/dealers.json');
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            let dealers = await response.json();
            let emergencyList = [];
            for (const key in dealers) { if(dealers[key].emergency) {  emergencyList.push(dealers[key]); } }
            if(emergencyList.length > 0) { this.emergencyDealers = emergencyList; }
            

        } catch (error) {
            console.error('Error loading JSON data:', error);
        }
    },
  },

  components: { }
}
</script>
